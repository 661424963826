import { CapiClient, CapiCommand } from "./client";
import { emptyArray, emptyObject } from "utils/constants";
import { signalParamMarkers } from "utils/params";
import { Dictionary } from "utils/types";

const patternsDefault = emptyArray;
const patternsDefault2 = Object.freeze([".*"]);
const cv = CapiCommand.checkValue;
const cf = CapiCommand.checkField;

export class CommonParamsDef extends CapiCommand {
  values: boolean
  
  constructor(
    command: string,
    names: readonly string[] = emptyArray,
    patterns: readonly string[] = patternsDefault,
    options: { details?: boolean, values?: boolean, scope?: string, of?: string } = emptyObject
  )
  {
    cv(command, "command", "string");
    cv(names, "names", "array");
    cv(patterns, "patterns", "array");
    cv(options.details, "details", "boolean", "undefined");
    cv(options.values, "values", "boolean", "undefined");
    cv(options.scope, "scope", "string", "undefined");
    cv(options.of, "of", "string", "undefined");
    
    if (patterns.length == 0 && names.length === 0)
      patterns = patternsDefault2;
    
    super([
      command,
      [names, patterns],
      options
    ]);
  
    this.values = !!options.values;
  }

  parseData(status: number, data: any) {
    if (status !== 200) return data;

    cv(data, "data", "array");

    return data.flatMap((def: any) => {
      cf(def, "pid", "string");
      cf(def, "description", "string");
      cf(def, "help", "string", "undefined");
      cf(def, "version", "string", "undefined");
      cf(def, "read_scopes", "array");
      cf(def, "write_scopes", "array");
      cf(def, "values", "array", "undefined"); // TODO: głębiej
      
      try {
        return { ...def, type: parseParamType(def.type, def.pid) };
      }
      catch (err) {
        console.error(err);
        // pomijamy błędne parametry, dobry pomysł? zobaczymy, czy to nam typowania nie rozpieprzy
        return emptyArray;
      }
    });
  }
  
  getMarkerList(client: CapiClient) {
    if (!this.values)
      // interesuje nas tylko sygnalizowanie zmian w wartościach, bo definicji klient nie może modyfikować
      return emptyArray;
    if (this.status !== 200) // FIXME: nie adresujemy tutaj błędów, więc hm... czasem może się nie odświeżać
      return emptyArray;
    const url = client.url;
    return this.result!.data!.map(({ pid }: { pid: string }) => `param:${pid}@${url}`);
  }
  
  static bind(command: string) {
    return bindCommandName(CommonParamsDef, command);
  }
}

export class CommonParamsGet extends CapiCommand {
  constructor(
    command: string,
    names: readonly string[] = emptyArray,
    patterns: readonly string[] = patternsDefault,
    options: { scope?: string, of?: string } = emptyObject
  ) 
  {
    cv(command, "command", "string");
    cv(names, "names", "array");
    cv(patterns, "patterns", "array");
    cv(options.scope, "scope", "string", "undefined");
    cv(options.of, "of", "string", "undefined");
  
    if (patterns.length == 0 && names.length === 0)
      patterns = patternsDefault2;
    
    super([command, [names, patterns], options]);
    
  }
  
  getMarkerList(client: CapiClient) {
    if (this.status !== 200)
      return emptyArray;
    return Object.keys(this.result!.data!).map((name: string) => `param:${name}@${client.url}`);
  }
  
  static bind(command: string) {
    return bindCommandName(CommonParamsGet, command);
  }
}

export class CommonParamsSet extends CapiCommand {
  constructor(
    command: string,
    setting: Dictionary,
    resetting: readonly string[] = emptyArray,
    { scope, of }: { scope?: string, of?: string } = emptyObject
  )
  {
    cv(command, "command", "string");
    cv(setting, "setting", "object");
    cv(resetting, "resetting", "array");
    cv(scope, "scope", "string", "undefined");
    cv(of, "of", "string", "undefined");
    
    super([command, [setting, resetting], { scope, of, report: true }]);
  }

  parseData(status: number, data: any, client: CapiClient) {
    if (status === 200) {
      // automatycznie sygnalizujemy, jakie parametry się zmieniły
      signalParamMarkers(client.url, data);
    }
    return data;
  }
  
  static bind(command: string) {
    return bindCommandName(CommonParamsSet, command);
  }
}

//

type UnboundCommandClass = new(command: string, ...args: any[]) => CapiCommand

// niestety nie udało mi się tego tak rozwiązać, żeby IDEA podpowiadała argumenty
// albo podpowiada `...args: P`
// albo zamienia argumenty opcjonalne na wymagane :/
// albo w ogóle `...args: any`
function bindCommandName<T extends UnboundCommandClass>(klass: T, command: string):
  T extends new(command: string, ...args: infer P) => infer R
    ? new(...args: P) => R
    : never
{
  // @ts-ignore // TS coś tutaj narzeka na mechanizm, którego nawet nie próbowałem użyć
  return class BoundCommand extends klass {
    constructor(...args: any[]) {
      super(command, ...args);
    }
  };
}

//

export type ParamType = {
  kind: "string" | "number" | "bool" | "date" | "datetime"
  null?: boolean
} | {
  kind: "array"
  of?: ParamType
} | {
  kind: "map"
  of?: ParamType
  by?: ParamType
} | {
  kind: "enum"
  of?: ParamType
  list: readonly [unknown, string][]
} | {
  kind: "flags"
  list: readonly [string, string][]
}

const stringType = { kind: "string" as "string", null: false };
const indexType = { kind: "number" as "number", null: false };
const validKinds = {
  string: 1,
  number: 1,
  bool: 1,
  array: 1,
  map: 1,
  object: 1,
  enum: 1,
  flags: 1,
  invalid: 1
};

function expandParamType(type: any): ParamType {
  if (type === "string") return stringType;
  else if (typeof type === "string") type = { kind: type };
  else if (typeof type !== "object") {
    console.error("Invalid param type: ", type);
    type = { kind: "invalid" };
  }
  // eslint-disable-next-line
  return {
    ...type,
    null: type.null || false,
    of: (type.of && expandParamType(type.of)) || stringType,
    by: (type.by && expandParamType(type.by)) || (type.kind === "array" ? indexType : stringType)
  };
}

function parseParamType(type: any, pid: string): ParamType {
  type = expandParamType(type);
  
  if (!(type.kind in validKinds)) {
    console.log("Unrecognized param kind:", type.kind, pid);
    type = { kind: "invalid", null: type.null };
  }
  else if (type.kind === "flags" || type.kind === "enum") {
    if (!Array.isArray(type.list) || type.list.length === 0) {
      throw new Error(`${pid}: ${type.kind} parameter type missing list`);
    }
  }
  
  return type;
}

export class FolksParamsDef extends CommonParamsDef.bind("folksParamsDef") {}
export class FolksParamsGet extends CommonParamsGet.bind("folksParamsGet") {}
export class FolksParamsSet extends CommonParamsSet.bind("folksParamsSet") {}

export const folksCommands = {
  ParamsDef: FolksParamsDef,
  ParamsGet: FolksParamsGet,
  ParamsSet: FolksParamsSet,
}

export class SowaParamsDef extends CommonParamsDef.bind("sowaParamsDef") {}
export class SowaParamsGet extends CommonParamsGet.bind("sowaParamsGet") {}
export class SowaParamsSet extends CommonParamsSet.bind("sowaParamsSet") {}

export const sowaCommands = {
  ParamsDef: SowaParamsDef,
  ParamsGet: SowaParamsGet,
  ParamsSet: SowaParamsSet,
}

export class TekaParamsDef extends CommonParamsDef.bind("tekaParamsDef") {}
export class TekaParamsGet extends CommonParamsGet.bind("tekaParamsGet") {}
export class TekaParamsSet extends CommonParamsSet.bind("tekaParamsSet") {}

export const tekaCommands = {
  ParamsDef: TekaParamsDef,
  ParamsGet: TekaParamsGet,
  ParamsSet: TekaParamsSet,
}

export class KasaParamsDef extends CommonParamsDef.bind("kasaParamsDef") {}
export class KasaParamsGet extends CommonParamsGet.bind("kasaParamsGet") {}
export class KasaParamsSet extends CommonParamsSet.bind("kasaParamsSet") {}

export const kasaCommands = {
  ParamsDef: KasaParamsDef,
  ParamsGet: KasaParamsGet,
  ParamsSet: KasaParamsSet,
}
