import "./utils/themes";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";

import ScrollToTopWrapper from "components/common/ScrollToTopWrapper";
import App from "./components/App";

import store, { sagaMiddleware } from "./store";

import rootSaga from "./sagas";

import "./utils/errorReporting";
import bootstrap from "bootstrap";

import "./styles/index.scss";

function onWindowResized() {
  // Czemu tak? Bo to nam daje rozmiary w pełnych pikselach,
  // jak używamy 90vw bezpośrednio to czasem wychodzą ułamki, i np. ramka elementu się przytnie
  const { floor } = Math;
  const w = window.innerWidth;
  document.body.style.setProperty("--90vw", floor(w * 0.9) + "px");
}

window.addEventListener("resize", onWindowResized);
onWindowResized();

document.title = `${bootstrap.title2} • ${bootstrap.name}`;

sagaMiddleware.run(rootSaga);

const root = document.getElementById("root");

root && ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ScrollToTopWrapper>
        <Route component={App} />
      </ScrollToTopWrapper>
    </Router>
  </Provider>,
  root
);
