import React, { useEffect, useRef, useState } from "react";
import { emptyArray } from "../../../utils/constants";
import { useTouchDevice } from "../../../utils/hooks";
import { useSearchPhraseAsState } from "../../../utils/hooks/search";
import { Input } from "../../shared";
import { useShellContext } from "../../shell/shellContext";

// UWAGA: gdy sidebar jest zamknięty, to żaden element w środku, łącznie z tym, nie może otrzymać focusa, przez visibility:hidden

export function SidebarFilterInput() {
  const [query, setQuery] = useSearchPhraseAsState();
  const isTouchDevice = useTouchDevice();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { isSidebarOpen, closeSidebar } = useShellContext(); 
  
  // "zaznaczony" element
  const [$focus, $setFocus] = useState<HTMLElement | null>(null);
  
  useEffect(() => {
    const $input = inputRef.current;
    if (!$input) return;
    
    // FIXME: brzydki hak
    $input.addEventListener("sowaForceReveal", onForceReveal);
    
    const $sidebar = $input.closest(".main-sidebar");
    $sidebar?.addEventListener("click", onClickCapture, true);
    
    return () => {
      $input.removeEventListener("sowaForceReveal", onForceReveal);
      $sidebar?.removeEventListener("click", onClickCapture, true);
    }
    
    function onClickCapture(event: any) {
      // TODO!
      let $el = event.target?.closest(".main-sidebar__headerIcon, .main-sidebar__profileBtn, a.main-sidebar__entry");
      if ($el) {
        $el.tabIndex = -1;
        $setFocus($el);
      }
      if (event.target?.closest(".main-sidebar") === $sidebar) {
        event.target?.closest(".main-sidebar")?.querySelector("input")?.focus();
      }
    }
    
    function onForceReveal() {
      setQuery(" ");
      $input!.focus;
    }
  }, emptyArray);
  
  useEffect(() => {
    setFocus(null);
  }, [isSidebarOpen]);
  
  const queryTrimmed = query.trimStart();
  
  if (isTouchDevice)
    return null;
  
  return (
    <Input
      key="input"
      inputRef={inputRef}
      className="main-sidebar__filter"
      wrapperCls={query ? "filled" : undefined}
      type="search"
      fluid
      borderless
      value={queryTrimmed}
      onChange={setQuery1}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    />
  );
  
  function setQuery1(val: string) {
    setQuery(" " + val.trimStart());
  }
  
  function onBlur(event: React.FocusEvent<HTMLInputElement>) {
    const $el = document.activeElement;
    if ($el && $el.closest(".main-sidebar")) {
      setFocus($el);
      event.target.focus();
    }
    //else if ($el === document.body)
    //  event.target.focus();
  }
  
  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    const $input = event.currentTarget;
    const cursorPos = $input.selectionStart || 0;
    const hasSelection = Math.abs(cursorPos - ($input.selectionEnd || cursorPos)) > 0;
    const $sidebar = $input.closest(".main-sidebar")!;
    let $f = $focus;
    if ($f && $f.closest(".main-sidebar") !== $sidebar) {
      $f = null;
    }
    
    const modded = event.shiftKey || event.altKey || event.ctrlKey || event.metaKey;
    
    console.log("keyDown", event.key, modded, $f);
    
    switch (event.key) {
      case "ArrowLeft":
        if (modded) return;
          
        if (hasSelection || cursorPos > 0 || !$f) return;
        else if ($f.matches(".main-sidebar__headerIcon"))
          setFocus(findSibling($sidebar.querySelectorAll(".main-sidebar__headerIcon"), $f, -1));
        else if ($f.matches(".main-sidebar__profileBtn"))
          setFocus(findSibling($sidebar.querySelectorAll(".main-sidebar__profileBtn"), $f, -1));
        else if ($f.matches("a.main-sidebar__entry[aria-expanded='true']"))
          dispatchClick($f.querySelector(".main-sidebar__entry-expand"));
        break;
      
      case "ArrowRight":
        if (modded) return;
        
        if (hasSelection || cursorPos < $input.value.length || !$f) return;
        else if ($f.matches(".main-sidebar__headerIcon"))
          setFocus(findSibling($sidebar.querySelectorAll(".main-sidebar__headerIcon"), $f, 1));
        else if ($f.matches(".main-sidebar__profileBtn"))
          setFocus(findSibling($sidebar.querySelectorAll(".main-sidebar__profileBtn"), $f, 1));
        else if ($f.matches("a.main-sidebar__entry[aria-expanded='false']"))
          dispatchClick($f.querySelector(".main-sidebar__entry-expand"));
        break;
      
      case "ArrowUp":
        if (modded) return;
        
        event.preventDefault();
        
        if (!$f)
          setFocus($sidebar.querySelector(".main-sidebar__headerIcon"));
        else if ($f.matches("a.main-sidebar__entry")) {
          const $next = findSibling($sidebar.querySelectorAll("a.main-sidebar__entry"), $f, -1);
          if ($next !== $f)
            setFocus($next, "up");
          else
            setFocus($sidebar.querySelector(".main-sidebar__headerIcon"));
        }
        else if ($f.matches(".main-sidebar__profileBtn")) {
          const $list = $sidebar.querySelectorAll(".main-sidebar__headerIcon, a.main-sidebar__entry");
          setFocus($list.item($list.length - 1), "up");
        }
        
        break;
      
      case "ArrowDown":
        if (modded) return;
        
        event.preventDefault();
        
        if (!$f)
          setFocus($sidebar.querySelector("a.main-sidebar__entry"));
        else if ($f.matches("a.main-sidebar__entry")) {
          const $next = findSibling($sidebar.querySelectorAll("a.main-sidebar__entry"), $f, 1);
          if ($next !== $f)
            setFocus($next, "dn");
          else
            setFocus($sidebar.querySelector(".main-sidebar__profileBtn"));
        }
        else if ($f.matches(".main-sidebar__headerIcon"))
          setFocus($sidebar.querySelector("a.main-sidebar__entry, .main-sidebar__profileBtn"), "dn");
        
        break;
      
      case "Tab":
        if (modded && !event.shiftKey) return;
        
        event.preventDefault();
        
        if (!event.shiftKey) {
          if (!$f || $f.matches(".main-sidebar__headerIcon"))
            setFocus($sidebar.querySelector("a.main-sidebar__entry, .main-sidebar__profileBtn"), "dn");
          else if ($f.matches("a.main-sidebar__entry"))
            setFocus($sidebar.querySelector(".main-sidebar__profileBtn"));
        }
        else {
          if (!$f || $f.matches("a.main-sidebar__entry"))
            setFocus($sidebar.querySelector(".main-sidebar__headerIcon"));
          else if ($f.matches(".main-sidebar__profileBtn"))
            setFocus($sidebar.querySelector("a.main-sidebar__entry") || $sidebar.querySelector(".main-sidebar__headerIcon"), "up");
        }
        
        break;
      
      case "Enter":
        if (modded) return;
        
        event.preventDefault();
        
        if ($f) {
          dispatchClick($f);
        }
        
        break;
        
      case "Escape":
        if (modded) return;
        
        if (!query) {
          event.preventDefault();
          closeSidebar();
        }
        else if (query === " ") {
          event.preventDefault();
          setQuery("");
        }
        break;
      
      default:
        return;
    }
    
    //$sidebar.addAttribute
  }
  
  function setFocus($el: Element | null, direction?: "up"| "dn") {
    $focus?.removeAttribute("data-focused");
    $el?.setAttribute("data-focused", "1");
    $setFocus($el as HTMLElement | null);
    direction && scrollIntoView($el, direction === "up");
  }
}

function findSibling(nodes: NodeList | null | undefined, current: HTMLElement, offset: number) {
  if (!nodes)
    return null;
  let idx = 0, L = nodes.length;
  if (!L)
    return null;
  for (; idx < L; idx++) {
    if (nodes.item(idx) === current)
      break;
  }
  if (idx < L) {
    idx += offset;
    if (idx >= 0 && idx < L)
      return nodes.item(idx) as HTMLElement;
    return current;
  }
  return nodes.item(offset > 0 ? 0 : L - 1) as HTMLElement;
}

function dispatchClick($el: Element | null){
  $el = !$el || $el.matches("a, button") ? $el : $el.querySelector("a, button");
  $el && $el.dispatchEvent(new MouseEvent("click", {
    view: window,
    cancelable: true,
    bubbles: true,
  }))
}

function scrollIntoView($el: Element | null, direction: boolean){
  if (!$el) return;
  // @ts-ignore
  if ($el.scrollIntoViewIfNeeded) $el.scrollIntoViewIfNeeded();
  else $el.scrollIntoView(direction);
}
