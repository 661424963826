import React from "react";
import Dialog from "../Dialog";
import type { IDialog } from "../useDialog";

export type MessageDialogProps = {
  buttons?: readonly React.ReactElement[] | React.ReactFragment
  icon?: string
  children?: React.ReactNode
  size?: "small" | "medium"
  variant?: "normal" | "warning" | "error" | "fatal"
  unwrapped?: boolean
  dialog: IDialog
}

export function MessageDialog(props: MessageDialogProps) {
  const message = props.unwrapped ? props.children : <div>{props.children}</div>;
  return <Dialog buttons={props.buttons} dialog={props.dialog} size={props.size || "small"} icon={props.icon} type="message" className={`message-dialog--${props.variant || "normal"}`}>
    <div className="message-dialog__space">
      {message}
    </div>
  </Dialog>;
}
