import bootstrap from "../bootstrap";
import type { Dictionary } from "./types";

export function howManyDaysTill(date: Date | number) {
  // podaje ile dni pozostało do daty podanej w formacie "RRRR-MM-DD"
  // const rmd = date.split("-"); // [rok, miesiąc, dzień]
  // const parsedDate = new Date(rmd[0], rmd[1] - 1, rmd[2]);
  const today = Date.now();
  const differ = Math.floor((date as number /* .getTime() z automatu */ - today) / (1000 * 60 * 60 * 24));
  return differ;
}

export function getDateString(date: Date | undefined | null) {
  if (!date) return "";

  let D = new Date(date);
  let y = D.getFullYear();
  let m = D.getMonth() + 1;
  let d = D.getDate();
  
  return `${d < 10 ? "0" + d : d}.${m < 10 ? "0" + m : m}.${y}`;
}

export function getDateAndTimeString(date: Date | undefined | null) {
  if (!date) return "";

  let D = new Date(date);
  let h = D.getHours();
  let m = D.getMinutes();
  let s = D.getSeconds();
  
  return `${getDateString(date)}, ${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${s < 10 ? "0" + s : s}`;
}

// Zwraca kwotę w formacie "x zł x gr" (np. "2 zł 52 gr"), którą można zaprezentować w dowolnym języku
export function groszToZlotys(grosz: number) {
  if (typeof grosz !== "number")
    throw new Error("`grosz` is not a number");

  const groszString = grosz.toString();
  
  if(groszString.length === 1) {
    return `0 zł 0${groszString} gr`;
  }

  if(groszString.length === 2) {
    return `0 zł ${groszString} gr`;
  }

  return groszString.replace(
    groszString,
    `${groszString.substring(0, groszString.length - 2)} zł ${groszString.substring(groszString.length - 2)} gr`
  );
}

/** Sprawdzenie, czy element HTML zawiera się w innym elemencie */
export function isElementDescendant(child: Node, parent: Node) {
  let node = child.parentNode;
  while (node !== null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
}

/**
 * Wydobycie parametrów search z url
 * @param {String} search String search z props.location.search
 * @example ?q=1&cat=2 => { q: 1, cat: 2 }
 */
export function getSearchParams(search: string | undefined | null) {
  if (typeof search !== "string" || search.length === 0) {
    return {};
  }

  const pairs = search.slice(1).split("&");
  const params: Dictionary<string> = {};
  
  for (const pair of pairs) {
    const at = pair.indexOf("=");
    if (at > 0) {
      const name = pair.slice(0, at);
      const value = pair.slice(at + 1);
      params[name] = decodeURIComponent(value);
    }
  }

  return params;
}

const spRegexCache = new Map<string, RegExp>();
export function getSearchParam(name: string, url: string): string | undefined {
  let regex = spRegexCache.get(name);
  if (!regex) {
    regex = new RegExp(`[?&]${escapeRegExp(name)}=([^&]*)`);
    spRegexCache.set(name, regex);
  }
  const match = regex.exec(url);
  if (match)
    return match[1];
}

/**
 * Zwraca string search zawierający teraźniejsze i nowe zmiany w URL.
 * @param {string} search String search z props.location.search
 * @param {object} changes Objekt ze zmianami
 * @returns {string} eg. "?q=testquery"
 */
export function setSearchParams(search: string | undefined | null, changes: Dictionary<string | undefined>) {
  // FIXME: nie chcemy polegać raczej na pustych wartościach mających inne znaczenie niż brak wartości, trzeba by znaleźć takie przypadki w kodzie
  const params = { ...getSearchParams(search), ...changes };
  const result = Object.keys(params)
    .filter(e => params[e] !== undefined && params[e] !== null)
    .map(e => `${e}=${encodeURIComponent(params[e]!)}`)
    .join("&");
  return `?${result}`;
}

export const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * @description Not call a function again until a certain amount of time has passed without it being called.
 * @param {requestCallback} callback
 * @param {int} delay
 * @returns {function(): void}
 */
export function debounce<F extends (...args: any[]) => any>(callback: F, delay: number) {
  let timer: any = null;

  return function (this: any, ...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => { callback.apply(this, args); }, delay);
  };
}

/**
 * @description Not call a function more than once every X milliseconds.
 * @param {requestCallback} callback
 * @param {int} delay
 * @returns {function(): void}
 */
export function throttle<F extends (...args: any[]) => any>(callback: F, delay: number) {
  let isThrottled = false,
    args: Parameters<F> | null,
    context: any;

  function wrapper(this: any) {
    if (isThrottled) {
      // @ts-ignore
      args = arguments;
      context = this;
      return;
    }

    isThrottled = true;
    // @ts-ignore
    callback.apply(this, arguments);

    setTimeout(() => {
      isThrottled = false;
      if (args) {
        // @ts-ignore
        wrapper.apply(context, args);
        args = context = null;
      }
    }, delay);
  }

  return wrapper;
}

/**
 * @name getFileSize
 * @description Returns size of the selected file.
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/File/Using_files_from_web_applications#Example_Showing_file(s)_size} for the original code.
 */
export const getFileSize = (bytes: number | null | undefined) => {
  if (typeof bytes !== "number")
    return "";
  
  let nResult = bytes;
  let sUnit = "B";

  for (
    let nMultiple = 0,
      nApprox = bytes / 1024;
    nApprox > 1;
    nApprox /= 1024, nMultiple++
  ) {
    nResult = nApprox;
    sUnit = SIZE_UNITS[nMultiple];
  }
  
  const fmt = sUnit === "KiB"
    ? INTEGRAL
    : ONE_FRAC_DIGIT;
  
  return fmt.format(nResult) + " " + sUnit;
};

const INTEGRAL = new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 });
const ONE_FRAC_DIGIT = new Intl.NumberFormat(undefined, { maximumFractionDigits: 1 }); // TODO: użyć locale aplikacji zamiast przeglądarki
const SIZE_UNITS = Object.freeze(["KiB", "MiB", "GiB", "TiB"]);

const min = Math.min;

function strictEquality(a: any, b: any) {
  return a === b;
}

/** Dodanie elementu na początku listy, usunięcie duplikatu, przesunięcie pozostałych i obcięcie do zadanej długości.
 *  @param {*[]}                      array
 *  @param {*}                        element
 *  @param {function(*, *): boolean}  equals
 *  @param {number}                   limit
 */
export function unshiftUnique<T>(array: T[], element: T, equals = strictEquality, limit = 1000000) {
  // push 5
  // 01234 i=0
  // 51234 i=1 p=0
  // 50234 i=2 p=1
  // 50134 i=3 p=2
  // 50124 i=4 p=3
  // 50123

  // push 2
  // 01234 i=0
  // 21234 i=1 p=0
  // 20234 i=2 p=1
  // 20134 i=3 p= break
  
  if (typeof element === "undefined")
    throw new Error("unshiftUnique does not support undefined");
  
  let prev = array[0];
  array[0] = element;

  if (typeof prev === "undefined" || equals(prev, element))
    return;

  for (let i = 1, L = min(array.length + 1, limit); i < L; i++) {
    const here = array[i];
    array[i] = prev;
    if (equals(prev, element))
      break;
    else
      prev = here;
  }
}

export function getNameWithoutExtension(name: string | undefined | null) {
  if(!name) return name;
  let nameToDisplay = name;

  const dotIndex = name.lastIndexOf("."); // ostatnia kropka w stringu = początek rozszerzenia pliku

  if(dotIndex > -1) {
    nameToDisplay = name.substring(0, dotIndex); // jeśli nazwa zawiera rozszerzenie pliku (np. ".pdf"), nie wyświetlamy go
  }

  return nameToDisplay;
}

/** Wycięcie argumentu querystringa z zachowaniem kolejności.
 *  Zwraca [nowego URLa, wartości wycięte...] */
export function queryCut(url: string | undefined | null, paramName: string): readonly string[] {
  let results = [""];
  results[0] =
    (url || "").replace(/([?&])([\w.-]+)=([^&]*)/g, function($0, $1, $2, $3) {
      if ($2 === paramName) {
        results.push($3);
        return $1;
      }
      else
        return $0;
    }).replace(/\?$|&$/, "");
  return results;
}

const abs = Math.abs;

// TODO: przepisać na iteracyjne wersje

/** Szuka najbliższego elementu w posortowanej tablicy liczb. Zwraca jego indeks. */
export function binarySearchNearest(haystack: readonly number[], needle: number, s = 0, e = haystack.length): number | null {
  const m = ~~((s + e)/2);
  if (needle === haystack[m]) return m;
  if (e - 1 === s) return abs(haystack[s] - needle) > abs(haystack[e] - needle) ? e : s;
  if (needle > haystack[m]) return binarySearchNearest(haystack, needle, m, e);
  if (needle < haystack[m]) return binarySearchNearest(haystack, needle, s, m);
  return null;
}

/** Szuka największego elementu w posortowanej tablicy liczb, ktory jest mniejszy od zadanego. Zwraca jego indeks. */
export function binarySearchLesser(haystack: readonly number[], needle: number, notFound: any = null, s = 0, e = haystack.length): number | typeof notFound {
  const m = ~~((s + e)/2);
  if (needle === haystack[m]) return m;
  if (e - 1 === s) return haystack[e] < needle ? e : haystack[s] < needle ? s : notFound;
  if (needle > haystack[m]) return binarySearchLesser(haystack, needle, notFound, m, e);
  if (needle < haystack[m]) return binarySearchLesser(haystack, needle, notFound, s, m);
}

/** Szuka najmniejszego elementu w posortowanej tablicy liczb, ktory jest większy od zadanego. Zwraca jego indeks. */
export function binarySearchGreater(haystack: readonly number[], needle: number, notFound: any = null, s = 0, e = haystack.length - 1): number | typeof notFound {
  const m = ~~((s + e)/2);
  if (needle === haystack[m]) return m;
  if (e - 1 === s) return haystack[s] > needle ? s : haystack[e] > needle ? e : notFound;
  if (needle > haystack[m]) return binarySearchGreater(haystack, needle, notFound, m, e);
  if (needle < haystack[m]) return binarySearchGreater(haystack, needle, notFound, s, m);
}

/** Konwertuje HEX na RGBA */
export function hexToRgbA(hex: string, alpha= 1){
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      let c = hex.substring(1).split('');
      if(c.length === 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      const x = parseInt(c.join(''), 16);
      return `rgba(${(x>>16)&255}, ${(x>>8)&255}, ${x&255}, ${alpha})`
  }
  return `rgba(0, 0, 0, 1)`;
}

/** Zwraca pierwszy argument !== null|undefined. */
export function coalesce(...vals: any[]) {
  const L = vals.length;
  
  for (let i = 0; i < L; i++) {
    if (typeof vals[i] !== "undefined" && vals[i] !== null) return vals[i];
  }
  
  if (L) return vals[L - 1];
  
  return undefined;
};

export function hasObjectProperties(obj: object) {
  return Object.keys(obj).length > 0;
}

/** Funkcja do bezstanowego testowania czy string pasuje do regexa.
 *  Jest potrzebna ponieważ jak się okazuje, globalne regexy w JS (/foo/g)
 *  są mutowalne i trzymają offset od którego zaczynają porównywać.
 *  Tutaj resetujemy ten offset na 0 przed każdym porównaniem, żeby działało
 *  tak samo jak nie-globalne regexy. */
export function regexTest(regex: RegExp, haystack: string) {
  const val = regex.lastIndex;
  const global = typeof val === "number";
  
  if (global)
    regex.lastIndex = 0;
  
  const result = regex.test(haystack);
  
  if (global)
    regex.lastIndex = val;
  
  return result;
}

/** Powściągliwe kodowanie tekstu dla URLi, nie koduje nic poza symbolami, a spacje jako plusy. */
export function urlencode(s: string): string {
  return s.replace(/[ ;,/?:@&=+$#]/g, _urlencode as any); 
}
const urlencodeTable = new Map(" ;,/?:@&=+$#".split("").map(c => [c, c === " " ? "+" : "%" + c.charCodeAt(0).toString(16).toUpperCase()]));
const _urlencode = urlencodeTable.get.bind(urlencodeTable);

export function urldecode(s: string): string {
  return decodeURIComponent(s.replace(/\+/g, "%20"))
}

export function capitalizeFirstLetter(text: string) {
  return text.slice(0, 1).toUpperCase() + text.slice(1);
}

export function objectFilter<T>(obj: Dictionary<T>, predicate: (v: T, k: string) => boolean): Partial<typeof obj> {
  const result: typeof obj = {};
  const hasOwn = Object.hasOwn;
  for (const key in obj) {
    if (hasOwn(obj, key)) {
      const val = obj[key];
      if (predicate(val, key))
        result[key] = val;
    }
  }
  return result;
}

export function objectMap<T, U>(obj: Dictionary<T>, transform: (v: T, k: string) => U): { [K in keyof typeof obj]: U } {
  const result: any = {};
  const hasOwn = Object.hasOwn;
  for (const key in obj) {
    if (hasOwn(obj, key)) {
      const val = obj[key];
      result[key] = transform(val, key);
    }
  }
  return result;
}
