import { TopHamburger, TopLogin } from "components/layout/TopBar";
import React from "react";
import { Link } from "react-router-dom";
import { pages } from "utils/pages";
import { ActionSlot, type ActionSlotProps } from "components/shared/actions";
import { emptyObject } from "utils/constants";
import { tran } from "utils/language";
import { AppBarBarcodeInput } from "components/shell/appbar/BarcodeInput";

interface AppBarProps {
  app: keyof typeof pages;
  disabled?: boolean;
}

export function AppBar({ app, disabled = false }: AppBarProps) {
  const config = pages[app];

  return (
    <div className="top-bar">
      <TopHamburger />
      <div className="top-bar__info">
        <b className="hide-on-small-only">
          <Link to={config.fullPath}>{config.displayName}</Link>
        </b>
        {!disabled &&
          config.appBar?.leftActions?.map((action) => (
            <ActionSlot
              level="global"
              style={{ backgroundColor: "var(--main)", ...(action.style || emptyObject) }}
              {...action}
            />
          ))}
      </div>
      {!disabled && config.appBar?.barcode && (
        <AppBarBarcodeInput className="top-bar__barcode-input" />
      )}
      {!disabled && <AppBarDefinedActions actions={config.appBar?.rightActions} />}
      <TopLogin />
    </div>
  );
}

export interface IAppBar {
  leftActions?: readonly ActionSlotProps[];
  rightActions?: readonly ActionSlotProps[];

  /** Czy wyświetlać input kodów kreskowych */
  barcode?: boolean;
}

const AppBarDefinedActions = React.memo(({ actions }: { actions?: readonly ActionSlotProps[] }) => {
  if (!actions) return null;
  return (
    <>
      {actions.map((action) => {
        if (definedActions[action.name]) {
          return <ActionSlot level="global" name={action.name} {...definedActions[action.name]} />;
        }

        return <ActionSlot level="global" {...action} />;
      })}
    </>
  );
});

const definedActions: { [key: string]: Omit<ActionSlotProps, "name"> } = Object.freeze({
  "appbar.reports": {
    icon: "chart-mixed",
    label: tran("pages.topbar.reports"),
    variant: "icon",
  },
  "appbar.programs": {
    icon: "hammer",
    label: tran("pages.topbar.programs"),
    variant: "icon",
  },
  "appbar.collections": {
    label: tran("cataloguing.collections.button"),
    variant: "icon",
  },

  //Akcje z Czytelnii
  "appbar.preferences": {
    icon: "sliders-h",
    label: tran("journal.topbar.preferences"),
    variant: "icon",
  },
  "appbar.edit": {
    icon: "edit",
    label: tran("journal.topbar.events.edit"),
    variant: "icon",
  },
  "appbar.settings": {
    icon: "cog",
    label: tran("journal.topbar.settings"),
    variant: "icon",
  },
  "appbar.visibility": {
    label: tran("journal.topbar.events"),
    variant: "icon",
  },

  
});
