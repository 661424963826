import {useEffect, useRef, useState} from "react";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

import bootstrap from "../bootstrap";
import {sessionClient} from "./appclient";
import store from "store";
import {reportError} from "utils/errorReporting";

import { registerUpdateMarker, unregisterUpdateMarker } from "utils/hooks/markers";
import { scheduleReactUpdate } from "utils/hooks/batch";
import { alwaysVoid, doNothing, emptyArray, increment } from "utils/constants";

const freeze = Object.freeze || (v => v);

// TODO: periodyczne odświeżanie w wypadku błędu?

export class CapiCache {
  constructor(client, size, validity = 600e3 /* 10 min */) {
    this.client = client;
    this.size = size;
    this.validity = validity;
    
    ALL_CACHES.push(this);
  }
  
  _data = {};
  _mounted = {};
  
  makeCommand(id) {
    throw new Error("Not implemented!");
  }
  
  objectIsLoading(id) {
    throw new Error("Not implemented!");
  }
  
  objectFromResult(id, result) {
    throw new Error("Not implemented!");
  }
  
  objectFromError(id, err) {
    throw new Error("Not implemented!");
  }

  put(id, data) {
    id = id.toString();
    
    this._data[id] = freeze({
      ...data,
      _state: "ok",
      _loadedAt: new Date(),
    });
  }

  get(id) {
    id = id.toString();
    
    const data = this._data;
    let cached = data[id];
    if (!cached) {
      if (this.client) {
        // pierwsze ładowanie
        cached = data[id] = freeze({
          ...this.objectIsLoading(id),
          _state: "loading",
        });

        this.load(id);
      }
      else {
        cached = data[id] = freeze({
          ...this.objectIsLoading(id),
          _state: "missing",
        })
      }
    }
    else if (cached._loadedAt) {
      // wygasanie i odświeżanie
      const now = new Date();
      if (now - cached._loadedAt > this.validity) {
        this.load(id);
      }
    }
    return cached;
  }
  
  async load(id) {
    id = id.toString();
    
    const session = SESSION;
    if (! session)
      return;
    
    const data = this._data;
    let cached = data[id];
    
    try {
      const client = sessionClient(session, this.client);
      const result = await client.executeSingle(this.makeCommand(id));
      cached = data[id] = freeze({
        ...data[id],
        _state: "ok",
        ...this.objectFromResult(id, result),
        _loadedAt: new Date(),
      });
    }
    catch (err) {
      reportError(err);
      
      cached = data[id] = freeze({
        ...data[id],
        _state: "error",
        ...this.objectFromError(id, err),
        _loadedAt: new Date(),
      });
    }
    
    // aktualizujemy wszystkie zamontowane komponenty, które wyświetlają info o tym obiekcie
    const m = this._mounted[id];
    if (m) {
      m.forEach (setter => {
        scheduleReactUpdate(setter, cached);
      });
    }
  }
  
  /** To jest hook */
  use(id) {
    if (id !== notYet)
      id = id.toString();
    
    // Głupek myśli, że to komponent klasowy
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [localData, setLocalData] = useState(() => {
      if (id === notYet)
        return freeze({ ...this.objectIsLoading(id), _state: "loading" });
      else
        return this.get(id);
    });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      if (id !== notYet) {
        // mount
        // rejestrujemy się jako komponent wyświetlający info o danym obiekcie
        const mounted = this._mounted;
        const m = mounted[id] = mounted[id] || [];
        m.push(setLocalData); // setLocalData ma stałą tożsamość
        setLocalData(this.get(id)); // aktualizujemy na wypadek zmiany id
        
        return () => {
          // unmount
          const i = m.indexOf(setLocalData);
          if (i >= 0) {
            if (m.length > 1)
              m[i] = m[m.length - 1];
            m.pop();
          }
        };
      }
    }, [id]);
    
    return localData;
  }
}

const loadingResult = freeze({
  status: null,
  _state: "loading",
  _refresh: doNothing,
});

export const LOADING_RESULT = loadingResult;

const notInstalledResult = freeze({
  status: null,
  _state: "missing",
  _refresh: doNothing,
});

export const NOT_INSTALLED_RESULT = notInstalledResult;

/** Sprytny sposób na odwleczenie wykonywania zapytania do czasu skończenia innego zapytania
 *  Jeśli dowolny argument zapytania będzie === notYet, to zapytanie się nie wykona.
 *  Od strony wywołania możemy użyć następującej konstrukcji:
 *  
 *  notYet(wynikZapytania1) || notYet(wynikZapytania2) || { rzeczywisty argument }
 *  
 *  I argument zostanie wyliczony i przekazany tylko jeśli oba zapytania poprzedzające się zakończyły.
 */
export const notYet = result => (result && isLoaded(result)) ? false : notYet;

/** Czy zapytanie jest ciągle w trakcie ładowania lub oczekiwania na możliwość wykonania? */
export const isLoading = result => result._state === "loading";

/** Czy zapytanie jest ciągle w trakcie ładowania, oczekiwania na możliwość wykonania lub ponownego wykonywania? */
export function isReloading(result) {
  const s = result._state;
  return s === "loading" || s === "reloading" || s === "error_reloading" ;
}

/** Czy zapytanie jest ciągle w trakcie ładowania, oczekiwania na możliwość wykonania lub ponownego wykonywania?
 *  Różni się od `isReloading` tym, że sygnalizuje `true` wcześniej, zanim odpalą się efekty po renderowaniu.
 *  Co może powodować błędne pozytywy w concurrent mode, ale pozwala zapobiegać migotaniu w normalnym przebiegu wykonania.
 *  Jest potrzebne gdy łączymy podczas przetwarzania dane wejściowe hooka z wyjściowymi.
 *  TODO: może nie byłoby to potrzebne gdyby hooki CAPI wspierały zwracanie danych wejściowych zsynchronizowanych z wyjściowymi,
 *        ale to byłoby bardziej skomplikowane API...
 */
export function hasReloadingIntent(result) {
  const s = result._state;
  return s === "loading" || s === "reloading" || s === "error_reloading" || s === "reloading_intent";
}

export function wasCached(result) {
  return !!result._cached;
}

/** Czy zapytanie zakończyło się błędem?
 *  Standardowo odpowiedź 4xx czy 5xx nie jest traktowana jako błąd,
 *  chyba, że użyjemy `ensure` */
export const isError = result => result._state === "error" || result._state === "error_reloading";

/** Czy odpowiedź przyszła i nie wystąpił błąd? */
export const isLoaded = result => result._state === "ok" || result._state === undefined  || result._state === "reloading" || result._state === "reloading_intent";

/** Czy aplikacja do której idzie zapytanie jest zainstalowana? */
export const isInstalled = result => result._state !== "missing";

const defaultStatuses = freeze([200]);

/** Zamiana odpowiedzi na zapytanie CAPI na błąd jeśli nie zakończyło się jednym z podanych statusów. */
export const ensure = (result, ...statuses) => {
  const _state = result._state;
  
  if (statuses.length === 0)
    statuses = defaultStatuses;
  
  if ((_state === "ok" || _state === "reloading" || _state === "reloading_intent" || typeof _state === "undefined")
    && (statuses.indexOf(result.status) < 0 && statuses.indexOf(result.reason) < 0))
  {
    const err = freeze({
      message: "Operacja tymczasowo niedostępna, prosimy spróbować później.",
      ...result,
      _state: _state === "reloading" ? "error_reloading" : "error",
    });
    
    // // to powinno być zbędne... ale wyrzucenie wymagałoby przetestowania
    // if (result._set)
    //   result._set(err);
    
    return err;
  }
     
  return result;
};

const isNotYet = v => v === notYet;

let ID = 0;

/** Hook wywołujący komendę CAPI za każdym razem jak zmienią się argumenty (porównywane głęboko).
 *  Zakładamy, ze przy zmianie obiektu klienta wywołujemy ponownie tylko jeśli jego url lub język
 *  jest inny (a nie inne opcje). */
export function useCapi(client, command, ...args) {
  const [result, setResult] = useState(client === null ? notInstalledResult : loadingResult); // tutaj składujemy wynik wywołania CAPI
  const [guard, setGuard] = useState(0); // tego używamy do wymuszenia ponownego wywołania CAPI
  const clientNeedsSession = client ? (!client.auth && !client.dontLogin) : false;

  // czekamy z wywołaniem na sesję, jak się pojawi to listener wymusi zmianę stanu przez setGuard
  // czekamy też aż wszystkie argumenty będą gotowe
  // client===null traktujemy jak stan gotowości, bo chcemy wywołać fetchEffect by przewinąć nr sekw.
  // i zapobiec zwróceniu wyniku z potencjalnych wywołań w toku
  const isReady = (SESSION || !clientNeedsSession) && !isNotYet(client) && !args.some(isNotYet);
  
  let firstRender = false;
  
  const stateRef = useRef(null);
  if (stateRef.current === null) {
    firstRender = true;
    stateRef.current = {
      seqNo: 0, // nr sekwencyjny zapytania w ramach hooka
      id: "capi-" + (++ID), // tego używamy do identyfikacji hooka w globalnym rejestrze
      cached: null, // odczytana z cache'a odpowiedź
      args: undefined, // argumenty ostatnio zapisane podczas wykonywania zapytania
      intentCacheIn: null, // jeśli sygnalizujemy zamiar przeładowania, to tutaj trzymamy odpowiedź źródłową
      intentCacheOut: null, // a tutaj wynikową
      
      prevClient: null,
      prevGuard: null,
      prevCommand: null,
      
      loadSignalsIn: emptyArray, // wywołujemy funkcje z tej tablicy po załadowaniu zapytania
      loadSignalsOut: emptyArray, // przez przeniesienie ich do tej tablicy najpierw, a potem odpalenie ich w efekcie
      
      refresh: () => scheduleReactUpdate(setGuard, increment),
      
      setResult,
      
      updateWithNewData: (newResult) => {
        setResult(oldResult => {
          // potencjalnie możemy zaoszczędzić na aktualizacjach komponentów jeśli re-użyjemy starego
          // obiektu danych, skoro są równe
          const data = isEqual(oldResult.data, newResult.data) ? oldResult.data : newResult.data;
          return freeze({
            ...newResult,
            data,
            _state: "ok",
            _refresh: hookState.refresh,
            _set: setResult, // potrzebne do ensure()
          });
        });
      },
      
      loadSignalsEffect: () => {
        hookState.loadSignalsOut.forEach(scheduleReactUpdate);
        hookState.loadSignalsOut = emptyArray;
      }
    }
  }
  
  const hookState = stateRef.current;
  
  useEffect(() => {
    if (clientNeedsSession) {
      const id = hookState.id;
      
      // mount
      ALL_COMMANDS.set(id, hookState);

      return () => {
        // unmount
        ALL_COMMANDS.delete(id);
        
        // dla spójności trzeba wykonać pozostałe sygnały ładowania przy odmontowaniu,
        // żeby nic się nie zwiesiło czekając na hooka, którego już nie ma
        hookState.loadSignalsEffect();
      };
    }
  }, [clientNeedsSession]);
  
  const argsToSave = isReady ? (isEqual(args, hookState.args) ? hookState.args : args) : undefined;
  
  /** @type () => void */
  let fetchEffect = doNothing;
  if (isReady) {
    fetchEffect = function fetchEffect() {
      if (client === null) {
        hookState.seqNo++;
        return;
      }
      
      if (client === hookState.prevClient && guard === hookState.prevGuard && command === hookState.prevCommand && argsToSave === hookState.args)
        return;// console.log("dedup");
      
      hookState.prevClient = client;
      hookState.prevGuard = guard;
      hookState.prevCommand = command;
      
      // w przygotowaniu na concurrent mode dopiero tutaj zapamiętujemy poprzednie argsy dla porównania
      // żeby spekulatywne wykonanie hooka nie powodowało odświeżenia prawdziwego
      // useEffect się spekulatywnie nie wykonuje
      if (argsToSave && (argsToSave !== hookState.args))
        hookState.args = cloneDeep(argsToSave);

      const memoArgs = argsToSave && hookState.args;

      setResult(updateResultInProgress);
      
      const cl = clientNeedsSession
        ? sessionClient(SESSION, client)
        : client;
      const cmd = new command(...memoArgs);
      const seq = ++hookState.seqNo;
      const auth = cl.auth;
      const lang = cl.lang;
      
      cl.executeSingle(cmd)
        // .then(resp => promiseTimeout(5000, resp)) // do debugowania
        .then(resp => {
          const _markers = cmd.getMarkerList ? getMarkerList(cmd, cl) : undefined;
          const result = { ...resp, _markers };
          
          if (resp.status < 300 && !command.dontCache)
            cacheResult(cmd.fingerprint(auth, lang), result);

          // TODO: AbortController w CapiClient
          // sprawdzamy nr sekw. żeby aplikować tylko najnowszy wynik
          if (hookState.seqNo !== seq)
            return;
          
          hookState.loadSignalsOut = hookState.loadSignalsIn;
          hookState.loadSignalsIn = emptyArray;
          scheduleReactUpdate(hookState.updateWithNewData, result);
        })
        .catch(err => {
          reportError(err);
          
          if (hookState.seqNo !== seq)
            return;
          hookState.loadSignalsOut = hookState.loadSignalsIn;
          hookState.loadSignalsIn = emptyArray;
          
          // TODO: lepsza obsługa błędów
          scheduleReactUpdate(setResult, freeze({
            status: 500,
            message: (err && err.enduserMessage) || "Wystąpił błąd podczas komunikacji z serwerem",
            _state: "error",
            _refresh: hookState.refresh,
          }));
        });
    } 
  }
  
  useEffect(hookState.loadSignalsEffect, [hookState.loadSignalsOut]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(fetchEffect, [guard, client && client.url, client && client.lang, client && client.auth, command, argsToSave]);
  
  // automagiczne śledzenie co ta komenda zawiera gdy jest zamontowana,
  // bo zakładamy, że te dane są wyświetlane
  useEffect(() => {
    const markers = result._markers;
    if (markers) {
      for (const marker of markers)
        registerUpdateMarker(marker, hookState.refresh);
      return () => unregisterUpdateMarker(hookState.refresh);
    }
  }, [result._markers]);
  
  if (!client)
    return notInstalledResult;
  
  if (result === loadingResult) {
    // TODO: pomyśleć nad bardziej agresywnym cache'owaniem, bez firstRender
    if (firstRender && isReady) {
      // z cache'a pobieramy tylko podczas montowania, bo służy to do przyspieszenia pierwszego wyświetlenia komponentu
      // po pobraniu danych z serwera i tak nie resetujemy do "pustego" stanu poza wyjątkowymi sytuacjami
      // (jak przelogowanie się)
      const cl = clientNeedsSession
        ? sessionClient(SESSION, client)
        : client;
      const cmd = new command(...args);
      const fp = cmd.fingerprint(cl.auth, cl.lang);
      const cached = ALL_RESULTS.get(fp);
      // console.log("?", fp, cached);
      if (cached) {
        hookState.cached = freeze({
          ...cloneDeep(cached),
          _state: "reloading",
          _cached: true,
          _refresh: () => scheduleReactUpdate(setGuard, increment),
          _set: setResult,
        });
      }
    }
    
    if (hookState.cached)
      return hookState.cached;
  }
  else
    // jeśli raz zwróciliśmy świeży wynik, to nawet po totalnym przeładowaniu nie używamy ponownie starego wyniku z cache'a
    hookState.cached = null;
  
  if (isReady && (hookState.args !== argsToSave) && result._state === "ok") {
    if (hookState.intentCacheIn !== result) {
      hookState.intentCacheIn = result;
      hookState.intentCacheOut = freeze({
        ...result,
        _state: "reloading_intent"
      })
    }
      
    return hookState.intentCacheOut;
  }
  
  return result;
}

function getMarkerList(cmd, client) {
  const result = cmd.getMarkerList(client);
  // oryginalnie wspieraliśmy iteratory, ale ponieważ teraz wspieramy cache'owanie
  // to musimy być w stanie porównywać wartości, a do tego potrzebujemy tablicy
  if (!Array.isArray(result))
    return Array.from(result);
  return result; 
}

function updateResultInProgress(result) {
  const state = result._state;
  
  if (state === "ok")
    return freeze({
      ...result,
      _state: "reloading"
    });
  
  if (state === "error")
    return freeze({
      ...result,
      _state: "error_reloading"
    });
  
  return result;
}

export function useFolks(command, ...args) {
  return useCapi(bootstrap.folks.client, command, ...args);
}

export function useTeka(command, ...args) {
  const client = bootstrap.teka.client;
  if (client) {
    // normalnie nie wolno wywoływać hooka pod ifem, ale bootstrap zawiera absolutne stałe,
    // nie zmienia się bez przeładowania wszystkiego, więc to tak jakby ten if znikał przy inicjalizacji programu
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useCapi(client, command, ...args);
  }
  else
    return notInstalledResult;
}

export function useSowa(catId, command, ...args) {
  let client = notYet;
  
  if (!isNotYet(catId)) {
    const cat = bootstrap.sowa.cataloguesById[catId];
    if (cat)
      client = cat.client;
    else
      client = null;
  }
  
  return useCapi(client, command, ...args);
}

export function useKasa(command, ...args) {
  return useCapi(bootstrap.kasa.client, command, ...args);
}

/* Powtarza wykonanie komendy co `interval` aż `condition(result)` zwróci prawdę */
export function useCapiWhile(client, condition, interval, command, ...args) {
  const result = useCapi(client, command, ...args);
  useEffect(() => {
    if (isLoaded(result) && !isReloading(result) && condition(result)) {
      const timer = setTimeout(result._refresh, interval);
      return () => {
        clearTimeout(timer);
      }
    }
  }, [result]);
  return result;
}

function cacheResult(fingerprint, result) {
  const results = ALL_RESULTS;
  results.delete(fingerprint);
  if (results.size >= 32) {
    for (const k of results) {
      // usuwamy najstarszy element
      results.delete(k);
      break;
    }
  }
  // console.log("+", fingerprint, result);
  results.set(fingerprint, result);
}

const ALL_CACHES = [];
const ALL_COMMANDS = new Map();
const ALL_RESULTS = new Map();

let SESSION = (() => {
  const state = store.getState().session;
  const session_id = (state && state.session_id) || null;
  if (session_id)
  // format identyczny do store'a, żeby appclient przyjął
    return { session: { session_id, session_key: state.session_key } };
  else
    return null;
})();

// czekamy na ustawienie sesji w storze
// * ustawienie na null powoduje wyczyszczenie cache'y i odrzucenie starych pobranych odpowiedzi na komendy
// * ustawienie innej sesji powoduje [ponowne] wykonanie aktywnie zamontowanych żądań
store.subscribe(() => {
  const sessionId = SESSION ? SESSION.session.session_id : null;
  const state = store.getState().session;
  const newSessionId = (state && state.session_id) || null;
  
  if (newSessionId !== sessionId) {
    ALL_RESULTS.clear();
    
    if (newSessionId) {
      SESSION = { session: { session_id: newSessionId, session_key: state.session_key } };
      
      // odświeżamy dane
      ALL_CACHES.forEach(cache => Object.getOwnPropertyNames(cache._data).forEach(id => cache.load(id)))
      
      refreshAllMountedCapiHooks();
    }
    else {
      SESSION = null;
      
      // kasujemy dane
      ALL_CACHES.forEach(cache => {
        Object.getOwnPropertyNames(cache._data).forEach (id => {
          cache._data[id] = {
            ...cache.objectIsLoading (id),
            _state: "loading",
          };
        });
      });
      
      for (const hookState of ALL_COMMANDS.values()) {
        scheduleReactUpdate(hookState.setResult, loadingResult);
      }
    }
  }
});

export function refreshAllMountedCapiHooks() {
  for (const hookState of ALL_COMMANDS.values()) {
    scheduleReactUpdate(hookState.refresh);
  }
}

export function refreshAllMountedCapiHooksAndWait() {
  const promises = [];
  for (const hookState of ALL_COMMANDS.values()) {
    scheduleReactUpdate(hookState.refresh);
    promises.push(new Promise(resolve => {
      hookState.loadSignalsIn = [...hookState.loadSignalsIn, resolve];
    }));
  }
  return Promise.allSettled(promises).then(alwaysVoid);
}

window.refreshAllMountedCapiHooksAndWait = refreshAllMountedCapiHooksAndWait;