import React, { useLayoutEffect, useRef, useState } from "react";
import { emptyArray } from "utils/constants";

export interface TabImpl<T> {
  // value: T - logicznie tutaj jest jakaś wartość, ale jej nazwy nie znamy, bo Tabs ma value, a LinkTabs ma link
}

type TabsMainProps<T, Tab extends TabImpl<T>> = {
  type?: "primary" | "secondary" | "segment";
  center?: boolean;
  style?: React.CSSProperties;
  tabs: readonly Tab[];
  tabConstructor: (tab: Tab, isCurrent: boolean) => React.ReactNode;
  valueFromTab: (tab: Tab) => T;
  value: T
}

const GRADIENT_WIDTH = 24;

export function TabsImpl<T, Tab extends TabImpl<T>>({ type = "primary", center = false, style, tabs, tabConstructor, valueFromTab, value }: TabsMainProps<T, Tab>) {
  const tabsRef = useRef<HTMLUListElement>(null);

  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  
  const currentTab = tabs.find(tab => valueFromTab(tab) === value) || tabs[0];
  
  useLayoutEffect(() => {
    if(tabsRef.current) {
      const $tabs = tabsRef.current;
      const $scroll = $tabs.parentElement!;
      
      const $tab = $tabs.querySelector(".tabs__tab--active") as HTMLLIElement;
      
      const resizeObserver = new ResizeObserver(() => {
        const $tab = $tabs.querySelector(".tabs__tab--active") as HTMLLIElement;
        $scroll.style.scrollBehavior = "auto"; // nie chcemy animować pierwszego renderowania, zmiany rozmiaru też nie trzeba
        handleScroll();
        if ($tab) scrollToTab($tab);
        $scroll.style.scrollBehavior = "";
      });
      
      resizeObserver.observe($tabs);
      resizeObserver.observe($scroll);
      
      $scroll.style.scrollBehavior = "auto"; // nie chcemy animować pierwszego renderowania
      handleScroll();
      scrollToTab($tab);
      $scroll.style.scrollBehavior = "";
      
      $scroll.addEventListener('scroll', handleScroll);
      
      return () => {
        $scroll.removeEventListener('scroll', handleScroll);
        resizeObserver.unobserve($tabs);
        resizeObserver.unobserve($scroll);
        resizeObserver.disconnect();
      }
    }
  }, emptyArray);

  return (
    <div
      role="tablist"
      style={style}
      className={`
        tabs-wrapper tabs-wrapper--${type}
        ${center ? "tabs-wrapper--center" : ""}
        ${showLeftArrow ? `tabs-wrapper--fadeout-left` : ""}
        ${showRightArrow ? `tabs-wrapper--fadeout-right` : ""}
      `}
    >
      <div 
        aria-hidden="true"
        className={`
          arrow-wrapper
          arrow-wrapper--left
          arrow-wrapper--${type}
          ${showLeftArrow ? "" : "arrow-wrapper--hidden"}
        `}
        onMouseDown={handleArrowMouseDown}
        onTouchStart={handleArrowMouseDown}
      >
        <div
          className={`
            arrow
            arrow--left
          `}
        >
        </div>
      </div>

      <div
        aria-hidden="true"
        className={`
          arrow-wrapper
          arrow-wrapper--right
          arrow-wrapper--${type}
          ${showRightArrow ? "" : "arrow-wrapper--hidden"}
        `}
        onMouseDown={handleArrowMouseDown}
        onTouchStart={handleArrowMouseDown}
      >
        <div
          className={`
            arrow
            arrow--right
          `}
        >
        </div>
      </div>
      <div className="tabs-scroller">
        <ul
          className={`rst tabs tabs--${type}`}
          ref={tabsRef}
          onKeyDown={handleArrowPress}
        >
          {tabs.map((tab, index) => {
            // Tab dla komponentu `Tabs`
            const isCurrentTab = tab === currentTab;
            return (
              <li
                onKeyDown={scrollToTab}
                onClick={scrollToTab}
                key={String(valueFromTab(tab))}
                className={`tabs__tab tabs__tab--${type} ${isCurrentTab ? `tabs__tab--active` : ""}`}
              >
                {tabConstructor(tab, isCurrentTab)}
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  );

  function handleScroll() {
    if(!tabsRef.current) return;
    
    const $tabs = tabsRef.current;
    const $scroll = $tabs.parentElement!;
    
    const scrollLeft = $scroll.scrollLeft;
    const clientWidth = $scroll.clientWidth;
    const scrollWidth = $scroll.scrollWidth;

    if(scrollLeft > 0) {
      setShowLeftArrow(true);
    } else {
      setShowLeftArrow(false);
    }

    if(scrollWidth > clientWidth && scrollLeft < scrollWidth - clientWidth - 2 /* -2 żeby ukryć niedokładności */) {
      setShowRightArrow(true);
    } else {
      setShowRightArrow(false);
    }
  }
}

function scrollToTab(e: React.SyntheticEvent<HTMLLIElement> | HTMLLIElement) {
  const $tab = e instanceof HTMLLIElement ? e : e.currentTarget;
  const $scroll = $tab.closest(".tabs-scroller")!;
  
  const overflowsRight = $scroll.scrollLeft + $scroll.clientWidth < $tab.offsetLeft + $tab.clientWidth;
  const overflowsLeft = $scroll.scrollLeft > ($tab.offsetLeft - ($scroll.scrollLeft ? GRADIENT_WIDTH : 0));
  
  if (overflowsLeft || overflowsRight) {
    $tab.scrollIntoView(false);
    $scroll.scrollLeft = $tab.offsetLeft - GRADIENT_WIDTH;
  }
}

function handleArrowPress(e: React.KeyboardEvent<HTMLUListElement>) {
  const target = e.target as Element;
  const opt = target.closest(".tabs__tab");
  if (!opt) return;
  const $scroll = e.currentTarget.parentElement!;
  let to = opt as HTMLElement;
  
  switch (e.key) {
    case "ArrowLeft":
      to = to.previousElementSibling as HTMLElement;
      break;
    case "ArrowRight":
      to = to.nextElementSibling as HTMLElement;
      break;
    default:
      return;
  }
  
  if (!to || !to.focus) return;
  const content = to.firstElementChild as HTMLElement;
  if (!content) return;
  
  e.preventDefault();
  e.stopPropagation();
  content.focus();
  to.scrollIntoView(false);
  $scroll.scrollLeft = to.offsetLeft - GRADIENT_WIDTH;
}

function handleArrowMouseDown(e: React.SyntheticEvent<HTMLDivElement, Event>) {
  const target = e.target as Element;
  const wrapper = target.closest(".tabs-wrapper");
  if (!wrapper) return;
  const scrollable = wrapper.querySelector(".tabs-scroller") as HTMLElement;
  if (!scrollable) return;
  
  if (e.type === "mousedown" && (e as any).button !== 0) return;
  
  const direction = target.className.indexOf("--left") >= 0 ? -1 : 1;
  let quantum = 0.0000004;
  let prevTimestamp = 0, movementQueued = 0, movementDone = 0;
  let frameTimer = requestAnimationFrame(onFrame);
  let start = -1;
  
  // smooth kompletnie rozpieprza próby ręcznej animacji
  scrollable.style.scrollBehavior = "auto";
  
  document.addEventListener("mouseup", onMouseUp);
  document.addEventListener("touchend", onMouseUp);
  document.addEventListener("touchcancel", onMouseUp);
  window.addEventListener("contextmenu", onContextMenu); // trzeba wyłączyć na androidzie
  
  e.preventDefault();
  e.stopPropagation();
  
  function onMouseUp() {
    cleanUp();
  }
  
  function onContextMenu(e: any) {
    e.preventDefault();
    e.stopPropagation();
  }
  
  function onFrame(timestamp: number) {
    if (start < 0) {
      start = timestamp;
    }
    else {
      timestamp -= start;
      prevTimestamp = timestamp;
      movementQueued = timestamp * timestamp * timestamp * quantum;
      let movementNow = movementQueued - movementDone;
      
      if (movementNow >= 1) {
        movementNow = Math.floor(movementNow);
        movementDone += movementNow;
  
        const max = scrollable.scrollWidth - scrollable.clientWidth;
        const to = Math.min(max, Math.max(0, scrollable.scrollLeft + movementNow * direction));
        scrollable.scrollLeft = to;
  
        if (to <= 0 || to >= max)
          return cleanUp();
      }
    }
    
    frameTimer = requestAnimationFrame(onFrame);
  }
  
  function cleanUp() {
    scrollable.style.scrollBehavior = "";
    cancelAnimationFrame(frameTimer);
    document.removeEventListener("mouseup", onMouseUp);
    document.removeEventListener("touchend", onMouseUp);
    document.removeEventListener("touchcancel", onMouseUp);
    window.removeEventListener("contextmenu", onContextMenu);
    if (prevTimestamp < 250) {
      // kliknięcie a nie trzymanie
      scrollable.scrollLeft += 250 * direction;
    }
  }
}
