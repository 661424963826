import React, {FunctionComponent, useEffect, useRef} from "react";
//import { tabbable } from "tabbable";

import {useSignal} from "utils/hooks/other";
import {emptyArray, emptyObject} from "utils/constants";
import ErrorBoundary from "components/shared/ErrorBoundary";
import {ErrorDialog} from "components/shared/Dialog/comps";
import {translate} from "utils/language";
import {Button} from "components/shared";

import {captureTab} from "./tabs";
import { ActionService } from "../../shared/actions";

/**
 * Usługa pozwalająca komponentom poniżej wyświetlać nakładki nad główną treścią strony,
 * np. dla modali.
 */

export const OverlayServiceContext = React.createContext({} as OverlayServiceContext);

export type OverlayOptions = {
  /** Czy wyłączyć możliwość zamykania metodą `close`? (Potrzebne gdy widoczność sterowana jest zewnętrznie) */
  disableClose?: boolean
  
  createClosed?: boolean
  
  /** Opcjonalny callback wywoływany kiedy zmieni się tożsamość węzła DOM zawierającego nakładkę */
  onRef?: (ref: HTMLElement | null) => void
}

/** API usługi nakładek */
export interface OverlayServiceContext {
  createOverlay(type: OverlayType, content: JSX.Element, options?: OverlayOptions): OverlayState
}

/** API nakładki */
export interface OverlayState {
  readonly type: OverlayTypeDef
  readonly isOpen: boolean
  
  /** Zamknięcie nakładki, jeśli zamykanie nie jest wyłączone bo obsługuje je inna warstwa logiki.
   *  Zasadniczo wszystkie interaktywne metody zamknięcia to wywołują. */
  readonly close: (...args: any[]) => void
  
  /** Zamknięcie nakładki bezwarunkowo, wywoływane w momencie odmontowania komponentu,
   *  lub przez w/w dalszą warstwę logiki, która wie lepiej kiedy zamknąć. */
  readonly forceClose: (...args: any[]) => void
  
  readonly onRef?: (ref: HTMLElement | null) => void
  
  /** Aktualizacja zawartości nakładki */
  update(element: JSX.Element): void
  
  /** Callback wywoływany po wywołaniu close oraz forceClose, klient API może ustawić */
  onClose?: (...args: any[]) => void
  
  /** Callback wywoływany zamiast close, klient API może ustawić */
  doClose?: (...args: any[]) => void
  
  /** Callback wywoływany przed close, by sprawdzić czy można zamknąć */
  canClose?: (...args: any[]) => (boolean | Promise<boolean>)
}

interface OverlayStatePriv extends OverlayState {
  isOpen: boolean
  
  content: JSX.Element
  
  ref: HTMLElement | null
  stolenFocus: HTMLElement | null
  
  id: string
  
  readonly onRefInternal?: (ref: HTMLElement | null) => void
  readonly onFocusIn: (event: FocusEvent) => void
  readonly onFocusOut: (event: FocusEvent) => void
}

let ID = 0;
const OverlayService: FunctionComponent = ({ children }) => {
  type State = OverlayServiceContext & {
    /** Stos stanów otwartych nakładek */
    stack: (OverlayStatePriv | undefined)[]
    /** Czy wyłączyć interakcje myszą z główną treścią strony? */
    disableMouse: boolean
    /** Czy wyłączyć interakcje ARIA z główną treścią strony? */
    disableAria: boolean
    /** Lista elementów do renderowania nakładek */
    elements: (JSX.Element | null)[]
    _onMount: () => () => void
  }
  
  const ref = useRef(null as any as State);
  const signal = useSignal();
  
  if (ref.current === null) {
    const updateStack = () => {
      const context = ref.current;
      const stack = context.stack;
      
      while (stack.length > 0 && !stack[stack.length - 1])
        stack.length = stack.length - 1;
  
      // TODO: opt
      const rootElements = context.elements = [] as (JSX.Element | null)[];
      const L = stack.length;
      rootElements.length = L;
      let nestedElements = [] as (JSX.Element | null)[];
      
      let disableMouse = false;
      let disableTab = false;
      let disableAria = false;
      let r = L;
      for (let i = L - 1; i >= 0; i--) { // jedziemy wstecz, tzn. od najnowszej do najstarszej nakładki
        const overlay = stack[i];
        if (overlay) {
          const type = overlay.type;
          const props = {
            key: "" + i,
            className: disableMouse ? "overlayService__wrapper inert" : "overlayService__wrapper",
            "aria-hidden": disableAria ? "true": "false",
            children: overlay.content,
            id: (type.disablesTab && !disableTab) ? "overlayService__tabRoot" : undefined
          };
          
          if (type.nested)
            nestedElements.unshift(React.createElement("div", props));
          else {
            // zawieramy podnadkładki w nakładce i resetujemy listę podnakładek
            // pozwala nam to ograniczyć focusa do nakładki i wszystkich podnakładek razem
            // tzn. DOM wygląda tak:
            // - content
            // - stack
            //   - wrapper 1 <-- blokada taba tutaj blokuje w całym poddrzewie
            //     - overlay 1
            //     - wrapper 1.1
            //       - overlay 1.1
            //     - wrapper 1.2
            //       - overlay 1.2
            //   - wrapper 2
            //     - overlay 2
            props.children = <>{overlay.content}{nestedElements}</>;
            rootElements[--r] = React.createElement("div", props);
            nestedElements = [];
          }
          
          if (type.disablesMouse)
            disableMouse = true;
          
          if (type.disablesAria)
            disableAria = true;
          
          if (type.disablesTab)
            disableTab = true;
        }
        else 
          rootElements[i] = null;
      }
      
      if (nestedElements.length > 0 && nestedElements.length >= r && r < L)
        console.error("MAYDAY: OverlayService overwritten overlays detected.", L, r, nestedElements.length);
      
      for (let i = 0; i < nestedElements.length; i++)
        rootElements[i] = nestedElements[i];
      
      context.disableMouse = disableMouse;
      context.disableAria = disableAria;
      
      signal();
    };
    
    ref.current = {
      stack: [],
      elements: [],
      disableMouse: false,
      disableAria: false,
      
      createOverlay(type: OverlayType, content: JSX.Element, options: OverlayOptions = emptyObject): OverlayState {
        const def = OVERLAY_TYPES[type];
        const Overlay = def.component;
        const stack = this.stack;
        const index = stack.length; // pozycja tego okna na stosie
        
        const close = function() {
          if (!overlay.isOpen)
            return;
          
          //const isTop = index >= stack.length - 1;
          overlay.isOpen = false;
          stack[index] = undefined;
          
          updateStack();
          
          if (overlay.stolenFocus && (overlay.type.disablesMouse || /* wykliknięcie z dropdowna nie powinno przesuwać focusa na przycisk, chyba że w nic nie trafimy */ document.activeElement === document.body)) {
            //console.log("returning focus to", overlay.stolenFocus, "from", document.activeElement);
            overlay.stolenFocus.focus();
          }
          
          overlay.onClose && setTimeout(overlay.onClose, 0, ...arguments);
        };
        
        function onRefInternal(ref: HTMLElement | null) {
          if (ref === overlay.ref)
            return; 
          
          if (overlay.ref) {
            overlay.ref.removeEventListener("focusin", onFocusIn);
            overlay.ref.removeEventListener("focusout", onFocusOut);
          }
  
          overlay.ref = ref;
          
          if (ref) {
            ref.addEventListener("focusin", onFocusIn);
            
            if (ref.getAttribute("tabindex") /* nie każdy rodzaj nakładki zamyka się przy utracie focusa */) {
              ref.addEventListener("focusout", onFocusOut);
              //console.log("focus()")
              ref.focus();
            }
            // TODO: aktualnie używaną metodą na focusowanie czegoś w dialogu jest autofocus,
            //       być może chcielibyśmy coś bardziej automatycznego, ale trzeba by zapewnić, że:
            //       1) focusuje coś sensownego
            //       2) przegrywa z autofocusem
            // else if (def.disablesTab) {
            //   const candidates = tabbable(ref, emptyObject);
            //   if (candidates.length > 0)
            //     candidates[0].focus();
            // }
          }
          
          (overlay as any).onRef && (overlay as any).onRef(ref);
        }
        
        function onFocusIn(event: FocusEvent) {
          if (!overlay.isOpen) {
            // nie wiem czemu to się dzieje, np. po otwarciu dropdowna i zamknięciu przez Esc
            // stolenFocus jest zwracany, ale natychmiast wraca tutaj do zamkniętego overlaya...
            
            // @ts-ignore
            event.relatedTarget?.focus();
            event.stopPropagation();
          }
          else {
            //console.log("onFI", overlay.isOpen, event.relatedTarget, "->", event.target, overlay.ref && overlay.ref.contains(event.relatedTarget as any));
            if (overlay.ref && event.relatedTarget && !overlay.ref.contains(event.relatedTarget as any)) {
              //console.log("stolen focus from", event.relatedTarget);
              overlay.stolenFocus = event.relatedTarget as HTMLElement;
            }
          }
        }
        
        let focusTimer: ReturnType<typeof setInterval> | null = null;
        function onFocusOut(event: FocusEvent) {
          if (!focusTimer)
            focusTimer = setInterval(onFocusTimer, 200);
        }
        
        function onFocusTimer() {
          if (document.visibilityState !== "visible")
            return;
          
          focusTimer && clearInterval(focusTimer);
          focusTimer = null;
          
          const focused = document.activeElement;
          if (overlay.ref && (!focused || !overlay.ref.contains(focused)))
            overlay.close();
        }
        
        const overlay: OverlayStatePriv = {
          id: "ovl-" + (++ID),
          type: def,
          content: <></>,
          isOpen: !options.createClosed,
          onClose: undefined,
          doClose: undefined,
          close: function() {
            if (overlay.canClose) {
              const canClose = overlay.canClose.apply(this, arguments as any);
              if (canClose === false)
                return;
              else if (canClose !== true) {
                // @ts-ignore
                if (canClose.then) {
                  const args = Array.prototype.slice.call(arguments);
                  canClose.then(canClose => {
                    if (canClose === false) {
                      // pass
                    }
                    else {
                      if (canClose !== true)
                        console.warn(`overlay.canClose returned a non-boolean promise: ${typeof canClose}`, canClose);
                      
                      if (overlay.doClose)
                        return overlay.doClose.apply(this, args);
                      else
                        return close.apply(this, args as any);
                    }
                  }, err => {
                    // pass
                  });
                  return;
                }
                else {
                  console.warn(`overlay.canClose returned non-boolean, non-promise type: ${typeof canClose}`, canClose);
                }
              }
            }
            
            if (overlay.doClose)
              return overlay.doClose.apply(this, arguments as any);
            else
              return close.apply(this, arguments as any);
          },
          forceClose: close,
          update(content: JSX.Element): void {
            const uniq = (new Date).toISOString(); // zmuszamy ErrorBoundary to zresetowania
            this.content = <ActionService id={this.id} levels={actionLevels}>
              <ErrorBoundary fallback={fallback} resetKey={uniq}>
                <Overlay overlay={this}>{content}</Overlay>
              </ErrorBoundary>
            </ActionService>;
            if (!this.isOpen) {
              stack.push(this);
              this.isOpen = true;
            }
            updateStack();
          },
          ref: null,
          stolenFocus: null,
          onRef: options.onRef,
          onRefInternal: onRefInternal, //options.onRef ? onRefInternal : undefined,
          onFocusIn: onFocusIn,
          onFocusOut: onFocusOut,
        };
        
        // FIXME: kod renderujący stos nakładek wyżej nie wie, że wyświetla ModalOverlay zamiast oryginalnego typu w wypadku błędu
        const fallback = (message: string) => <ModalOverlay overlay={overlay}><CrashDialog message={message} overlay={overlay}/></ModalOverlay>;
        overlay.content = <ActionService id={overlay.id} levels={actionLevels}>
          <ErrorBoundary fallback={fallback}>
            <Overlay overlay={overlay}>{content}</Overlay>
          </ErrorBoundary>
        </ActionService>;
        
        if (overlay.isOpen) {
          stack.push(overlay);
          updateStack();
        }
        return overlay;
      },
      
      _onMount: () => {
        const stack = ref.current.stack;
        
        // Zamykanie ostatnio otwartej trwałej nakładki klawiszem Esc 
        // TODO: blokada zamknięcia (dla formularzy ze zmianami)
        
        let pressed = false;
        let timeout = null as unknown as Parameters<typeof clearTimeout>[0];
        
        function onTimeout() {
          pressed = false;
        }
        
        function onKeyDown(event: KeyboardEvent) {
          if (event.key === "Escape" && stack.length > 0 && !pressed) {
            // keydown śledzimy, żeby nie odpowiadać na keyup z innego okna
            pressed = true;
            clearTimeout(timeout);
            timeout = setTimeout(onTimeout, 1000);
          }
          else if (event.key === "Tab") {
            captureTab(event);
          }
        }
        
        function stop(e: Event) {
          e.preventDefault();
          e.stopPropagation();
        }
        
        function onKeyUp(event: KeyboardEvent) {
          if (event.isComposing || event.keyCode === 229) // z MDNu
            return;
          
          if (event.key === "Escape") {
            const $active = document.activeElement;
            if ($active) {
              const ss = ($active as any)["selectionStart"] as number | undefined | null;
              const se = ($active as any)["selectionEnd"] as number | undefined | null;
              if (typeof ss === "number" && typeof se === "number" && se !== ss) {
                ($active as any).setSelectionRange(0, 0);
                stop(event);
                return;
              }
            }
            
            const selection = window.getSelection();
            if (selection && !selection.isCollapsed) {
              selection.removeAllRanges();
              stop(event);
              return;
            }
            
            if (stack.length > 0) {
              stop(event);
              
              if (pressed)
                closeTopmostOverlay(stack);
            }
            
            pressed = false;
            clearTimeout(timeout);
          }
        }
        
        window.addEventListener("keyup", onKeyUp);
        window.addEventListener("keydown", onKeyDown);
        return () => {
          window.removeEventListener("keydown", onKeyDown);
          window.removeEventListener("keyup", onKeyUp);
        }
      }
    }
  }
  
  const context = ref.current;
  const ariaHidden = context.disableAria ? "true" : "false";
  const contentClass = context.disableMouse ? "overlayService__content inert" : "overlayService__content";
  
  useEffect(context._onMount, emptyArray);
  
  return (
    <OverlayServiceContext.Provider value={context}>
      <div className={contentClass} aria-hidden={ariaHidden}>
        {children}
      </div>
      <div className="overlayService__stack">
        {context.elements}
      </div>
    </OverlayServiceContext.Provider>
  );
};

const actionLevels = new Set(["dialog", "view", "pagination"]);

function closeTopmostOverlay(stack: (OverlayStatePriv | undefined)[]) {
  for (let i = stack.length - 1; i >= 0; i--) {
    const overlay = stack[i];
    if (overlay && !overlay.type.ignoresEscape) {
      overlay.type.swallowsEscape || overlay.close();
      break;
    }
  }
}

type OverlayComponent = React.FunctionComponent<{ overlay: OverlayStatePriv, children: any }>

/** Nakładka zasłaniająca i łąpiąca kliknięcia */
const ModalOverlay: OverlayComponent = ({ overlay, children }) => {
  return <div ref={overlay.onRefInternal} className={`overlayService__overlay overlayService__overlay--modal`} id={overlay.id}>
    <div className="overlayService__overlay__modal-bg" onClick={() => overlay.close()}/>
    {children}
  </div>;
};

/** Nakładka przezroczysta, nie łąpiąca zdarzeń */
const TransparentOverlay: OverlayComponent = ({ overlay, children }) => {
  return <div ref={overlay.onRefInternal} className={`overlayService__overlay overlayService__overlay--transparent`} id={overlay.id}>
    {children}
  </div>;
};

/** Nakładka przezroczysta dla myszy, ale łapiąca focus i zamykająca się gdy go straci */
const DropdownOverlay: OverlayComponent = ({ overlay, children }) => {
  return <div
    ref={overlay.onRefInternal}
    className={`overlayService__overlay overlayService__overlay--dropdown`}
    id={overlay.id}
    tabIndex={-1}>
    {children}
  </div>;
};

interface OverlayTypeDef {
  component: OverlayComponent
  /* Czy to podnakładka, w tym sensie, że współdzieli focusa i możliwość interakcji
   * z nadrzędną nakładką lub główną treścią.
   * Jeśli nested===true, to:
   * disables???===false,
   * ignoresEscape===true */
  nested: boolean
  disablesMouse: boolean
  disablesTab: boolean
  disablesAria: boolean
  ignoresEscape: boolean
  swallowsEscape: boolean
}

const OVERLAY_TYPES = {
  
  "modal": {
    nested: false, // TODO: lepszy model zagnieżdżania gdzie nawet modale są zagnieżdżane i mogą mieć onFocusOut?
    disablesMouse: true,
    disablesTab: true,
    disablesAria: true,
    ignoresEscape: false,
    swallowsEscape: false,
    component: ModalOverlay
  } as OverlayTypeDef,
  
  "transparent": {
    nested: true,
    disablesMouse: false,
    disablesTab: false,
    disablesAria: false,
    ignoresEscape: true,
    swallowsEscape: false,
    component: TransparentOverlay
  } as OverlayTypeDef,
  
  "completion": {
    nested: true,
    disablesMouse: false,
    disablesTab: false,
    disablesAria: false,
    ignoresEscape: false,
    swallowsEscape: false,
    component: TransparentOverlay
  } as OverlayTypeDef,

  "dropdown": {
    nested: false,
    disablesMouse: false,
    disablesTab: true,
    disablesAria: false, // ?
    ignoresEscape: false,
    swallowsEscape: false,
    component: DropdownOverlay
  } as OverlayTypeDef,
};

export type OverlayType = keyof typeof OVERLAY_TYPES;

const CRASH_DIALOG_TITLE = translate("error.dialogTitle");

/** Fallback wyświetlany kiedy kod okna dialogowego się wywali */
function CrashDialog(props: { message: string, overlay: OverlayState }) {
  const errorBoundary = ErrorBoundary.useApi();
  
  const buttons = <>
    <Button preset="close" onClick={() => props.overlay.close()}/>
    <Button preset="tryAgain" onClick={errorBoundary.clearError}/>
  </>;
  
  return <ErrorDialog
    title={CRASH_DIALOG_TITLE}
    buttons={buttons}
    message={props.message}
    dialog={props.overlay as any /* FIXME: inny typ obiektu przekazujemy, w tym wypadku działa, no ale... */}
  />
}

export default OverlayService;
