import { translate } from "utils/language";
import bootstrap, { BootstrapCatalogue } from "bootstrap";

import { folksCommands, sowaCommands, tekaCommands, kasaCommands } from "capi/common";
import { emptyArray } from "../utils/constants";
import { CapiClient, CapiCommand } from "./client";
import { ICatalogue } from "../types/params";

type StrixModuleType = "sowa" | "folks" | "teka" | "kasa"

export interface StrixModule extends ICatalogue /* burdel z tymi typami... >_> */ {
  readonly description: React.ReactNode;
  // alias z innego typu, który najwyraźniej jest mieszany z tym
  readonly text: React.ReactNode;
  readonly type: StrixModuleType;
  readonly commands: StrixModuleCommands
  // alias bo w kodzie róznie używali...
  readonly module: StrixModuleCommands
  readonly paramsGet: string;
  readonly url: string;
  readonly id: string;
  readonly modId: string;
  readonly path: readonly string[];
  readonly client: CapiClient;
}

export interface StrixModuleCommands {
  ParamsDef: new(...args: any[]) => CapiCommand
  ParamsGet: new(...args: any[]) => CapiCommand
  ParamsSet: new(...args: any[]) => CapiCommand
}

type SowaCatalogue = BootstrapCatalogue;

// Ten hook jest w zasadzie niepotrzebny, bo to stałe informacje i bez przeładowania strony
// się nie zmieniają, ale zostawiamy, bo w przyszłości po integracji z mOPAC bootstrap może
// przestać być stałą

// mały hak by kodu nie zmieniać (był useRef)
let modules: { current: StrixModule[] } = { current: emptyArray as any };

/**
 * Zwraca listę modułów podanych w bootstrap, w tym też wszystkich katalogów Sowa.
 */
export const useStrixModules = (): readonly StrixModule[] => {
  //const modules = useRef<StrixModule[]>(emptyArray as any);

  if (modules.current === emptyArray as any) {
    modules.current = [];

    // Katalogi modułu Sowa
    modules.current.push(
      ...bootstrap.sowa.catalogues.map((catalogue: SowaCatalogue) => Object.freeze({
        description: catalogue.name,
        text: catalogue.name,
        type: "sowa" as StrixModuleType,
        commands: sowaCommands,
        module: sowaCommands,
        paramsGet: "sowaParamsGet",
        url: catalogue.url,
        id: catalogue.cat_id,
        modId: `sowa-${catalogue.cat_id}`,
        path: Object.freeze(["sowa", "cataloguesById", catalogue.cat_id]),
        client: catalogue.client,
      }))
    );

    // Moduł Folks
    modules.current.push(Object.freeze({
      description: translate("app.folks"),
      text: translate("app.folks"),
      type: "folks",
      commands: folksCommands,
      module: folksCommands,
      paramsGet: "folksParamsGet",
      url: bootstrap.folks.url,
      id: "folks",
      modId: "folks",
      path: Object.freeze(["folks"]),
      client: bootstrap.folks.client,
    }));

    // Moduł Teka
    if (bootstrap.teka && bootstrap.teka.url)
      modules.current.push(Object.freeze({
        description: translate("app.teka"),
        text: translate("app.teka"),
        type: "teka",
        commands: tekaCommands,
        module: tekaCommands,
        paramsGet: "tekaParamsGet",
        url: bootstrap.teka.url,
        id: "teka",
        modId: "teka",
        path: Object.freeze(["teka"]),
        client: bootstrap.teka.client,
      }));

    // Moduł Kasa
    if (bootstrap.kasa && bootstrap.kasa.url)
      modules.current.push(Object.freeze({
        description: "Kasa",
        text: "Kasa",
        type: "kasa",
        commands: kasaCommands,
        module: kasaCommands,
        paramsGet: "kasaParamsGet",
        url: bootstrap.kasa.url,
        id: "kasa",
        modId: "kasa",
        path: Object.freeze(["kasa"]),
        client: bootstrap.kasa.client,
      }));
  }

  return modules.current as StrixModule[];
};

let sowaModules: undefined | readonly StrixModule[];

export function useSowaCatalogues(): readonly StrixModule[] {
  const modules = useStrixModules();
  
  if (!sowaModules)
    sowaModules = modules.filter(mod => mod.type === "sowa");
  
  return sowaModules;
}

export function useStrixModule(id: string | number): StrixModule | undefined {
  const modules = useStrixModules();
  if (typeof id === "number")
    return modules[id];
  else
    return modules.find(mod => mod.modId === id);
}

export function useSowaCatalogue(id: string): StrixModule | undefined {
  const catalogues = useSowaCatalogues();
  return catalogues.find(mod => mod.id === id);
}
