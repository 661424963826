import React, { createElement, useEffect } from "react";
import { useSelector } from "react-redux";
import isEqualReact from "react-fast-compare";

import store from "store";

import { UPDATE_SETTINGS } from "sagas/types";
import bootstrap from "../bootstrap";
import { CapiCommand } from "../capi/client";
import { emptyArray } from "./constants";
import { useSignal } from "./hooks/other";
import { registerUpdateMarker } from "./hooks/markers";
import { scheduleReactUpdate } from "./hooks/batch";
import { Dictionary } from "./types";
import { reportError } from "./errorReporting";

// @ts-ignore
import flag_pl from "images/poland.png";
// @ts-ignore
import flag_de from "images/germany.png";
// @ts-ignore
import flag_us from "images/united-states.png";
import { copyTextToTitle } from "./dom";

const DEV = process.env.NODE_ENV === "development";

const translations = (window as any).translations as {
  langs: string[],
  [key: string]: string[]
};

const languageOrder = {} as { [lang: string]: number };
translations.langs.forEach((lang, i) => languageOrder[lang] = i);
const pl_PL = languageOrder.pl_PL;
const en_US = languageOrder.en_US;

let serverTranslations = null as {
  langs: string[],
  [key: string]: string[]
} | null;

let serverLanguageOrder = {} as { [lang: string]: number };

export interface LanguageInfo {
  id: string,
  serverId: string,
  short: string,
  name: string,
  flagUrl: string,
  _order: number,
  
  translate(id: string): string
  numberForm(count: number): number
}

function makeLang(id: string, serverId: string, short: string, name: string, flagUrl: string, numberForm: (count: number) => number): Readonly<LanguageInfo> {
  return Object.freeze({
    id, serverId, short, name, flagUrl, numberForm, _order: languageOrder[id]!,
    translate: (stringId: string) => translate(stringId, id)
  });
}

/**
 * @namespace {array}
 * @description List of objects defining languages.
 */
export const LANGUAGE_LIST = Object.freeze([
  makeLang("pl_PL", "pl_PL", "pl", "Polski", flag_pl, pl_numberForm),
  makeLang("en_US", "en_GB", "en", "English", flag_us, en_numberForm),
  makeLang("de_DE", "de_DE", "de", "Deutsch", flag_de, de_numberForm)
]);

/** language id -> language info */
export const LANGUAGES_BY_ID = (function() {
  let result = {} as Dictionary<Readonly<LanguageInfo>>;
  for (let lang of LANGUAGE_LIST) {
    result[lang.id] = lang;
  }
  return Object.freeze(result);
})();

/** server language id -> language info */
export const LANGUAGES_BY_SERVER_ID = (function() {
  let result = {} as Dictionary<Readonly<LanguageInfo>>;
  for (let lang of LANGUAGE_LIST) {
    result[lang.serverId] = lang;
  }
  return Object.freeze(result);
})();

/** translation id -> language id -> string */
export const TRANSLATIONS = (function() {
  const result: Dictionary<Dictionary<string>> = {};
O:for (const [k, v] of Object.entries(translations)) {
    const x: Dictionary<string> = {};
    for (const lang of LANGUAGE_LIST) {
      const t = v[lang._order];
      if (t) {
        if (typeof t === "string")
          x[lang.id] = t;
        else
          continue O; // tłumaczeń z odmianą nie wspieramy w edytorze tłumaczeń
      }
    }
    result[k] = Object.freeze(x);
  }
  return Object.freeze(result);
})();

const PL = LANGUAGES_BY_ID.pl_PL;
let ACTLANG = ""; // Taki mały hak, zwracamy w tym rzeczywisty język użyty w translate

/**
 * @name translate
 * @description Returns the proper translation for the active language.
 * @param {object} key Translation code.
 * @param {string} language
 * @returns {string}
 * @example translate("unique.one", "pl_PL"); => "jeden"
 * @deprecated Wycofujemy powoli użycie na korzyść `tran` i `Tran`, przypadki problematyczne zgłaszać Marcinowi
 */
export function translate(key: string, language: string = getLanguage()): string {
  if (typeof key !== "string") throw Error("The key property is not a string.");
  if (typeof language !== "string")
    throw Error("The language property is not a string.");
  
  if (serverTranslations) {
    const sLangIndex = serverLanguageOrder[language];
    const languages = serverTranslations[key];
    if (languages && languages[sLangIndex]) {
      ACTLANG = language;
      return languages[sLangIndex];
    }
  }
  
  const langIndex = languageOrder[language];
  
  let languages = translations[key];
  
  if (languages && languages[langIndex]) {
    ACTLANG = language;
    return languages[langIndex];
  }
  
  if (DEV) {
    ACTLANG = "";
    return `${key}@${language}`;
  }
  
  if (languages) {
    if (languages[en_US]) {
      ACTLANG = "en_US";
      return languages[en_US];
    }
    
    if (languages[pl_PL]) {
      ACTLANG = "pl_PL";
      return languages[pl_PL];
    }
  }
  
  ACTLANG = "";
  return translations["unique.noTranslation"][langIndex];
}

/**
 * @name getLanguage
 * @description Returns active language.
 * @returns {string}
 * @deprecated Wycofujemy powoli użycie na korzyść `useLanguage`.
 */
export function getLanguage(): string {
  return (
    store.getState().utils.appSettings.language ||
    localStorage.getItem("language") ||
    PL.id
  );
}

/**
 * @name setLanguage
 * @description Changes the active language.
 * @param {string} language
 */
export function setLanguage(language: string): void {
  store.dispatch({ type: UPDATE_SETTINGS, payload: { language } });
  localStorage.setItem("language", language);
  setTimeout(() => window.location.reload(), 500); // ! Refreshes the application so all translations can change
}

//
//
//

// dodając propsy nie zapomnij o funkcji comparePropsForTran poniżej
export type TranProps = {
  id: string // identyfikator tłumaczonego tekstu
  search?: string | RegExp // co podmienić?
  replace?: React.ReactNode | ((...groups: any[]) => React.ReactNode) // czym?
  case?: "lower" | "upper" | "title" | "original"
  form?: number // wybór konkretnej formy (dla tekstów zdefiniowanych jako tablice)
  count?: number // wybór odmiany na podstawie podanej liczby
  fallback?: React.ReactNode // tekst gdy tłumaczenia nie znaleziono
  className?: string
  children?: React.ReactNode // to samo co replace
}

/** 
 * Komponent do renderowanie przetłumaczonego tekstu
 * 
 */
export const Tran = React.memo(function Tran(props: TranProps): JSX.Element {
  const lang = useLanguage();
  
  try {
    // ta zmienna może być stringiem albo tablicą stringów i elementów
    let translated: any = lang.translate(props.id);
    
    if (!ACTLANG)
      return typeof props.fallback === "undefined" ? translated : props.fallback;
    
    if (typeof translated !== "string") {
      let form: number;
      if (typeof props.count === "number")
        form = LANGUAGES_BY_ID[ACTLANG].numberForm(props.count);
      else
        form = Math.min(props.form || 0, translated.length - 1);
      translated = translated[form];
    }
    
    switch (props.case) {
      case "lower":
        translated = translated.toLowerCase();
        break;
      case "upper":
        translated = translated.toUpperCase();
        break;
      case "title":
        translated = translated.charAt(0).toUpperCase() + translated.substring(1);
        break;
      default:
    }
    
    if (props.search) {
      const search = props.search;
      const replace = props.replace || props.children || "";
    
      if (typeof replace === "string")
        translated = translated.replace(search, replace);
    
      else {
        // wspieramy podmianę tekstu na element, więc jest to trochę skomplikowane
      
        const parts = translated.split(search);
      
        if (parts.length > 1) {
          // liczymy liczbę par nawiasów w regexie metodą z SO,
          // bo wynik splita to będzie [tekst, zawartość nawiasu 1, nawiasu 2, ..., tekst, ...]
          const numGroups = search instanceof RegExp
            ? (new RegExp(search.source + "|")).exec("")!.length - 1
            : 0;
        
          const isFn = replace instanceof Function;
          const isObj = replace && typeof replace === "object";
          let key = 0;
          
          translated = [parts[0]];
          for (let i = 1; i < parts.length;) {
            if (isFn)
              translated.push(<span key={key++}>{(replace as Function)(...parts.slice(i, i + numGroups))}</span>);
            else if (isObj)
              translated.push(<span key={key++}>{replace}</span>);
            else
              translated.push(replace);
            
            i += numGroups;
            translated.push(parts[i++]);
          }
        }
      }
    }
    
    return <span data-trid={props.id} className={props.className} onMouseEnter={copyTextToTitle} children={translated} />;
  }
  catch (e) {
    if (DEV) throw e;
    void reportError(e);
    return TR_FAIL;
  }
}, comparePropsForTran);

const TR_FAIL = <i>[Translation failure]</i>;

function comparePropsForTran(oldProps: TranProps, newProps: TranProps): boolean {
  return oldProps.id === newProps.id
    && compareRegexes(oldProps.search, newProps.search)
    && isEqualReact(oldProps.replace || oldProps.children, newProps.replace || newProps.children);
}

function compareRegexes(a: undefined | string | RegExp, b: undefined | string | RegExp): boolean {
  return a === b || (
    a instanceof RegExp
      && b instanceof RegExp
      && a.source === b.source
      && a.flags === b.flags
  );
}

const TR_CACHE = new Map<string, JSX.Element>();

/** 
 * Funkcja jak translate(), ale które zwraca element, a nie stringa
 * 
 */
export function tran(id: string) {
  let cached = TR_CACHE.get(id);
  if (!cached) {
    cached = Object.freeze(<Tran id={id}/>);
    TR_CACHE.set(id, cached);
  }
  return cached;
}

/** @deprecated nazwa zmieniona na życzenie, ale nie chcemy psuć innych branchy */  
export const tr = tran;

/** @deprecated nazwa zmieniona na życzenie, ale nie chcemy psuć innych branchy */
export const Tr = Tran;

export function useLanguage(): Readonly<LanguageInfo> {
  const storeLang = useSelector(languageSelector);
  const signal = useSignal();
  useEffect(() => {
    signals.add(signal);
    return () => { signals.delete(signal); };
  }, emptyArray);
  return LANGUAGES_BY_ID[storeLang || localStorage.getItem("language")] || PL;
}

const languageSelector = (state: any) => state.utils.appSettings.language;

const signals = new Set<() => void>()
export function wakeUpLanguageConsumers() {
  for (const signal of signals)
    scheduleReactUpdate(signal);
}

function pl_numberForm(count: number): number {
  if (count === 1)
    return 0;
  const d = count % 10;
  const s = count % 100;
  if (d >= 2 && d < 5 && !(s >= 12 && s < 15))
    return 1;
  else
    return 2;
}

function en_numberForm(count: number): number {
  return count === 1 ? 0 : 1;
}

function de_numberForm(count: number): number {
  return count === 1 ? 0 : 1;
}

////////////////////////////////////////////////////////////////////////////////

let downloading = false;
async function downloadServerTranslations() {
  if (downloading) return;
  downloading = true;
  try {
    const client = bootstrap.folks.client.withAuth(110, bootstrap.domain);
    const [command] = await client.execute(new ClientTranslationsCommand());
    if (command.status === 200) {
      serverTranslations = command.result.data as any;
      serverTranslations!.langs?.forEach((serverLangId, i) => {
        const lang = LANGUAGES_BY_SERVER_ID[serverLangId];
        if (lang) serverLanguageOrder[lang.id] = i;
      });
      wakeUpLanguageConsumers();
    }
    else {
      console.error("Nie udało się pobrać tłumaczeń z serwera", command.status, command.result.reason, command.result.message);
    }
  }
  finally {
    downloading = false;
  }
}

registerUpdateMarker(`param:tr.cli.#:#@#@${bootstrap.folks.url}`, downloadServerTranslations);

class ClientTranslationsCommand extends CapiCommand {
  constructor() {
    super(["folksClientTranslations", ["ws", "all"]]);
  }
}

if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "development")
  void downloadServerTranslations();

(window as any).__ws_translate = translate;
