import { Blankholder, Button, FlexWrapper } from "components/shared";
import { useDialog } from "components/shared/Dialog";
import { LoginDialog } from "components/special-pages/Login/LoginDialog";
import { useHistory } from "react-router";
import { tran } from "utils/language";
import React from "react";

export function LoginRequired() {
  const history = useHistory();

  const loginDialog = useDialog(LoginDialog);

  return (
    <FlexWrapper alignItems="center" justifyContent="center" width="100%" height="100%">
      <Blankholder
        icon="sign-in-alt"
        title={tran("shell.auth.header")}
        description={tran("shell.auth.info")}
        animate={false}
        button={
          <Button.Group align="center">
            <Button onClick={() => history.push("/")} preset="back" />

            <Button onClick={() => loginDialog.open()} icon="sign-in-alt">
              {tran("login.btn")}
            </Button>
          </Button.Group>
        }
      />
    </FlexWrapper>
  );
}

export function AccessDenied() {
  const history = useHistory();

  return (
    <FlexWrapper alignItems="center" justifyContent="center" width="100%" height="100%">
      <Blankholder
        icon="exclamation-triangle"
        title={tran("shell.auth.header")}
        description={tran("shell.auth.denied")}
        animate={false}
        button={
          <Button.Group align="center">
            <Button onClick={() => history.push("/")} preset="back" />
          </Button.Group>
        }
      />
    </FlexWrapper>
  );
}
