import { Button, Icon } from "components/shared";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useSmallScreen } from "../../../utils/hooks";
import { tran } from "../../../utils/language";
import UserLabel from "../../common/UserLabel";
import { useDialog } from "../../shared/Dialog";
import { useShellContext } from "../../shell/shellContext";
import { LoginDialog } from "../../special-pages/Login/LoginDialog";
import Navigation from "./Navigation";
import { TOP_LOGO, TOP_TITLE } from "./PageTitle";
import { SessionClock, ToastSessionClock } from "./SessionClock";

export const TopBar = React.memo(() => {
  const topBar = <div className="top-bar">
    <TopHamburger />
    {TOP_LOGO}
    {TOP_TITLE}
    <TopLogin />
  </div>;
  
  // FIXME: chowamy pasek okruszków na małych ekranach, bo okruszki trzeba przepisać porządnie
  if (useSmallScreen())
    return <>
      {topBar}
      <ToastSessionClock />
    </>
  else
    return <>
      {topBar}
      <TopBarLower/>
    </>
});

export const TopHamburger = React.memo(() => {
  const { isSidebarOpen, toggleSidebarOpen } = useShellContext();
  return <Button icon={isSidebarOpen ? hamburgerActive : hamburger} onClick={toggleSidebarOpen} className="main-hamburger" />;
});

export const TopLogin = React.memo(() => {
  const currentUser = useSelector(currentUserSelector);
  const loginDialog = useDialog(LoginDialog);
  if (currentUser)
    return <Link to="/myProfile" className="top-bar__user hide-on-small-only">
      <UserLabel user={currentUser} inline/>
    </Link>;
  else
    return <button className="top-bar__user hide-on-small-only" onClick={loginDialog.open}>
      {tran("login.heading")}
    </button>;
});

const hamburger = <Icon name="bars" className="fa-"/>;
const hamburgerActive = <Icon name="bars" className="fa-rotate-90" />;

/**
 * Dolna część paska nawigacji.
 */
const TopBarLower = React.memo(() => {
  return (
    <div className="top-bar__lower">
      <Navigation />
      <div className="top-bar__items-box">
        <SessionClock />
      </div>
    </div>
  )
});

//
// const TopBarMainControls = React.memo(() => {
//   return <>
//     <ContrastSwitch />
//     <LanguageMenu />
//     <Notifications />
//   </>
// })
//
// function AdminSearchConditional() {
//   const location = useLocation();
//   const isUserLoggedIn = useSelector(isUserLoggedInSelector);
//  
//   if (location.pathname.startsWith("/admin") && isUserLoggedIn)
//     return <AdminSearchLoader/>
//   else
//     return null;
// }
//
// const AdminSearchLoader = lazyComponent(() => import(/* webpackChunkName: "adm" */ "components/pages/Admin/AdminSearch"));
//
const currentUserSelector = (state: any) => state.session.isUserLoggedIn ? state.currentUser.folks : undefined;
