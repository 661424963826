import React, { Component } from "react";
import { connect } from "react-redux";

import { UserPageLoader } from "components/pages/Admin/Users/SingleUser";

import store from "store";

class MyProfile extends Component {
  constructor(props) {
    super(props);

    this.currentUserId = store.getState().currentUser.folks.user_id;
  }

  render() {
    if (!this.props.isUserLoggedIn) {
      this.props.history.push("/");
      return <React.Fragment />;
    }

    return (
      <div style={{ paddingBottom: "5%" }}>
        <UserPageLoader
          myProfile
        />
      </div>
    );
  }
}

const READ = state => ({
  isUserLoggedIn: state.session.isUserLoggedIn
});

export default connect(READ)(MyProfile);
// export default withRouter(connect(READ)(MyProfile));
