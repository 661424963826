import { Dictionary, Predicate, Transform, TransformWithIndex } from "../types";
import { alwaysTrue, identity } from "../constants";

export type NestedListsControlField = [string, string];

export type NestedListsDataField = [string, string, string, [string, string][]];

export type NestedListsRecord = (NestedListsControlField | NestedListsDataField)[];

export type SowaField = { idx?: string, value: string };

export type SowaRecord = { [tag: string]: (SowaField | string)[] };

/** API modułu */
export class Marq {
  static fromNestedLists(data: NestedListsRecord) {
    const contents: Dictionary<(MarcSF | MarcF)[]> = {};

    data.forEach(field => {
      let tag = field[0];

      contents[tag] = contents[tag] || [];

      if (field.length === 4) {
        contents[tag].push(new MarcF(tag,
                                     (field[1] || " ") + (field[2] || " "),
                                     new MarcSFL(field[3].map(sf => {
                                       return new MarcSF(tag, sf[0], sf[1]);
                                     }))));
      }
      else
        contents[tag].push(new MarcSF(tag, "", field[1]));
    });
    
    return new MarcFL(contents);
  }

  /** Parsowanie MARC21 w formacie JSONowym zwracanym z SAPI */
  static fromSowaJson(data: SowaRecord) {
    const contents: Dictionary<(MarcSF | MarcF)[]> = {};

    Object.keys(data).forEach(tag => {
      let v = data[tag];
      if (!Array.isArray(v)) v = [v];
      contents[tag] = v.map(field => {
        if (typeof field === "object") {
          if (field.idx !== undefined)
            return new MarcF(
              tag,
              field.idx,
              parseSubfields(tag, field.value)
            );
          else return new MarcSF(tag, "", field.value);
        } else return new MarcSF(tag, "", field);
      });
    });

    return new MarcFL(contents);
  }
}

/** Pole kontrolne lub podpole Marc21 */
export class MarcSF {
  readonly tag: string;
  readonly code: string;
  readonly value: string;
  
  /** Konstruktor prywatny */
  constructor(tag: string, code: string, value: string) {
    /** @member {string} - Trzyznakowy tag pola */
    this.tag = tag;
    /** @member {string} - Jednoznakowy kod podpola */
    this.code = code;
    /** @member {string} - Wartość (pod)pola */
    this.value = value;
  }

  /** Etykieta (pod)pola łącząca tag i code */
  get label(): string {
    if (this.code) return `${this.tag}^${this.code}`;
    else return this.tag;
  }

  /** Czy obiekt reprezentuje poprawne (pod)pole, czy może pusty wynik */
  get valid(): boolean {
    return typeof this.tag === "string" && this.tag !== "";
  }

  /** Dla kompatybilności z polami danych */
  subfields(): MarcSFL {
    return new MarcSFL([this]);
  }

  /** Dla kompatybilności z polami danych */
  get ind(): string {
    return "";
  }

  /** Zwrócenie kopii (pod)pola z usuniętymi znakami z początku i końca
   *  @param {string} of - Znaki do usunięcia (domyślnie usuwa znaki białe) */
  strip(of?: string): MarcSF {
    if (typeof of === "undefined") {
      return new MarcSF(this.tag, this.code, this.value.trim());
    } else {
      const p = `[${of.replace("^", "\\^").replace("]", "\\]").replace("\\", "\\\\").replace("-", "\\-")}]+`;
      const r = new RegExp(`^${p}|${p}$`, "g");
      return new MarcSF(this.tag, this.code, this.value.replace(r, ""));
    }
  }

  copy(tag?: string, code?: string, value?: string): MarcSF {
    return new MarcSF(tag || this.tag, code || this.code, value || this.value);
  }
}

/** Pole danych Marc21 */
export class MarcF {
  readonly tag: string;
  readonly ind: string;
  readonly _sfs: MarcSFL;
  
  constructor(tag: string, indicators: string, subfields: MarcSFL) {
    /** @member {string} - Trzyznakowy tag pola */
    this.tag = tag;
    /** @member {string} - Wartości pierwszego i drugiego wskaźnika jako dwuznakowy string */
    this.ind = indicators;
    /** @member {MarcSFL} - Lista podpól (prywatna), patrz `subfields()` */
    this._sfs = subfields;
  }

  /** Etykieta pola - tag */
  get label(): string {
    return this.tag;
  }

  /** Dla kompatybilności z polami kontrolnymi */
  get code(): string {
    return "";
  }

  /** Dla kompatybilności z polami kontrolnymi */
  get value(): string {
    return this._sfs.renderText();
  }

  /** Dostęp do listy podpól */
  subfields(codes?: string) {
    if (typeof codes === "undefined") return this._sfs;
    else return this._sfs.filter(codes);
  }

  copy(tag?: string, indicators?: string, subfields?: MarcSFL) {
    return new MarcF(
      tag || this.tag,
      indicators || this.ind,
      subfields || this._sfs.map(identity)
    );
  }
}

/** Lista podpól Marc21 */
export class MarcSFL {
  readonly _contents: readonly MarcSF[];
  
  constructor(contents: readonly MarcSF[]) {
    this._contents = contents;
  }

  /** Wyciągnięcie pierwszego podpola
   *  @param {string} codes - Opcjonalnie filtrując do podanych kodów */
  first(codes?: string): MarcSF {
    const c = this._contents;

    if (typeof codes === "undefined") {
      if (c.length > 0) return c[0];
    } else {
      for (let i = 0, L = c.length; i < L; i++) {
        const sf = c[i];
        if (sf.code.length > 0 && codes.indexOf(sf.code) >= 0) return sf;
      }
    }

    return emptySF;
  }

  /** Wyciągnięcie ostatniego podpola
   *  @param {string} codes - Opcjonalnie filtrując do podanych kodów */
  last(codes?: string): MarcSF {
    const c = this._contents;
    const l = c.length - 1;

    if (typeof codes === "undefined") {
      if (l >= 0) return c[l];
    } else {
      for (let i = l; i >= 0; i--) {
        const sf = c[i];
        if (sf.code.length > 0 && codes.indexOf(sf.code) >= 0) return sf;
      }
    }

    return emptySF;
  }

  get length(): number {
    return this._contents.length;
  }

  get nonEmpty(): boolean {
    return this._contents.length > 0;
  }

  /** Przetworzenie listy podpól funkcją i zwrócenie nowej */
  map(f: TransformWithIndex<MarcSF>): MarcSFL {
    return new MarcSFL(this._contents.map(f));
  }

  /** Przetworzenie listy podpól funkcją i zwrócenie tablicy wyników */
  mapToArray<T>(f: TransformWithIndex<MarcSF, T>): T[] {
    return this._contents.map(f);
  }

  /** Wywołanie funkcji dla każdego podpola */
  forEach(f: (subfield: MarcSF, i: number) => void): void {
    this._contents.forEach(f);
  }

  /** Zwrócenie listy przefiltrowanej do podpól o podanych kodach */
  filter(codes: string | string[] | Predicate<MarcSF>): MarcSFL {
    let f: Predicate<MarcSF>;
    if (typeof codes === "function") f = codes;
    else f = sf => sf.code.length > 0 && codes.indexOf(sf.code) >= 0;
    
    const n = this._contents.filter(f);
    if (n.length === 0) return emptySFL;
    
    return new MarcSFL(n);
  }

  /** Zwrócenie listy przefiltrowanej do podpól innych niż o podanych kodach */
  except(codes: string | string[] | Predicate<MarcSF>): MarcSFL {
    let f: Predicate<MarcSF>;
    if (typeof codes === "function") f = sf => !codes(sf);
    else f = sf => sf.code.length > 0 && codes.indexOf(sf.code) < 0;
    
    const n = this._contents.filter(f);
    if (n.length === 0) return emptySFL;
    
    return new MarcSFL(n);
  }
  
  takeUntil(codes: string | string[] | Predicate<MarcSF>, inclusive?: boolean): MarcSFL {
    let f: Predicate<MarcSF>;
    if (typeof codes === "function") f = codes;
    else f = sf => sf.code.length > 0 && codes.indexOf(sf.code) >= 0;
    
    const c = this._contents;
    for (let i = 0, L = c.length; i < L; i++) {
      if (f(c[i])) {
        if (inclusive) {
          if (i == L - 1)
            return this;
          return new MarcSFL(c.slice(0, i + 1));
        }
        else {
          if (i == 0)
            return emptySFL;
          return new MarcSFL(c.slice(0, i));
        }
      }
    }
    
    return this;
  }

  /** Zwrócenie listy przefiltrowanej do podpól o niepustych wartościach */
  filterNonEmpty(): MarcSFL {
    const n = this._contents.filter(sf => sf.value.length > 0);
    if (n.length === 0) return emptySFL;

    return new MarcSFL(n);
  }

  /** Zwrócenie sklejenia wartości podpól w tej liście
   *  @param {string} sep - Znak separatator */
  join(sep: string = ""): string {
    return this._contents.map(getVal).join(sep);
  }
  
  trimJoin(sep: string = ""): string {
    return this._contents.map(getValAndTrim).join(sep);
  }

  /** Zwrócenie sklejenia wartości podpól w tej liście spacjami, uważając by nie dodać dwóch sąsiednich spacji */
  smartJoin(): string {
    const parts = [];
    let spaceNeeded = false;
    const c = this._contents;
    for (let i = 0, L = c.length; i < L; i++) {
      if (spaceNeeded) parts.push(" ");

      const part = c[i].value;
      parts.push(part);

      spaceNeeded = !part.endsWith(" ") || (part === "" && !spaceNeeded);
    }
    return parts.join("");
  }

  /** Wyrenderowanie listy podpól w formacie SOWY */
  renderText(delim: string = "^", delimEscaped?: string): string {
    if (!delimEscaped)
      return this._contents.map(sf => `${delim}${sf.code}${sf.value}`).join("");
    else
      return this._contents
        .map(sf => `${delim}${sf.code}${sf.value.replace(delim, delimEscaped)}`)
        .join("");
  }

  /** Zwrócenie listy podpól, po wywołaniu na każdym `.strip(of)` */
  stripped(empty: boolean = false, of?: string): MarcSFL {
    let c = this._contents.map(sf => sf.strip(of));
    if (!empty) c = c.filter(sf => sf.value.length > 0);
    return new MarcSFL(c);
  }
}

/** Lista pól Marc21 / model rekordu */
export class MarcFL {
  readonly order: string[];
  readonly _contents: { [tag: string]: readonly (MarcF | MarcSF)[] };
  
  constructor(contents: { [tag: string]: readonly (MarcF | MarcSF)[] }) {
    this._contents = contents;
    this.order = Object.keys(contents).sort();
  }

  /** Wyciągnięcie pierwszego pola
   *  @param {string} tags - Opcjonalnie filtrując do podanych tagów */
  first(tags?: string | string[]): MarcF | MarcSF {
    const c = this._contents;

    if (typeof tags === "undefined") {
      const ks = Object.keys(c);
      for (let i = 0, L = ks.length; i < L; i++) {
        if (c[i].length > 0) return c[i][0];
      }
      return emptySF;
    } else if (typeof tags === "string") tags = tags.split(/\s*,\s*/);

    for (let i = 0, L = tags.length; i < L; i++) {
      const tag = tags[i];
      const fields = c[tag];
      if (fields && fields.length > 0) return fields[0];
    }

    return emptySF;
  }

  get length(): number {
    const c = this._contents;
    return Object.keys(c).reduce((s, k) => s + c[k].length, 0);
  }

  get nonEmpty(): boolean {
    return this.length > 0;
  }
  
  /** Przetworzenie listy pól funkcją i zwrócenie nowej */
  map(f: TransformWithIndex<MarcF | MarcSF>): MarcFL {
    const c = this._contents;
    const results: typeof c = {};
    let idx = 0;
    
    for (let tag in this.order) {
      const a = c[tag];
      const r = [];
      for (let i in a) {
        r.push(f(a[i], idx++));
      }
      results[tag] = r;
    }

    return new MarcFL(results);
  }

  /** Przetworzenie listy pól funkcją i zwrócenie tablicy wyników */
  mapToArray<T>(f: TransformWithIndex<MarcF | MarcSF, T>): T[] {
    const c = this._contents;
    const tags = this.order;
    const results: T[] = [];
  
    let idx = 0;
    
    for (let it in tags) {
      const tag = tags[it];
      const a = c[tag];
      for (let i in a) {
        results.push(f(a[i], idx++));
      }
    }
    
    return results;
  }

  /** Wywołanie funkcji dla każdego pola */
  forEach(f: (field: MarcF | MarcSF) => void): void {
    const c = this._contents;
  
    for (let tag of this.order) {
      c[tag].forEach(f);
    }
  }

  /** Zwrócenie listy przefiltrowanej do pól o podanych tagach */
  filter(tags: string | string[]): MarcFL {
    if (typeof tags === "string")
      tags = tags.split(/\s*,\s*/).sort();
    else
      tags = [...tags].sort();
    
    const c = this._contents;
    
    if (tags.length === 1) {
      const tag = tags[0];
      if (c[tag])
        return new MarcFL({ [tag]: c[tag] });
      else
        return emptyFL;
    }
    
    return new MarcFL(
      tags.reduce((result, tag) => {
        if (c[tag]) result[tag] = c[tag];
        return result;
      }, {} as typeof c)
    );
  }

  /** Zwrócenie listy przefiltrowanej do pól innych niż o podanych tagach */
  except(tags: string | string[]): MarcFL {
    if (typeof tags === "string") tags = tags.split(/\s*,\s*/);

    const t: { [tag: string]: boolean } = {};

    for (let i = 0, L = tags.length; i < L; i++) t[tags[i]] = true;

    const c = this._contents;

    return new MarcFL(
      Object.keys(c).reduce((result, k) => {
        if (!t[k]) result[k] = c[k];
        return result;
      }, {} as typeof c)
    );
  }

  /** Zwrócenie listy podpól wszystkich pól w tym rekordzie */
  subfields(codes?: string | string[]): MarcSFL {
    const c = this._contents;
    const f = codes
      ? ((sf: MarcSF) => sf.code.length > 0 && codes.indexOf(sf.code) >= 0)
      : alwaysTrue;

    return new MarcSFL(
      Object.keys(c).reduce((result, k) => {
        const subresult = c[k].reduce((subresult, field) => subresult.concat(field.subfields()._contents.filter(f)), [] as MarcSF[]);
        return result.concat(subresult);
      }, [] as MarcSF[])
    );
  }
  /** Zwrócenie pól z podziałem na control, data, leader */
  toIpubContent() {
    const result = {
      leader: "",
      control: [] as [string, string][],
      data: [] as [string, string, string, [string, string][]][],
    }
    
    this.forEach(field => {
      if (field instanceof MarcSF) {
        if (field.tag === "LDR")
          result.leader = field.value;
        else
          result.control.push([field.tag, field.value]);
      }
      else {
        result.data.push([field.tag, field.ind[0], field.ind[1], field.subfields().mapToArray(sf => [sf.code, sf.value])]);
      }
    });
    
    return result;
  }
}

function getVal(sf: MarcSF) { return sf.value }
function getValAndTrim(sf: MarcSF) { return sf.value.trim() }

export const emptySF  = Object.freeze(new MarcSF("", "", ""));
export const emptySFL = Object.freeze(new MarcSFL(Object.freeze([])));
export const emptyFL  = Object.freeze(new MarcFL(Object.freeze({})));

export function parseSubfields(tag: string, data: string): MarcSFL {
  const parts = data.split("^");
  const sfs = [];

  if (parts[0].length > 0) sfs.push(new MarcSF(tag, "0", parts[0]));

  for (let i = 1, L = parts.length; i < L; i++)
    sfs.push(new MarcSF(tag, parts[i].charAt(0), parts[i].substring(1)));

  return new MarcSFL(sfs);
}
