import React, { useReducer, useRef } from "react";

// TODO: przenieść te hooki do hooks/base i zaznaczyć, że nie wolno tam dodawać zależności z uwagi na cykliczne referencje

const signalReducer = (state: number, _: void) => state + 1;

/** Hook zwracający funkcję, której wywołanie powoduje ponowne renderowanie komponentu */
export function useSignal(): () => void {
  const [, dispatch] = useReducer(signalReducer, 0);
  return dispatch as () => void;
}

/** Hook zwracający funkcję, której wywołanie powoduje ponowne renderowanie komponentu, śledzi liczbę wywołań */
export function useVersionedSignal(): [number, () => void] {
  return useReducer(signalReducer, 0);
}

let ID = 0;

/** Hook zwracający unikalny id tekstowy, odpowiedni do użycia w HTMLu */
export function useId(): string {
  const id = React.useRef("");
  if (id.current === "")
    id.current = `hkid-${ID++}`;
  return id.current;
}

/** Jednorazowy przełącznik, dopóki value jest false, to zwraca false,
 *  a kiedy raz dostanie true, to już zawsze zwraca true niezależnie od value. */
export function useLatch(value: boolean): boolean {
  const ref = useRef(value);
  if (value && !ref.current)
    ref.current = value;
  return ref.current;
}

// TODO: useEffect
export function useLast<T>(value: T, lockIn?: boolean): T {
  const ref = useRef(value);
  if (lockIn ?? value)
    ref.current = value;
  return ref.current;
}
