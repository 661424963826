import {
  CapiCommand,
  capiDateFromString,
  capiDateToString,
} from "./client";

import { emptyArray, emptyObject } from "../utils/constants";
export { TekaParamsDef as ParamsDef, TekaParamsGet as ParamsGet, TekaParamsSet as ParamsSet } from "./common";

const checkValue = CapiCommand.checkValue;
const checkField = CapiCommand.checkField;
const parseDate = capiDateFromString;

export class UserChange extends CapiCommand {
  constructor(user_id, obj_args = {}) {
    super(["tekaUserChange", [user_id], obj_args]);

    checkValue(user_id, "user_id", "string");
    checkValue(obj_args, "obj_args", "object");

    checkField(obj_args, "is_editor", "boolean", "undefined");
  }
}

export class UserInfo extends CapiCommand {
  constructor(user_id) {
    super(["tekaUserInfo", { user_id }]);

    checkValue(user_id, "user_id", "string", "undefined");
  }

  parseData(status, data) {
    checkField(data, "user_id", "string");
    checkField(data, "is_editor", "boolean");

    return data;
  }
}

function parseOwner(owner) {
  checkField(owner, "user_id", "string");
  checkField(owner, "is_editor", "boolean", "undefined");

  return {
    id: owner.user_id, // TODO: deprecated
    user_id: owner.user_id,
    is_editor: owner.is_editor || false
  };
}

function parseOwnerOrAll(owner) {
  if (owner === "all") return owner;
  if (owner === "registered") return owner;
  else return parseOwner(owner);
}

function parseCustody(custody) {
  checkValue(custody, "custody", "object");
  checkField(custody, "custody_id", "number");
  checkField(custody, "level", "string");
  checkField(custody, "at", "string");

  return {
    ...custody,
    at: parseDate(custody.at),
    to: parseOwnerOrAll(custody.to),
    from: parseOwner(custody.from)
  };
}

function parseCustodyArray(data) {
  checkValue(data, "custody", "array", "undefined");
  return data ? data.map(parseCustody) : data;
}

function parseAccess(access) {
  checkValue(access, "access", "object");
  checkField(access, "access_id", "number");
  checkField(access, "level", "string");
  checkField(access, "at", "string");
  checkField(access, "password", "string", "boolean");
  checkField(access, "starts_at", "string", "undefined");
  checkField(access, "ends_at", "string", "undefined");

  const starts_at = access.starts_at
    ? capiDateFromString(access.starts_at)
    : access.starts_at;
  const ends_at = access.ends_at
    ? capiDateFromString(access.ends_at)
    : access.ends_at;

  return {
    ...access,
    at: parseDate(access.at),
    to: parseOwnerOrAll(access.to),
    from: parseOwner(access.from),
    starts_at,
    ends_at
  };
}

function parseAccessArray(data) {
  checkValue(data, "access", "array", "undefined");
  return data ? data.map(parseAccess) : data;
}

function parseDocumentInfo(data) {
  checkField(data, "document_id", "number");
  checkField(data, "created_at", "string");
  checkField(data, "owner", "object", "null");

  checkField(data, "upload_url", "string", "undefined");
  checkField(data, "expires_at", "string", "undefined");

  checkField(data, "file", "object", "null", "undefined");
  checkField(data, "attachments", "array", "undefined");

  checkField(data, "virtual_copies", "number", "undefined");

  checkField(data, "my_custody", "string", "undefined");
  checkField(data, "my_access", "object", "undefined");
  if (data.my_access) {
    checkField(data.my_access, "online", "string", "boolean", "null");
    checkField(data.my_access, "offline", "string", "boolean", "null");
  }

  let result = {
    ...data,
    created_at: parseDate(data.created_at),
    owner: data.owner ? parseOwner(data.owner) : data.owner,
    custody: parseCustodyArray(data.custody)
  };

  if (data.expires_at) result.expires_at = parseDate(data.expires_at);

  if (result.file) {
    checkField(result.file, "created_at", "string");
    checkField(result.file, "mimetype", "string");
    checkField(result.file, "bytes", "number");
    result.file = {
      ...result.file,
      created_at: parseDate(result.file.created_at)
    };
  } else result.file = null;

  result.attachments = result.attachments || [];

  result.attachments.forEach(att => {
    checkField(att, "attachment_id", "number");
    checkField(att, "kind", "string");
    checkField(att, "origin", "string");
    checkField(att, "url", "string");
    checkField(att, "mimetype", "string");
    checkField(att, "bytes", "number");
    checkField(att, "selected", "boolean");
  });

  return result;
}

export class DocumentNew extends CapiCommand {
  constructor() {
    super(["tekaDocumentNew"]);
  }

  parseData(status, data) {
    if (status !== 200) return data;

    return parseDocumentInfo(data);
  }
}

export class DocumentInfo extends CapiCommand {
  constructor(document_id) {
    super(["tekaDocumentInfo", [document_id]]);
    checkValue(document_id, "document_id", "number");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    return parseDocumentInfo(data);
  }
}

export class DocumentPut extends CapiCommand {
  constructor(document_id, file_url) {
    super(["tekaDocumentPut", [document_id, file_url]]);

    checkValue(document_id, "document_id", "number");
    checkValue(file_url, "file_url", "string");
  }
}

export class DocumentDelete extends CapiCommand {
  constructor(document_id) {
    super(["tekaDocumentDelete", [document_id]]);

    checkValue(document_id, "document_id", "number");
  }
}

export class DocumentEdit extends CapiCommand {
  constructor(
    document_id,
    {
      name,
      state,
      sowa_id,
      sowa_lending,
      sowa_lending_id,
      catalogue_id,
      metadata,
      license_id,
      virtual_copies,
      frozen,
      campus_only
    }
  ) {
    super([
      "tekaDocumentEdit",
      [document_id],
      {
        name,
        state,
        sowa_id,
        sowa_lending,
        sowa_lending_id,
        metadata,
        catalogue_id,
        license_id,
        virtual_copies,
        frozen,
        campus_only
      }
    ]);

    checkValue(document_id, "document_id", "number");
    checkValue(name, "name", "string", "undefined");
    checkValue(state, "state", "string", "undefined");
    checkValue(sowa_id, "sowa_id", "string", "undefined");
    checkValue(sowa_lending, "sowa_lending", "boolean", "null", "undefined");
    checkValue(sowa_lending_id, "sowa_lending_id", "string", "undefined");
    checkValue(catalogue_id, "sowa_id", "string", "undefined");
    checkValue(metadata, "metadata", "object", "undefined");
    checkValue(license_id, "license_id", "number", "null", "undefined");
    checkValue(virtual_copies, "virtual_copies", "number", "undefined");
    checkValue(frozen, "frozen", "boolean", "undefined");
    checkValue(campus_only, "campus_only", "boolean", "undefined");
  }
}

export class DocumentLinks extends CapiCommand {
  constructor(document_id) {
    super(["tekaDocumentLinks", [document_id]]);

    checkValue(document_id, "document_id", "number");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "array");
    data.forEach(path => {
      checkField(path, "path", "array");
      checkField(path, "tree_id", "number");
      checkField(path, "tree_ids", "array");
      checkField(path, "access", "object");
      checkField(path.access, "online", "string", "array");
      checkField(path.access, "online_with_password", "string", "array");
      checkField(path.access, "offline", "string", "array");
      checkField(path.access, "offline_with_password", "string", "array");
    });

    return data;
  }
  
  *getMarkerList() {
    if (this.status === 200) {
      for (const link of this.result.data)
        for (const id of link.tree_ids)
          yield `tree:${id}`;
    }
  }
}

// export class DocumentAccessOnline extends CapiCommand {
//   constructor(document_id, password) {
//     super(["tekaDocumentAccess", ["online", document_id], { password }]);
//
//     checkValue(document_id, "document_id", "number");
//     checkValue(password, "password", "string", "undefined");
//   }
//
//   parseData(status, data) {
//     if (status !== 200) return data;
//
//     checkValue(data, "data", "object");
//     checkField(data, "online_url", "string");
//     checkField(data, "expires_at", "string");
//
//     return { ...data, expires_at: parseDate(data.expires_at) };
//   }
// }
//
// export class DocumentAccessOffline extends CapiCommand {
//   constructor(document_id, password) {
//     super(["tekaDocumentAccess", ["offline", document_id], { password }]);
//
//     checkValue(document_id, "document_id", "number");
//     checkValue(password, "password", "string", "undefined");
//   }
//
//   parseData(status, data) {
//     if (status !== 200) return data;
//
//     checkValue(data, "data", "object");
//     checkField(data, "offline_url", "string");
//     checkField(data, "expires_at", "string");
//
//     return { ...data, expires_at: parseDate(data.expires_at) };
//   }
// }

export class DocumentStats extends CapiCommand {
  constructor(document_id, leases_from, leases_to) {
    super(["tekaDocumentStats", [document_id], {
      leases_from: leases_from ? capiDateToString(leases_from) : undefined,
      leases_to: leases_to ? capiDateToString(leases_to) : undefined
    }]);

    checkValue(document_id, "document_id", "number");
    checkValue(leases_from, "leases_from", "object", "undefined")
    checkValue(leases_to, "leases_to", "object", "undefined")
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object");
    checkField(data, "download_count", "number");
    checkField(data, "anon_download_count", "number");
    checkField(data, "untracked_loan_count", "number");
    checkField(data, "virtual_loan_count", "number");
    checkField(data, "physical_loan_count", "number");
    checkField(data, "total_loan_seconds", "number");

    checkField(data, "leases", "array", "undefined");

    return { ...data };
  }
}

export class FolderNew extends CapiCommand {
  constructor(parent, name, owner_id) {
    super(["tekaTreeNew", ["folder", parent], { owner_id, name }]);

    checkValue(parent, "parent", "number", "array");
    checkValue(name, "name", "string");
    checkValue(owner_id, "owner_id", "string", "undefined");
  }
}

export class DocumentLinkNew extends CapiCommand {
  constructor(parent, document_id, owner_id) {
    super(["tekaTreeNew", ["document", parent], { owner_id, document_id }]);

    checkValue(parent, "parent", "number", "array");
    checkValue(document_id, "document_id", "number");
    checkValue(owner_id, "owner_id", "string", "undefined");
  }
}

export class TreeNew extends CapiCommand {
  constructor(kind, parent, document_id, owner_id) {
    super(["tekaTreeNew", [kind, parent], { owner_id, document_id }]);

    checkValue(kind, "kind", "string");
    checkValue(parent, "parent", "number", "array", "null");
    checkValue(document_id, "document_id", "number", "undefined");
    checkValue(owner_id, "owner_id", "string", "undefined");
  }
}

export class TreeInfo extends CapiCommand {
  constructor(path, options) {
    super(["tekaTreeInfo", [path], options || emptyObject]);

    checkValue(path, "path", "number", "array");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkField(data, "tree_id", "number");
    checkField(data, "kind", "string");
    checkField(data, "name", "string", "null");
    checkField(data, "document", "object", "undefined");
    checkField(data, "owner", "null", "object");

    // TODO: access, custody

    if (data.document) {
      checkField(data.document, "document_id", "number");
      checkField(data.document, "campus_only", "boolean");
      checkField(data.document, "file", "object", "undefined");
      checkField(data.document, "front_url", "string", "undefined");

      if (data.document.file) {
        checkField(data.document.file, "mimetype", "string");
        checkField(data.document.file, "bytes", "number");
      }
    }

    return {
      ...data,
      owner: data.owner ? parseOwner(data.owner) : data.owner,
      access: parseAccessArray(data.access),
      custody: parseCustodyArray(data.custody)
    };
  }

  getMarkerList() {
    if (this.status !== 200) return emptyArray;
    const data = this.result.data;
    const t = `tree:${data.tree_id}`;
    if (data.document)
      return [t, `document:${data.document.document_id}`];
    else
      return [t];
  }
}
// super(["tekaApplicationQueue", [kind], { volume, offset, document_id }]);

export class TreeList2 extends CapiCommand {
  constructor(path, { at, volume, kinds, recursive, after, before, access, campus_only, descending, order } = emptyObject) {
    super(["tekaTreeList2", [path], { at, volume, kinds, recursive, after, before, access, campus_only, descending, order }]);
    checkValue(path, "path", "number", "array");
    checkValue(volume, "volume", "number", "undefined");
    checkValue(kinds, "kinds", "array", "undefined");
    checkValue(recursive, "recursive", "boolean", "undefined");
    checkValue(access, "access", "string", "undefined")
    // tutaj podajemy cokolwiek serwer zwróci
    //checkValue(after, "after", "string", "array", "null", "undefined");
    //checkValue(before, "before", "string", "array", "null", "undefined");
  }
  
  parseData(status, data) {
    if (status !== 200) return data;
    
    // serwer tutaj może zwrócić co mu się podoba
    //checkField(data, "at", "array", "null");
    //checkField(data, "next", "array", "null");
    //checkField(data, "prev", "array", "null");
    checkField(data, "p8n", "string");  
    checkField(data, "results", "array");
    checkField(data, "volume", "number")  

    data.results.forEach(tree => {
      checkField(tree, "tree_id", "number");
      checkField(tree, "kind", "string");
      checkField(tree, "name", "string");
      checkField(tree, "document", "object", "undefined");
      checkField(tree, "created_at", "string");
      
      tree.created_at = parseDate(tree.created_at);
      
      if (tree.document) {
        checkField(tree.document, "document_id", "number");
        checkField(tree.document, "campus_only", "boolean");
        checkField(tree.document, "file", "object", "undefined");
        checkField(tree.document, "access", "string", "undefined");
        
        if (tree.document.file) {
          checkField(tree.document.file, "mimetype", "string");
          checkField(tree.document.file, "bytes", "number");
          checkField(tree.document.file, "created_at", "string");

          tree.document.file.created_at = parseDate(tree.document.file.created_at);
        }
      }
    });

    return data;
  }
  
  *getMarkerList() {
    // TreeList2 przede wszystkim zwraca nam informacje o węzłach, więc rejestrujemy na nich markery,
    // ale węzły mogą zawierać też informacje o dokumentach
    if (this.status === 200)
    for (const result of this.result.data.results) {
      yield `tree:${result.tree_id}`;
      const id = result.document?.document_id;
      if (id)
        yield `document:${id}`;
    }
  }
}

export class TreeListNav extends CapiCommand {
  constructor() {
    super(["tekaTreeListNav"])
  }
  
  parseData(status, data) {
    if (status !== 200) return data;
    
    checkValue(data, "data", "array");
    data.forEach(entry => {
      checkField(entry, "label", "string");
      //checkField(entry, "after"); // dowolny typ
    })
    return data;
  }

}

export class TreeList extends CapiCommand {
  constructor(path, { offset, volume, kind } = {}) {
    super(["tekaTreeList", [path], { offset, volume, kind }]);

    checkValue(path, "path", "number", "array");
    checkValue(volume, "volume", "number", "undefined");
    checkValue(kind, "kind", "string", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkField(data, "num_rows", "number");
    checkField(
      data,
      "next_offset",
      "object",
      "array",
      "number",
      "string",
      "null"
    );
    checkField(
      data,
      "prev_offset",
      "object",
      "array",
      "number",
      "string",
      "null"
    );
    checkField(data, "children", "array");

    data.children.forEach(tree => {
      checkField(tree, "tree_id", "number");
      checkField(tree, "kind", "string");
      checkField(tree, "name", "string");
      checkField(tree, "document", "object", "undefined");

      if (tree.document) {
        checkField(tree.document, "document_id", "number");
        checkField(tree.document, "campus_only", "boolean");
        checkField(tree.document, "file", "object", "undefined");
        if (tree.document.file) {
          checkField(tree.document.file, "mimetype", "string");
          checkField(tree.document.file, "bytes", "number");
        }
      }
    });

    return data;
  }
}

export class TreeDelete extends CapiCommand {
  constructor(path, { recursive, selective } = {}) {
    super(["tekaTreeDelete", [path], { recursive, selective }]);

    checkValue(path, "path", "number", "array");
    checkValue(recursive, "recursive", "boolean", "undefined");
    checkValue(selective, "selective", "boolean", "undefined");
  }
}

export class AccessGrant extends CapiCommand {
  constructor(path, level, recipient, password, starts_at, ends_at) {
    // starts_at = capiDateToString(starts_at)
    super([
      "tekaAccessGrant",
      [path, level, recipient],
      {
        password,
        starts_at: starts_at ? capiDateToString(starts_at) : undefined,
        ends_at: ends_at ? capiDateToString(ends_at) : undefined
      }
    ]);

    checkValue(path, "path", "number", "array");
    checkValue(level, "level", "string");
    checkValue(recipient, "recipient", "string");
    checkValue(password, "password", "string", "undefined");
    checkValue(starts_at, "starts_at", "object", "undefined");
    checkValue(ends_at, "ends_at", "object", "undefined");
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "access_id", "number");
    }
    return data;
  }
}

export class AccessRevoke extends CapiCommand {
  constructor(access_id) {
    super(["tekaAccessRevoke", [access_id]]);

    checkValue(access_id, "access_id", "number");
  }
}

export class DocumentCustodyGrant extends CapiCommand {
  constructor(document_id, level, recipient) {
    super(["tekaCustodyGrant", ["document", document_id, level, recipient]]);

    checkValue(document_id, "document_id", "number");
    checkValue(level, "level", "string");
    checkValue(recipient, "recipient", "string");
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "custody_id", "number");
    }
    return data;
  }
}

export class TreeCustodyGrant extends CapiCommand {
  constructor(cls, path, level, recipient, custody_id) {
    super(["tekaCustodyGrant", [cls, path, level, recipient], { custody_id }]);

    checkValue(cls, "cls", "string");
    checkValue(path, "path", "number", "array");
    checkValue(level, "level", "string");
    checkValue(recipient, "recipient", "string");
    checkValue(custody_id, "custody_id", "number", "undefined");
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "custody_id", "number");
    }
    return data;
  }
}

export class CustodyRevoke extends CapiCommand {
  constructor(custody_id) {
    super(["tekaCustodyRevoke", [custody_id]]);

    checkValue(custody_id, "custody_id", "number");
  }
}

export class ElasticSearch extends CapiCommand {
  constructor(query, params = {}) {
    const search = Array.isArray(query) ? "split" : "simple";
    super(["tekaElasticSearch2", [search, query], params]);

    if (search === "simple") {
      checkValue(query, "query", "string");
    } else {
      checkValue(query, "query", "array");
      query.forEach((e, index) => {
        checkValue(query[index], "query", "string");
      })
    }
  }
}

export class AttachmentEdit extends CapiCommand {
  constructor(attachment_id, { kind, selected }) {
    super(["tekaAttachmentEdit", [attachment_id], { kind, selected }]);

    checkValue(attachment_id, "attachment_id", "number");
    checkValue(kind, "kind", "string", "undefined");
    checkValue(selected, "selected", "boolean", "undefined");
  }
}

export class LicenseNew extends CapiCommand {
  constructor(name, text = "", comment = "") {
    super(["tekaLicenseNew", [name, text, comment]]);

    checkValue(name, "name", "string");
    checkValue(text, "text", "string");
    checkValue(comment, "comment", "string");
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "license_id", "number");
    }
    return data;
  }
}

export class LicenseInfo extends CapiCommand {
  constructor(license_id) {
    super(["tekaLicenseInfo", [license_id]]);

    checkValue(license_id, "license_id", "number");
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "license_id", "number");
      checkField(data, "name", "string");
      checkField(data, "text", "string");
      checkField(data, "comment", "string", "undefined");
      checkField(data, "state", "string");
      checkField(data, "references", "number");
    }
    return data;
  }
}

export class LicenseList extends CapiCommand {
  constructor() {
    super(["tekaLicenseList"]);
  }

  parseData(status, data) {
    if (status === 200) {
      checkValue(data, "data", "array");
      data.forEach(e => {
        checkField(e, "license_id", "number");
        checkField(e, "name", "string");
        checkField(e, "state", "string");
        checkField(e, "references", "number");
      });
    }
    return data;
  }
}

export class LicenseEdit extends CapiCommand {
  constructor(license_id, { name, text, comment, state }) {
    super(["tekaLicenseEdit", [license_id], { name, text, comment, state }]);

    checkValue(license_id, "license_id", "number");
    checkValue(name, "name", "string", "undefined");
    checkValue(text, "text", "string", "undefined");
    checkValue(comment, "comment", "string", "undefined");
    checkValue(state, "state", "string", "undefined");
  }
}

export class LicenseDelete extends CapiCommand {
  constructor(license_id) {
    super(["tekaLicenseDelete", [license_id]]);
    
    checkValue(license_id, "license_id", "number");
    
    this.license_id = license_id;
  }
}

export class LicenseGrantNew extends CapiCommand {
  constructor(document_id) {
    super(["tekaLicenseGrantNew", [document_id]]);
    
    checkValue(document_id, "document_id", "number");
  }
}

export class LicenseGrantList extends CapiCommand {
  constructor(document_id, volume) {
    super(["tekaLicenseGrantList", [document_id], {volume}]);
    
    checkValue(document_id, "document_id", "number");
  }
  
  parseData(status, data) {
    if (status !== 200) return data;
    return {
      ...data,
      results: data.results.map(result => ({
        ...result,
        granted_at: result.granted_at && capiDateFromString(result.granted_at),
        expires_at: result.expires_at && capiDateFromString(result.expires_at),
        unlocks_at: result.unlocks_at && capiDateFromString(result.unlocks_at)
      }))
    };
  }
}

export class LicenseGrantEdit extends CapiCommand {
  constructor(lg_id, { granted_at, expires_at, license_id, state }) {
    const kwargs = {
      granted_at: granted_at && capiDateToString(granted_at),
      expires_at: expires_at && capiDateToString(expires_at),
      license_id,
      state
    };
    
    super(["tekaLicenseGrantEdit", [lg_id], kwargs]);
    
    checkValue(lg_id, "lg_id", "number");
  }
}

export class ApplicationNew extends CapiCommand {
  constructor(kind, { document_id, message }) {
    super(["tekaApplicationNew", [kind], { document_id, message }]);

    checkValue(kind, "kind", "string");
    checkValue(document_id, "document_id", "number", "undefined");
    checkValue(message, "message", "string", "undefined");
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "application_id", "number");
    }
    return data;
  }
}

export class ApplicationInfo extends CapiCommand {
  constructor(application_id) {
    super(["tekaApplicationInfo", [application_id]]);

    checkValue(application_id, "application_id", "number");
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "application_id", "number");
      checkField(data, "kind", "string");
      checkField(data, "state", "string");
      checkField(data, "created_at", "string");
      checkField(data, "message", "string");
      checkField(data, "verdict", "string", "undefined");

      checkField(data, "applicant", "object", "undefined");
      checkField(data, "claimed_by", "object", "undefined");
      checkField(data, "document", "object", "undefined");

      if (data.applicant) checkField(data.applicant, "user_id", "string");

      if (data.claimed_by) checkField(data.claimed_by, "user_id", "string");

      if (data.document) {
        checkField(data.document, "document_id", "number");
        checkField(data.document, "name", "string", "undefined");
      }

      return { ...data, created_at: capiDateFromString(data.created_at) };
    }
    return data;
  }
}

export class ApplicationList extends CapiCommand {
  kind;
  
  constructor(kind, { volume, offset, document_id } = {}) {
    super(["tekaApplicationList", [kind], { volume, offset, document_id }]);

    checkValue(kind, "kind", "string");
    checkValue(volume, "volume", "number", "undefined");
    checkValue(offset, "offset", "number", "undefined");
    checkValue(document_id, "document_id", "number", "undefined");
    
    this.kind = kind;
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "next_offset", "number", "null");
      checkField(data, "prev_offset", "number", "null");
      checkField(data, "items", "array");

      const items = data.items.map(item => {
        checkField(item, "application_id", "number");
        checkField(item, "kind", "string");
        checkField(item, "state", "string");
        checkField(item, "created_at", "string");
        checkField(item, "message", "string");
        checkField(item, "verdict", "string", "undefined");

        checkField(item, "applicant", "object", "undefined");
        checkField(item, "claimed_by", "object", "undefined");
        checkField(item, "document", "object", "undefined");

        return { ...item, created_at: capiDateFromString(item.created_at) };
      });

      return { ...data, items };
    }
    return data;
  }
  
  getMarkerList() {
    return this.status === 200 ? [`tekaApplicationList:${this.kind}`] : emptyArray;
  }
}

export class ApplicationQueue extends CapiCommand {
  kind;
  
  constructor(kind, { volume, offset, document_id }) {
    super(["tekaApplicationQueue", [kind], { volume, offset, document_id }]);

    checkValue(kind, "kind", "string");
    checkValue(volume, "volume", "number", "undefined");
    checkValue(offset, "offset", "number", "undefined");
    checkValue(document_id, "document_id", "number", "undefined");

    this.kind = kind;
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "next_offset", "number", "null");
      checkField(data, "prev_offset", "number", "null");
      checkField(data, "items", "array");

      const items = data.items.map(item => {
        checkField(item, "application_id", "number");
        checkField(item, "kind", "string");
        checkField(item, "state", "string");
        checkField(item, "created_at", "string");
        checkField(item, "message", "string");
        checkField(item, "verdict", "string", "undefined");

        checkField(item, "applicant", "object", "undefined");
        checkField(item, "claimed_by", "object", "undefined");
        checkField(item, "document", "object", "undefined");

        return { ...item, created_at: capiDateFromString(item.created_at) };
      });

      return { ...data, items };
    }
    return data;
  }
  
  getMarkerList() {
    return this.status === 200 ? [`tekaApplicationList:${this.kind}`] : emptyArray;
  }
}

export class ApplicationEdit extends CapiCommand {
  constructor(application_id, { message, verdict, claim, state }) {
    super([
      "tekaApplicationEdit",
      [application_id],
      { message, verdict, claim, state }
    ]);

    checkValue(application_id, "application_id", "number");
    checkValue(message, "message", "string", "undefined");
    checkValue(verdict, "verdict", "string", "undefined");
    checkValue(claim, "claim", "boolean", "undefined");
    checkValue(state, "state", "string", "undefined");
  }
}

export class MiscInfo extends CapiCommand {
  constructor(x) {
    super(["tekaMiscInfo", [x]]);
  }
}

export class LeaseRequest extends CapiCommand {
  constructor(document_id, { password, duration, request }) {
    super(["tekaLeaseRequest", [document_id], { password, duration, request }]);

    checkValue(document_id, "document_id", "number");
    checkValue(password, "password", "string", "undefined");
    checkValue(duration, "duration", "number", "undefined");
    checkValue(request, "request", "boolean", "undefined");
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "lease_id", "string");
      checkField(data, "kind", "string");
      checkField(data, "state", "string");
      checkField(data, "starts_at", "string");
      checkField(data, "ends_at", "string");
      checkField(data, "limit", "string");

      return {
        ...data,
        starts_at: capiDateFromString(data.starts_at),
        ends_at: capiDateFromString(data.ends_at),
        limit: capiDateFromString(data.limit)
      };
    }
    return data;
  }
  
  static dontCache = true;
}

export class LeaseInfo extends CapiCommand {
  constructor(document_id, { countWaiting }) {
    super(["tekaLeaseInfo", [document_id], { count_waiting: countWaiting }]);

    checkValue(document_id, "document_id", "number");
    checkValue(countWaiting, "countWaiting", "boolean", "undefined");
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "lease_id", "string");
      checkField(data, "kind", "string");
      checkField(data, "state", "string");
      checkField(data, "starts_at", "string");
      checkField(data, "ends_at", "string");
      checkField(data, "limit", "string");
      checkField(data, "waiting_ahead", "number", "undefined");
      checkField(data, "waiting_behind", "number", "undefined");

      return {
        ...data,
        starts_at: capiDateFromString(data.starts_at),
        ends_at: capiDateFromString(data.ends_at),
        limit: capiDateFromString(data.limit)
      };
    }
    return data;
  }
  
  static dontCache = true;
}

export class LeaseContents extends CapiCommand {
  constructor(lease_id) {
    super(["tekaLeaseContents", [lease_id]]);

    checkValue(lease_id, "lease_id", "string");
  }

  parseData(status, data) {
    if (status === 200) {
      checkField(data, "thumbs", "string");
      checkField(data, "pages", "array");

      data.pages.forEach(page => {
        checkField(page, "ord", "number");
        checkField(page, "w", "number");
        checkField(page, "h", "number");
      });
    }
    return data;
  }
  
  static dontCache = true;
}

export class LeasePage extends CapiCommand {
  constructor(lease_id, pageOrdinal) {
    super(["tekaLeasePage", [lease_id, pageOrdinal]]);

    checkValue(lease_id, "lease_id", "string");
    checkValue(pageOrdinal, "pageOrdinal", "number");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkField(data, "url", "string");
    checkField(data, "key", "string");
    checkField(data, "expires_at", "string");
    checkField(data, "w", "number");
    checkField(data, "h", "number");

    return data;
  }
}

LeasePage.prototype.dontPrintData = true; // czemu kurde `static ...` tak nie działa

export class LeaseEnd extends CapiCommand {
  constructor(lease_id, smart=true) {
    super(["tekaLeaseEnd", [lease_id], { smart }]);
    
    checkValue(lease_id, "lease_id", "string");
    checkValue(smart, "smart", "boolean", "undefined");
  }
}

export class IssueNew extends CapiCommand {
  constructor(document_id, kind, message) {
    super(["tekaIssueNew", [document_id, kind, message]]);

    checkValue(document_id, "document_id", "number");
    checkValue(kind, "kind", "string");
    checkValue(message, "message", "string");
  }
}

export class IssueEdit extends CapiCommand {
  constructor(issue_id, { state, resolution }) {
    super(["tekaIssueEdit", [issue_id], { state, resolution }]);

    checkValue(issue_id, "document_id", "number");
    checkValue(state, "state", "string", "undefined");
    checkValue(resolution, "resolution", "string", "undefined");
  }
}

export class IssueList extends CapiCommand {
  constructor({ kind, state, offset, volume }) {
    super(["tekaIssueList", [], { kind, state, offset, volume }]);

    checkValue(kind, "kind", "array", "undefined");
    checkValue(state, "state", "array", "undefined");
    checkValue(offset, "offset", "number", "undefined");
    checkValue(volume, "volume", "number", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) {
      return data;
    }

    checkField(data, "next_offset", "number", "null");
    checkField(data, "prev_offset", "number", "null");
    checkField(data, "items", "array");

    data.items.forEach(item => {
      checkField(item, "id", "number");
      checkField(item, "kind", "string");
      checkField(item, "state", "string");
      checkField(item, "document_id", "number");
      checkField(item, "issuer_id", "string");
      checkField(item, "message", "string");
      checkField(item, "resolver_id", "string", "undefined");
      checkField(item, "resolution", "string", "undefined");
    });

    return data;
  }
}

export class ReportCount extends CapiCommand {
  constructor(report_name, date_from, date_to, top_count=10) {
    super(["tekaReportCount", [report_name, date_from, date_to], { top_count }]);

    checkValue(report_name, "report_name", "string");
    checkValue(date_from, "date_from", "string", "undefined");
    checkValue(date_to, "date_to", "string", "undefined");
    checkValue(top_count, "top_count", "number");
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "object", "array");

    // TODO: Sprawdzić wedle nazwy raportu.
    /*checkField(data, "anonymous_count", "number", "undefined");
    checkField(data, "authorized_count", "number", "undefined");
    checkField(data, "anonymous_time", "number", "undefined");
    checkField(data, "authorized_time", "number", "undefined");

    checkField(data, "unique_users_count", "number", "undefined");*/

    return { ...data };
  }
}

export class ReportLeases extends CapiCommand {
  constructor(user_id, date_from=new Date(), date_to=new Date()) {
    date_from   = capiDateToString(date_from);
    date_to     = capiDateToString(date_to);

    super(["tekaReportLeases", [user_id, date_from, date_to]])

    checkValue(user_id, "user_id", "string")
    checkValue(date_from, "date_from", "string")
    checkValue(date_to, "date_to", "string")
  }
  
  parseData(status, data) {
    if(status !== 200) return data;

    checkValue(data, "data", "array");

    return data.map(def => {
      checkField(def, "document_id", "number")
      checkField(def, "starts_at", "string")
      checkField(def, "ends_at", "string")
      checkField(def, "state", "string")
      checkField(def, "pool", "string")

      return {...def, starts_at: capiDateFromString(def.starts_at), ends_at: capiDateFromString(def.ends_at)}
    })
  }  
}

export class ReportTable extends CapiCommand {
  constructor(report_name, at, volume) {
    super(["tekaReportTable", [ report_name ], { at, volume }])

    checkValue(report_name, "report_name", "string");

    checkValue(at, "at", "number", "undefined", "null");
    checkValue(volume, "volume", "number", "undefined");
  }

  parseData(status, data) {
    if(status !== 200) return data;

    checkValue(data, "data", "object");

    checkField(data, "p8n", "string");
    checkField(data, "at", "number", "null");
    checkField(data, "prev", "number", "null");
    checkField(data, "next", "number", "null");
    checkField(data, "volume", "number");
    checkField(data, "total", "number", "undefined");
    checkField(data, "results", "array", "undefined");

    // TODO: Sprawdzić wartości "results"

    return data;
  }
}

export class DeferredResult extends CapiCommand {
  constructor(deferred_id) {
    super(["deferredResult", [deferred_id]]);

    checkValue(deferred_id, "deferred_id", "string")
  }

  parseData(status, data) {
    if(status !== 202) return data;

    checkValue(data, "data", "object");

    checkField(data, "progress", "number");

    return data;
  }
  
  static dontCache = true;
}
