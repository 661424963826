import React, { useEffect, useState } from 'react'
import InputMask from "react-input-mask";
import cx from "classnames";

export type MaskedInputProps = {
  value?: string;
  placeholder?: string;
  label?: string;
  name?: string;

  form?: string;

  mask: string;
  maskPlaceholder?: string | null;
  alwaysShowMask?: boolean;
  beforeMaskedStateChange?: (newState: any, oldState: any, userInput: any) => any;
  dirty?: boolean;

  disabled?: boolean;
  required?: boolean;
  error?: boolean | React.ReactNode;

  className?: string;
  style?: object;
  labelCls?: string;
  fluid?: boolean;

  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  onFocus?: (value: any) => void;
};

export default function MaskedInput({ value, placeholder, label, name, form, mask, maskPlaceholder = "_", alwaysShowMask = true, disabled, required, error, className, style, labelCls, fluid, onChange, onBlur, onFocus, beforeMaskedStateChange }: MaskedInputProps) {
  const [active, setActive] = useState(false)

  const handleChange = (element: any) =>
    onChange && onChange(element.target.value);

  const wrapperClasses = cx("custom-input", { disabled, fluid, active, labeled: label ?? false, error })
  const inputClasses = cx("custom-input__input", className, disabled ? "custom-input__input--disabled" : null);

  const labelEl = <label className={`custom-input__label ${labelCls} ${required ? "required" : ""}`}>
    {label} </label> ?? false;

  useEffect(() => {
    if (value) {
      setActive(true)
    }
  }, [value])

  const handleFocus = (e: any) => {
    setActive(true);
    if (onFocus) {
      onFocus(e)
    }
  }
  const handleBlur = (e: any) => {
    if (onBlur) {
      onBlur(e);
    }
  }

  return <div className={wrapperClasses} style={style}>
    {labelEl}
    <div className="custom-input__inner">
      <InputMask
        maskPlaceholder={maskPlaceholder}
        mask={mask}
        value={value}
        alwaysShowMask={false}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        disabled={disabled}
      >
        <input
          form={form}
          className={inputClasses}
          name={name}
          onChange={handleChange}
        />
      </InputMask>
    </div>
    {error
      ? <div className="custom-input__error-msg">
          {error}
        </div>
      : null
    }
  </div>
}