import { applyMiddleware, createStore, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import reducer from "./reducers/reducer";

import {
  APP_HIDDEN,
  APP_SHOWN,
  APP_LOAD,
  ACTIVITY_CHANGE,
  CHECK_SESSION,
  CURRENT_USER_SUCCESS,
  CLEAR_USER,
  FETCH_CURRENT_USER,
  CURRENT_USER_SOWA_SUCCESS,
  CURRENT_USER_SOWA_ERROR,
  CURRENT_USER_TEKA_ERROR,
  CURRENT_USER_TEKA_SUCCESS
} from "./sagas/types";

import { registerUpdateMarker, unregisterUpdateMarker } from "./utils/hooks/markers";

export const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = process.env.NODE_ENV === "development" ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose;

const middleware = [sagaMiddleware];

if (window.bootstrap.devel) {
  middleware.unshift(store => next => action => {
    console.log("DISPATCH", (new Date()).toISOString(), action.type, action)
    return next(action);
  });
}

(() => {
  // Mostek między aktualizacją danych zalogowanego użytkownika w operacjach, a odczytem ich w sadze
  
  middleware.unshift(store => next => action => {
    switch (action.type) {
      case CLEAR_USER: {
        unregisterUpdateMarker(onSignal);
        unregisterUpdateMarker(onTekaSignal);
        for (const sig of sowaSignals.values())
          unregisterUpdateMarker(sig);
        break;
      }
      case CURRENT_USER_SUCCESS: {
        unregisterUpdateMarker(onSignal);
        registerUpdateMarker(`userInfo:${action.payload.user_id}`, onSignal);
        break;
      }
      case CURRENT_USER_TEKA_SUCCESS:
      case CURRENT_USER_TEKA_ERROR: {
        unregisterUpdateMarker(onTekaSignal);
        registerUpdateMarker(`tekaUserInfo:${action.payload.user_id}`, onTekaSignal);
        break;
      }
      case CURRENT_USER_SOWA_SUCCESS:
      case CURRENT_USER_SOWA_ERROR: {
        // TODO: zmiana loginu może tu namieszać
        const cat = bootstrap.sowa.catalogues.find(cat => cat.cat_id === action.payload.cat_id);
        const sig = getSowaSignal(cat.cat_id);
        unregisterUpdateMarker(sig);
        registerUpdateMarker(`sowaUserGet:${action.payload.login}@${cat.url}`, sig);
        registerUpdateMarker(`sowaUserDef@${cat.url}`, sig);
        break;
      }
    }
    
    return next(action);
  });
  
  function onSignal(keys) {
    store.dispatch({ type: FETCH_CURRENT_USER, payload: {} });
  }
  
  const sowaSignals = new Map();
  
  function getSowaSignal(catId) {
    let result = sowaSignals.get(catId);
    if (!result) {
      result = function onSignalSowa(keys) { return onSignal(keys) };
      sowaSignals.set(catId, result);
    }
    return result;
  }
  
  const onTekaSignal = keys => onSignal(keys);
})();

const store = createStore(
  reducer,
  /* preloadedState, */ composeEnhancers(applyMiddleware(...middleware))
);

// Monitorowanie czy jesteśmy aktywną kartą w przeglądarce
(() => {
  const SHOWN = Object.freeze({ type: APP_SHOWN });
  const HIDDEN = Object.freeze({ type: APP_HIDDEN });
  
  let debouncer = null;
  let visible = false;
  
  document.addEventListener("visibilitychange", () => {
    const visibleNow = document.visibilityState === "visible";
    if (visibleNow !== visible) {
      visible = visibleNow;
      clearTimeout(debouncer);
      debouncer = setTimeout(store.dispatch, 100, visibleNow ? SHOWN : HIDDEN);
    }
  })
})();

// Wykrywanie aktywności użytkownika
// Z dokładnością do minuty, bo co minutę instalujemy event handlery wykrywające interakcje ze stroną
// (Nie chcemy żeby chodziły w tle cały czas.)
(() => {
  const ACTIVE = Object.freeze({ type: ACTIVITY_CHANGE, payload: true });
  const INACTIVE = Object.freeze({ type: ACTIVITY_CHANGE, payload: false });
  const SUSPEND = Object.freeze({ type: ACTIVITY_CHANGE, payload: null });
  const CHECK = Object.freeze({ type: CHECK_SESSION });
  const listenerOptions = { capture: true };
  
  let timer = null;
  let visible = document.visibilityState === "visible";
  let activity = null;
  let trapSet = false;
  let minutesInactive = 0;

  function trap() {
    // "pułapka" to event handler wykrywający aktywność
    trapSet = false;
    minutesInactive = 0;
    unbind();
    if (!activity) {
      activity = true;
      store.dispatch(ACTIVE);
    }
  }
  
  function unbind() {
    window.removeEventListener("mousemove", trap, listenerOptions);
    window.removeEventListener("keydown", trap, listenerOptions);
    window.removeEventListener("touchstart", trap, listenerOptions);
  }
  
  function enable() {
    activity = true;
    minutesInactive = 0;
    store.dispatch(ACTIVE);
    if (timer === null) {
      timer = setInterval(() => {
        minutesInactive++;
        if (minutesInactive > 1 && activity) {
          activity = false;
          store.dispatch(INACTIVE);
        }
        else
          store.dispatch(CHECK);
  
        if (!trapSet) {
          window.addEventListener("mousemove", trap, listenerOptions);
          window.addEventListener("keydown", trap, listenerOptions);
          window.addEventListener("touchstart", trap, listenerOptions);
        }
      }, 60e3);
    }
  }
  
  function disable() {
    clearInterval(timer);
    timer = null;
    activity = null;
    unbind();
    store.dispatch(SUSPEND);
  }
  
  document.addEventListener("visibilitychange", () => {
    const visibleNow = document.visibilityState === "visible";
    if (visibleNow !== visible) {
      visible = visibleNow;
      if (visibleNow)
        enable()
      else
        disable()
    }
  });
  
  if (visible)
    enable();
  else
    disable();
})();


(() => {
  const LOAD = Object.freeze({ type: APP_LOAD });
  window.addEventListener("load", () => {
    store.dispatch(LOAD);
  })
})();

export default store;
