import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'components/shared';
import Settings from 'components/special-pages/Settings';
import { useSmallScreen, useTouchDevice } from "../../../../utils/hooks";
import { useShellContext } from "../../../shell/shellContext";
import { SidebarMode } from "../types";
import { LanguageMenu } from './LanguageMenu';
import ContrastSwitch from './ContrastSwitch';
import { ColumnLayout } from 'components/shared/ColumnLayout';

export const SidebarHeader = React.memo(({ sidebarMode }: { sidebarMode: SidebarMode }) => {
  const buttons = [
    homeButton,
    languageButton,
    contrastButton,
    settingsButton,
  ];
  
  const { closeSidebar } = useShellContext();
  
  const isMobile = useTouchDevice();
  const isSmallScreen = useSmallScreen();
  
  if (!isMobile)
    buttons.push(focusSearchButton)
  
  if (sidebarMode === "fixed" || isSmallScreen)
    buttons.push(<button className="main-sidebar__headerIcon" onClick={closeSidebar} key="close">
      <Icon name="arrow-left" />
    </button>);

  return (
    <ColumnLayout
      unresponsive
      margin={[15, 10]}
      spacing={10}
      cols={`repeat(${buttons.length}, 1fr)`}
      className='main-sidebar__header'
      vAlign={"center" /* domyślnie baseline, ale FF 122 zepsuł */}
    >
      {buttons}
    </ColumnLayout>
  );
});

const homeButton = <Link to="/" className="main-sidebar__headerIcon" key="home">
  <Icon name="home" />
</Link>;

const languageButton = <LanguageMenu key="lang" />;

const contrastButton = <ContrastSwitch key="contrast" />;
  
function focusSearch(event: React.MouseEvent<HTMLButtonElement>) {
  event.preventDefault();
  event.currentTarget?.closest(".main-sidebar")?.querySelector("input")?.dispatchEvent(new Event("sowaForceReveal"));
}

const focusSearchButton = <button className="main-sidebar__headerIcon" onClick={focusSearch} key="search"><Icon name="search" /></button>;

function SettingsButton() {
  const [isSettingsOpen, setSettingsOpen] = useState(false);
  const onClick = () => setSettingsOpen(true);

  return <>
    {isSettingsOpen && <Settings close={() => setSettingsOpen(false)} />}
    <button className="main-sidebar__headerIcon" onClick={onClick}>
      <Icon name="paint-brush" />
    </button>
  </>
}

const settingsButton = <SettingsButton key="settings" />;
