import {
  FETCH_PREVIEW_USERS,
  PREVIEW_USERS_LOADING,
  PREVIEW_USERS_SUCCESS,
  SESSION_SUCCESS
} from "sagas/types";

import {
  put,
  call,
  select,
  take,
  takeLatest,
  takeEvery,
  all
} from "redux-saga/effects";

import * as FolksApi from "api/folks";
import * as TekaApi from "api/teka";
import * as sowaUserFunctions from "./sowaUserFunctions";

import bootstrap from "bootstrap";

export default function* usersWatcher() {
  // czekamy aż sessionSaga skończy inicjalizację
  if (!(yield select(state => state.session.session_id)))
    yield take(SESSION_SUCCESS);
  
  yield all([
    yield takeLatest(FETCH_PREVIEW_USERS, fetchPreviewUsers),
  ]);
}

function* fetchPreviewUsers({ payload: users_ids }) {
  yield put({
    type: PREVIEW_USERS_LOADING
  });

  const users = [];
  for (let i = 0; i < users_ids.length; i++) {
    yield call(handleFolksForPreview, users_ids[i], users, i);
    if (bootstrap.teka && bootstrap.teka.url) {
      yield call(handleTekaForPreview, users_ids[i], users, i);
    }
    if (users[i].folks.login) {
      yield call(handleSowaForPreview, users[i].folks.login, users, i);
    } else {
      users[i].sowa = { error: 500 };
    }
  }

  yield put({
    type: PREVIEW_USERS_SUCCESS,
    payload: users
  });
}

function* handleFolksForPreview(user_id, users, index) {
  const applyResult = result => {
    const user = { ...users[index] } || {};
    user.folks = result;
    users[index] = user;
  };

  try {
    const result = yield call(FolksApi.fetchUserFolks, user_id, {
      props: true,
      addresses: true
    });
    if (result.status === 200) {
      applyResult(result.data);
    } else {
      applyResult({ error: result.status });
    }
  } catch (error) {
    applyResult({ error: 500 });
  }
}

function* handleTekaForPreview(user_id, users, index) {
  const applyResult = result => {
    const user = { ...users[index] } || {};
    user.teka = result;
    users[index] = user;
  };

  try {
    const result = yield call(TekaApi.fetchUserTeka, user_id);
    if (result.status === 200) {
      applyResult(result.data);
    } else {
      applyResult({ error: result.status });
    }
  } catch (error) {
    applyResult({ error: 500 });
  }
}

function* handleSowaForPreview(login, users, index) {
  const sowa = {};
  for (let catalogue of bootstrap.sowa.catalogues) {
    sowa[catalogue.cat_id] = yield call(
      handleCatalogueForPreview,
      login,
      catalogue
    );
  }

  users[index].sowa = sowa;
}

function* handleCatalogueForPreview(login, catalogue) {
  try {
    const result = yield call(
      sowaUserFunctions.fetchSowaUser,
      login,
      catalogue
    );
    return result;
  } catch (error) {
    return { error: 500 };
  }
}

