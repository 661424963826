import { CapiClient, CapiCommand, capiDateFromString } from "./client";
export { SowaParamsDef as ParamsDef, SowaParamsGet as ParamsGet, SowaParamsSet as ParamsSet } from "./common";
import { emptyArray } from "../utils/constants";

const checkValue = CapiCommand.checkValue;
const checkField = CapiCommand.checkField;

export class UserDef extends CapiCommand {
  constructor() {
    super(["sowaUserDef"]);
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data.profiles, "profiles", "array");
    checkValue(data.rights, "rights", "array");

    data.profiles.forEach(profile => {
      checkValue(profile.name, "name", "string");
    });

    data.rights.forEach(right => {
      checkValue(right.right, "rights", "string");
      checkValue(right.desc, "desc", "string");
      checkValue(right.with_attrib, "with_attrib", "boolean");
      checkValue(right.choices, "choices", "array", "undefined", "null");
    });

    return data;
  }
  
  getMarkerList(client) {
    return [`sowaUserDef@${client.url}`];
  }
}

export class RecordList extends CapiCommand {
  recordIds;
  
  constructor(records = [], view = "marc21") {
    super(["sowaRecordList", [records], { view }]);
    checkValue(records, "records", "array");
    checkValue(view, "view", "string");
    this.recordIds = Array.from(new Set(records));
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data, "data", "array");

    data.forEach(record => {
      checkValue(record.rec_no, "rec_no", "string");
      checkValue(record.view, "view", "array", "object");
    });

    return data;
  }
  
  *getMarkerList(client) {
    for (const recId of this.recordIds)
      yield `record:${recId}@${client.url}`;
  }
}

export class RecordListWithChildren extends RecordList {
  *getMarkerList(client) {
    for (const recId of this.recordIds)
      yield `record:${recId}@${client.url}`;
    if (this.status === 200) {
      for (const rec of this.result.data) {
        // yield `record:${rec.rec_no}@${client.url}`;
        for (const child of rec.children || emptyArray)
          yield `record:${child.rec_no}@${client.url}`;
      }
    }
  }
}

export class ServerInfo extends CapiCommand {
  constructor() {
    super();
    this.exec = ["sowaServerInfo"];
  }

  parseData(status, data) {
    if (status !== 200) return data;

    if (typeof data["version"] !== "string")
      throw new Error("version is not a string or is undefined");

    if (data["date"] !== undefined) {
      data["date"] = new Date(Date.parse(data["date"]));
      if (data["date"] === "Invalid Date" || isNaN(data["date"]))
        throw new Error("error while parsing date date");
    } else throw new Error("date is undefined");

    if (data["time"] !== undefined) {
      data["time"] = new Date(Date.parse(data["time"]));
      if (data["time"] === "Invalid Date" || isNaN(data["time"]))
        throw new Error("error while parsing time date");
    } else throw new Error("time is undefined");

    if (data["location"] !== undefined && typeof data["location"] !== "string")
      throw new Error("location is not a string or is undefined");

    return data;
  }
}

export class CollectionCreate extends CapiCommand {
  constructor(arr_args = [], obj_args = {}) {
    super(arr_args, obj_args);

    if (!arr_args instanceof Array)
      // Sprawdzamy czy argumenty pozycyjne są listą
      throw new Error("arguments array is not an array or is undefined");

    if (!obj_args instanceof Object)
      // Sprawdzamy czy argumenty nazwane są obiektem
      throw new Error("arguments object is not a object or is undefined");

    if (typeof arr_args[0] !== "string")
      // Sprawdzamy czy rodzaj kolekcji jest stringiem
      throw new Error("collection type is not an string or is undefined");

    if (!arr_args[1] instanceof Object)
      // Sprawdzamy czy parametry zapytania są obiektem
      throw new Error("query parameters are not an object or are undefined");

    if (!obj_args["sort"] instanceof Object)
      // Sprawdzamy czy sort jest obiektem
      throw new Error("sort is not a object or is empty");

    if (!obj_args["filter"] instanceof Array)
      // Sprawdzamy czy filter jest listą
      throw new Error("filter is not an array");

    // Budujemy exec
    this.exec = ["sowaCollectionCreate", arr_args, obj_args];
  }

  parseData(status, data) {
    if (status !== 200) return data;

    if (typeof data["collection_id"] !== "number")
      throw new Error("collection_id is not a number or is undefined");

    if (typeof data["count"] !== "number")
      throw new Error("count is not a number or is undefined");

    return data;
  }
}

export class CollectionGet extends CapiCommand {
  constructor(arr_args = [], obj_args = {}) {
    super(arr_args, obj_args);

    if (!arr_args instanceof Array)
      // Sprawdzamy czy argumenty pozycyjne są listą
      throw new Error("arguments array is not an array or is undefined");

    if (!obj_args instanceof Object)
      // Sprawdzamy czy argumenty nazwane są obiektem
      throw new Error("arguments object is not a object or is undefined");

    if (typeof arr_args[0] !== "number")
      // Sprawdzamy czy id kolekcji jest numerem
      throw new Error("collection identifier is not an number or is undefined");

    if (typeof arr_args[1] !== "number")
      // Sprawdzamy czy numer pierwszego wiersza jest numerem
      throw new Error("first verse number is not an number or is undefined");

    if (typeof arr_args[2] !== "number")
      // Sprawdzamy czy oczekiwana ilość wierszy jest numerem
      throw new Error("expected verse amount is not an number or is undefined");

    if (
      obj_args["format"] !== undefined &&
      typeof obj_args["format"] !== "number"
    )
      // Sprawdzamy czy format jest numerem jeśli nie jest undefined
      throw new Error(
        "format is not a number but is defined (as " +
          typeof obj_args["format"] +
          ")"
      );

    if (
      obj_args["reverse"] !== undefined &&
      typeof obj_args["reverse"] !== "boolean"
    )
      // Sprawdzamy czy reverse jest booleanem jeśli nie jest undefined
      throw new Error(
        "reverse is not a boolean but is defined (as " +
          typeof obj_args["reverse"] +
          ")"
      );

    if (arr_args[2] < 1 || arr_args[2] > 20)
      // Sprawdzamy czy oczekiwana ilość wierszy ma wartość między 1 i 20
      throw new Error(
        "expected verse amount should be a number between 1 and 20"
      );

    // Budujemy exec
    this.exec = ["sowaCollectionGet", arr_args, obj_args];
  }

  parseData(status, data) {
    if (status !== 200) return data;

    if (typeof data["id"] !== "number")
      throw new Error("id is not a number or is undefined");

    if (typeof data["rec_type"] !== "string")
      throw new Error("rec_type is not a string or is undefined");

    if (typeof data["red_id"] !== "number")
      throw new Error("rec_id is not a number or is undefined");

    if (typeof data["view"] !== "string")
      throw new Error("view is not a string or is undefined");

    return data;
  }
}

export class UserGet extends CapiCommand {
  login;
  
  constructor(login) {
    super(["sowaUserGet", [login]]);
    checkValue(login, "login", "string");
    self.login = login;
  }

  parseData(status, data) {
    if (status !== 200) return data;

    if (typeof data["profile"] !== "string")
      throw new Error("profile is not a string or is undefined");

    if (typeof data["enabled"] !== "boolean")
      throw new Error("enabled is not a boolean or is undefined");

    if (!data["rights"] instanceof Array)
      throw new Error("rights is not an array or is undefined");

    return data;
  }
  
  getMarkerList(client) {
    return [`sowaUserGet:${login}@${client.url}`];
  }
}

export class UserSet extends CapiCommand {
  constructor(login, profile, rights) {
    super(["sowaUserSet", [login, profile, rights]]);

    if (typeof login !== "string")
      throw new Error("user login is not a string or is undefined");

    if (typeof profile !== "string")
      throw new Error("profile is not a string or is undefined");

    if (typeof rights !== "object")
      throw new Error("rights are not an array or it's undefined");
  }
}

export class UserSwitch extends CapiCommand {
  constructor(rights, store) {
    super(["sowaUserSwitch", [rights, store]]);
    
    checkValue(rights, "rights", "array");
    checkValue(store, "store", "boolean");
  }
}


export class AccountInfo extends CapiCommand {
  constructor({ user_id, summary = false, channels = false, apps = false, queries = false, basket = false, visits = false }) {
    super(["sowaAccountInfo", { user_id, summary, channels, apps, queries, basket, visits }]);  

    checkValue(user_id, "user_id", "string");
    checkValue(summary, "summary", "boolean");
    checkValue(channels, "channels", "boolean");
    checkValue(apps, "apps", "boolean");
    checkValue(queries, "queries", "boolean");
    checkValue(basket, "basket", "boolean");
    checkValue(visits, "visits", "boolean");

    this.user_id = user_id;
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data.card_no, "card_no", "string");
    checkValue(data.barcode, "barcode", "string", "undefined");
    checkValue(data.confirmed, "confirmed", "boolean", "undefined");
    checkValue(data.valid_from, "valid_from", "string", "undefined")
    checkValue(data.valid_to, "valid_to", "string", "undefined");
    checkValue(data.limit, "limit", "number", "undefined"); 
    checkValue(data.category, "category", "object", "undefined"); 

    if(data.valid_from && data.valid_to) {  
      data.valid_from = capiDateFromString(data.valid_from);
      data.valid_to = capiDateFromString(data.valid_to);
    }

    return data;
  }

  getMarkerList(client) {
    return [`accountInfo:${this.user_id}@${client.url}`];
  }
}

export class AccountSetup extends CapiCommand {
  constructor() {
    super(["sowaAccountSetup", [], {}]);
  }

  parseData(status, data) {
    if (status !== 200) return data;

    checkValue(data.initials, "initials", "object");
    checkField(data.initials, "valid_to", "string");

    checkValue(data.setup, "setup", "object");
    checkField(data.setup, "categories", "object");
    checkField(data.setup, "signup", "object");
    checkField(data.setup.signup, "limit", "number");

    data.initials.valid_to = capiDateFromString(data.initials.valid_to);

    return data;
  }
}

export class AccountSignup extends CapiCommand {
  constructor([], {user_id, category, valid_to, limit}) {
    super(["sowaAccountSignup", [], { user_id, category, valid_to, limit }]);

    checkValue(user_id, "user_id", "string");
    checkValue(category, "category", "string");
    checkValue(valid_to, "valid_to", "string");
    checkValue(limit, "limit", "number");
  }

  parseData(status, data) {
    if (status !== 200) return data;
    
    return data;
  }
}

export class AccountUpdate extends CapiCommand {
  constructor([user_id], {barcode, category, valid_to, limit, omits, codes}) {
    super(["sowaAccountUpdate", [user_id], { barcode, category, valid_to, limit, omits, codes }]);

    checkValue(user_id, "user_id", "string");
    checkValue(category, "category", "string", "undefined");
    checkValue(limit, "limit", "number", "undefined");
    checkValue(omits, "omits", "string", "undefined");
    checkValue(codes, "codes", "array", "undefined");
  }

  parseData(status, data) {
    if (status !== 200) return data;
    
    return data;
  }
}

export class RegistryView extends CapiCommand {
  agenda;
  constructor(agenda, with_details, registers, date) {
    super(["sowaRegistryView", [agenda], { date, registers, with_details }]);
    this.agenda = agenda;

    checkValue(agenda, "agenda", "string", "undefined");
    checkValue(date, "date", "number", "string", "undefined");
    checkValue(registers, "registers", "array", "undefined");
    checkValue(with_details, "with_details", "boolean")
  }
  
  parseData(status, data) {
    if (status !== 200) return data;
    
    checkValue(data.agenda, "agenda", "string");
    checkValue(data.description, "description", "string");

    data.registers.forEach(register =>
      register.forEach(() => {
        checkValue(register[0], "register", "string");
        checkValue(register[1], "count", "number")
      })
    );

    if(this.with_details) {
      data.details.forEach(detail => {
        checkValue(detail.count, "count", "number");
        checkValue(detail.id, "id", "number");
        checkValue(detail.register, "register", "string");
        checkValue(detail.date, "date", "date");
      });
    }

    return data;
  }

  getMarkerList(client) {
    return [`registryView:${this.agenda}@${client.url}`];
  }
}

export class RegistryCount extends CapiCommand {
  constructor(register, count, agenda, details) {
    super(["sowaRegistryCount", [agenda, register, count], { details }])

    checkValue(register, "register", "string");
    checkValue(count, "count", "number");
    checkValue(agenda, "agenda", "string");
    checkValue(details, "details", "string", "undefined");
  }
}


export class RegistryAlter extends CapiCommand {
  constructor(id, count, details) {
    super(["sowaRegistryAlter", [id], { count, details }])

    checkValue(id, "id", "number");
    checkValue(count, "count", "number");
    checkValue(details, "details", "object", "undefined");
  }
}

export class AccountDeactivate extends CapiCommand {
  constructor(user_id) {
    super(["sowaAccountDeactivate", [user_id], {}]);

    checkValue(user_id, "user_id", "string");
  }

  parseData(status, data) {
    if (status !== 204) return data;
    
    return data;
  }
}


export class ReportCount extends CapiCommand {
  constructor(reportName, dateFrom, dateTo, granularity, kind) {
    super(["sowaReportCount", [reportName, dateFrom, dateTo], {granularity, kind}]);
  }

  parseData(status, data) {
    if (status !== 204) return data;
    
    return data;
  }
}
