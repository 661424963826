import React from "react";
import { useSelector } from 'react-redux';
import { LogoutDialog } from "components/layout/TopBar/LogoutDialog";
import { useDialog } from "components/shared/Dialog";
import { LoginDialog } from "components/special-pages/Login/LoginDialog";
import { pages } from "utils/pages";
import { Button, Divider, Icon } from "components/shared";
import UserLabel from "components/common/UserLabel";
import { Link } from "react-router-dom";
import { tran } from "utils/language";

export function SidebarProfile() {
  const currentUser = useSelector(currentUserFolksSelector);
  
  const loginDialog = useDialog(LoginDialog);
  const logoutDialog = useDialog(LogoutDialog);

  const isUserLoggedIn = !!currentUser.folks.user_id;

  return (
    <>
      <Divider style={{ margin: 0 }} />
      {isUserLoggedIn ? (
        <div className="main-sidebar__profile">
          <Link to={pages.myProfile.fullPath} className="main-sidebar__user main-sidebar__profileBtn">
            <div className="main-sidebar__user-avatar">
              <Icon name="user" type="primary" />
            </div>
            <div className="main-sidebar__user-info">
              {currentUser.folks.user_id && (
                <p className="main-sidebar__user-name">
                  <UserLabel user={currentUser.folks} inline />
                </p>
              )}
            </div>
          </Link>
          <Button variant="icon" color="error" icon="power-off" onClick={logoutDialog.open} className="main-sidebar__profileBtn" />
        </div>
      ) : (
        <div className="main-sidebar__profile">
          <Button
            className="main-sidebar__profile-logoutButton main-sidebar__profileBtn"
            variant="text"
            iconColor="primary"
            icon="sign-in-alt"
            title={tran("unique.login")}
            onClick={loginDialog.open}
          >
            {tran("unique.login")}
          </Button>
        </div>
      )}
    </>
  );
}

const currentUserFolksSelector = (state: any) => state.currentUser;