import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';
import { useDeviceState, useMediaQuery, useSmallOrMediumScreen, useTouchDevice } from 'utils/hooks';
import { emptyArray } from "../../../utils/constants";
import { SearchContext } from "../../../utils/hooks/search";
import { Icon } from "../../shared";
import { useShellContext } from "../../shell/shellContext";
import { SidebarHeader } from './Header';
import { SidebarList } from './List';
import { SidebarProfile } from './Profile';
import { SidebarMode } from "./types";
import { useSwipeGesture } from './useSwipeGesture';

type MainSidebarProps = {
}

export const MainSidebar = ({}: MainSidebarProps) => {
  const { isSidebarOpen, setSidebarOpen, toggleSidebarOpen, sidebarMode } = useShellContext();
  
  const isMobile = useTouchDevice();
  
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    //console.log(isSidebarOpen, sidebarRef.current);
    if (sidebarRef.current && isSidebarOpen) {
      // musimy poczekać aż skończy się animacja na atrybucie visibility
      // bo wcześniej element nie jest focusowalny
      setTimeout(focusFilter, 110, sidebarRef.current);
    }
  }, [isSidebarOpen]);
  
  useEffect(() => {
    function handler(event: KeyboardEvent) {
      if (event.key === "F2") {
        toggleSidebarOpen();
        event.preventDefault();
      }
    }
    
    window.addEventListener("keydown", handler);
    return () => window.removeEventListener("keydown", handler);
  }, emptyArray);

  const sidebarClass = `main-sidebar left ${isSidebarOpen ? "open" : "closed"} ${sidebarMode} ${isMobile ? "touch" : "mouse"}`;
  
  //console.log("SB", isSidebarOpen, sidebarMode, isMobile);
  
  useEffect(() => {
    if (sidebarMode === "fixed" || !isSidebarOpen)
      return;
    
    function onOutsideClick(ev: MouseEvent) {
      const t = ev.target as Element | null;
      if (t && t.closest(".global-container"))
        setSidebarOpen(false);
    }
    
    document.addEventListener("click", onOutsideClick, { capture: true });
    return () => document.removeEventListener("click", onOutsideClick, { capture: true });
  }, [sidebarMode, isSidebarOpen]);
  
  return (
    <>
      {sidebarMode !== "fixed" && <div
        className={`main-sidebar__overlay ${isSidebarOpen ? "main-sidebar__overlay--active" : ""}`}
        onClick={toggleSidebarOpen}
      />}
      <nav
        ref={sidebarRef}
        className={sidebarClass}
        aria-hidden={!isSidebarOpen}
        onClickCapture={onClick} 
      >
        <div className="main-sidebar__frame">
          <SidebarContent sidebarMode={sidebarMode} />
        </div>
        <SidebarCloseAfterNavigation sidebarMode={sidebarMode} />
      </nav>
    </>
  );
  
  function onClick(event: React.MouseEvent<HTMLElement>) {
    if (!isSidebarOpen) {
      setSidebarOpen(true);
      event.preventDefault();
      event.stopPropagation();
    }
    
    focusFilter(event.currentTarget);
  }
}

function focusFilter(target: Element | null) {
  (target?.querySelector("input.main-sidebar__filter") as HTMLElement | null)?.focus();
}


const SidebarToggle = React.memo(({ isSidebarOpen, toggleSidebarOpen }: { isSidebarOpen: boolean, toggleSidebarOpen: () => void }) => {
  return (
    <button className='main-sidebar__toggle' onClick={toggleSidebarOpen}>
      <Icon name={isSidebarOpen ? "chevron-left" : "chevron-right"} />
    </button>
  );
});

const SidebarContent = React.memo(({ sidebarMode }: { sidebarMode: SidebarMode }) => {
  return (
    <div className="main-sidebar__content">
      <SidebarHeader
        sidebarMode={sidebarMode} />
      <SearchContext prop="filter" phrase="">
        <SidebarList />
      </SearchContext>
      <SidebarProfile />
    </div>
  );
});

function SidebarCloseAfterNavigation({ sidebarMode }: { sidebarMode: SidebarMode }) {
  const isMobile = useTouchDevice();
  const location = useLocation();
  const predicateRef = useRef(isMobile && sidebarMode === "floating");
  const { closeSidebar } = useShellContext();
  
  useEffect(() => {
    predicateRef.current = isMobile && sidebarMode === "floating";
  }, [sidebarMode, isMobile]);
  
  useEffect(() => {
    if (predicateRef.current)
      closeSidebar();
  }, [location.pathname]);
  
  return null;
}
