import React, { KeyboardEventHandler } from "react";
import { Button } from "..";
import Icon from "../Icon";
import { IDialog } from "./useDialog";
import { ToastSessionClock } from "../../layout/TopBar/SessionClock";

export type DialogProps = {
  title?: React.ReactNode
  icon?: string
  size?: "small" | "medium" | "large"
  children?: React.ReactNode
  buttons?: readonly React.ReactElement[] | React.ReactFragment | null
  type?: "standard" | "simple" | "header" | "branded" | "error" | "question" | "fullscreen" | "fullscreen-unpadded" | "epr" | "full-title" | "message"
  scrollable?: boolean
  style?: React.CSSProperties
  className?: string
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
  dialog: IDialog
};

const Dialog = ({ title, icon, size = "small", children, buttons, type = "standard", style, className, onKeyDown, dialog, scrollable = true }: DialogProps) => {
  // Jeśli chcemy, żeby dialog nie wyswietlał żadnych przycisków (nawet domyślnego "Zamknij"), 
  // bo np. nasz komponent sam to robi, to przekazujemy `buttons={null}`.
  // Dzięki temu nie wyświetli się też pusty div bez przycisków z paddingami zajmującymi miejsce.
  let hasButtons = false;
  let buttonElems: DialogProps["buttons"];

  if(buttons !== null) {
    hasButtons = true;

    const hasActions = buttons && (Array.isArray(buttons) ? buttons.length > 0 : true);
    buttonElems = hasActions ? buttons : <Button preset="close" onClick={dialog.close} />;  
  }
  
  if (typeof dialog !== "object" || typeof dialog.close !== "function")
    // cokolwiek to jest, to na pewno nie IDialog
    throw new Error("Dialog passed invalid `dialog` object.");

  const iconElem = icon && <Icon name={icon} />;

  const withHeader = hasButtons && (icon || title)
    ? "with-header"
    : "headerless";
  
  let typeClass;
  if (type === "fullscreen-unpadded")
    typeClass = "dialog--fullscreen dialog--unpadded";
  else
    typeClass = `dialog--${type}`;
  
  return <>
    <div 
      className={`dialog ${typeClass} dialog--${size} dialog--${withHeader} ${className || ""}`} 
      style={style}
      role="dialog"
      onKeyDown={onKeyDown}
      tabIndex={0}
    >
      {(icon || title) && (
        <div className="dialog__header">
          <h3>{iconElem} {title}</h3>
        </div>
      )}

      {children && (
        <div className={`dialog__content ${scrollable ? "" : "dialog__content--noscroll"}  ${!hasButtons ? "dialog__content--full-height" : ""}`}>
          {children}
        </div>
      )}

      {hasButtons &&
        <div className="dialog__actions">
          <Button.Group align="center">
            {buttonElems}
          </Button.Group>
        </div>
      }
      
      {type.startsWith("fullscreen") && <ToastSessionClock/>}
    </div>
  </>;
};

export default Dialog;
