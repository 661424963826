import React, { useContext, useEffect, useRef, useState } from "react";
import { signalRegistry, SignalRegistry } from "../signalRegistry";
import { BetterContextProvider } from "../context";
import { doNothing, identity, isTrue, tuple } from "../constants";
import { escapeRegExp } from "../globalFunctions";
import { useSignal } from "./other";
import { useForkedState } from "./derived";

// TODO: wsparcie dla zawijania (WrapText), ale to będzie ciężki temat

interface SearchContextPriv {
  prop: string
  phrase: string
  className: string
  highlight: (input: string) => React.ReactNode
  highlighter: SearchHighlighter
  signals?: SignalRegistry
  setPropAndPhrase: React.Dispatch<readonly [string, string]>
  setPhrase: (phrase?: string) => void
}

export type SearchHighlighter = (phrase: string, className: string) => (input: string) => React.ReactNode;

const noHighlighter: SearchHighlighter = () => identity;
const searchContext = React.createContext<SearchContextPriv>(Object.freeze({
  prop: "",
  phrase: "",
  className: "",
  highlight: identity,
  highlighter: noHighlighter,
  signals: undefined,
  setPropAndPhrase: doNothing,
  setPhrase: doNothing,
}));

////////////////////////////////////////////////////////////////////////////////

type SearchContextProps = {
  /** Własność, która jest podświetlana */
  prop?: string
  
  /** Fraza, która jest podświetlana */
  phrase?: string
  
  /** Klasa, jaką nadawać podświetlonym wystąpieniom */
  className?: string
  
  /** Funkcja szukająca wystąpień w podanym tekście */
  highlighter?: SearchHighlighter
  
  children?: React.ReactNode
}

/** Komponent zapisujący stan wyszukiwania w kontekście na potrzeby podświetlania */
export function SearchContext(props: SearchContextProps) {
  const [[dynProp, dynPhrase], setPropAndPhrase] = useForkedState(tuple, props.prop || "", props.phrase || "");
  
  const ref = useRef<SearchContextPriv | null>(null);
  if (ref.current === null) {
    const ctx: SearchContextPriv = {
      prop: dynProp,
      phrase: dynPhrase,
      className: props.className || defaultClassName,
      highlighter: props.highlighter || defaultSearchHighlighter,
      highlight: identity,
      signals: signalRegistry(),
      setPropAndPhrase: (next => setPropAndPhrase(prev => prev[0] === next[0] && prev[1] === next[1] ? prev : next)),
      setPhrase: (phrase) => setPropAndPhrase(prev => prev[1] === (phrase || "") ? prev : [prev[0], phrase || ""])
    };
    
    if (ctx.prop && ctx.phrase)
      ctx.highlight = ctx.highlighter(ctx.phrase, ctx.className);
    
    ref.current = ctx;
  }
  
  const ctx = ref.current;
  
  useEffect(() => {
    const { prop, phrase, className, highlighter } = ctx;
    ctx.prop = dynProp;
    ctx.phrase = dynPhrase;
    ctx.className = props.className || defaultClassName;
    ctx.highlighter = props.highlighter || defaultSearchHighlighter;
    
    if (ctx.phrase !== phrase || ctx.className !== className || ctx.highlighter !== highlighter) {
      const newHighlight = ctx.prop && ctx.phrase ? ctx.highlighter(ctx.phrase, ctx.className) : identity; 
      if (ctx.highlight !== newHighlight) {
        ctx.highlight = newHighlight;
      }
    }
    
    // budzimy aktualnie podświetlanego propsa
    const wakes = ["__all__", ctx.prop];
    
    if (ctx.prop !== prop) {
      // budzimy poprzednio podświetlanego propsa, żeby usunąć podświetlenie
      wakes.push(prop);
    }
    
    ctx.signals!.signal(wakes);
  }, [dynProp, dynPhrase, props.className, props.highlighter]);
  
  return <BetterContextProvider context={searchContext} value={ref.current} children={props.children} />;
}

////////////////////////////////////////////////////////////////////////////////

/** Zwraca funkcję podświetlającą tekst dla podanego propsa */
export function useSearchHighlight(prop?: string): (input: string) => React.ReactNode {
  prop = prop || "__all__";
  
  const ctx = useContext(searchContext);
  const ref = useRef<((input: string) => React.ReactNode) | null>(null);
  const wakeUp = useSignal();
  
  const highlightActive = prop === "__all__" || ctx.prop === prop;
  const current = highlightActive ? ctx.highlight : identity;
  
  if (ref.current === null) {
    ref.current = current;
  }
  
  // pamiętamy ostatnio zwróconą wartość dla kompat. z concurrent mode dopiero w useEffect
  useEffect(() => {
    ref.current = current;
  }, [current]);
  
  useEffect(() => {
    const signals = ctx.signals;
    if (signals) {
      function filteredSignal() {
        const last = ref.current!;
        const highlightActive = prop === "__all__" || ctx.prop === prop;
        const current = highlightActive ? ctx.highlight : identity;
        if (current !== last)
          wakeUp();
      }
      
      signals.register(prop || "__all__", filteredSignal);
      return () => {
        signals.unregister(filteredSignal);
      }
    }
  }, [prop, ctx]);
  
  return current;
}

////////////////////////////////////////////////////////////////////////////////

export function useUpdateSearch(prop?: string, phrase?: string): void {
  const set = useSearchUpdater();
  
  useEffect(() => {
    set([prop || "", phrase || ""]);
  }, [set, prop, phrase]);
}

export function useSearchUpdater() {
  return useContext(searchContext).setPropAndPhrase;
}

export function useSearchPhraseAsState(prop?: string): readonly [string, (phrase?: string) => void] {
  prop = prop || "__all__";
  const ctx = useContext(searchContext);
  
  // TODO: opt
  useSearchHighlight(prop);
  
  return [prop === "__all__" || prop === ctx.prop ? ctx.phrase : "", ctx.setPhrase];
}

////////////////////////////////////////////////////////////////////////////////

/** Zwraca aktualnie przeszukiwanego propsa */
export function useSearchProp(): string {
  const ctx = useContext(searchContext);
  const wakeUp = useSignal();
  
  useEffect(() => {
    const signals = ctx.signals;
    if (signals) {
      signals.register("__all__", wakeUp);
      return () => {
        signals.unregister(wakeUp);
      }
    }
  }, [ctx]);
  
  return ctx.prop;
}

////////////////////////////////////////////////////////////////////////////////

/** Zwraca czy jest jakieś zapytanie ustawione */
export function useSearchIsActive(): boolean {
  const ctx = useContext(searchContext);
  const [isActive, setIsActive] = useState(false);
  
  useEffect(() => {
    const signals = ctx.signals;
    if (signals) {
      function onSignal() {
        setIsActive(!!ctx.phrase);
      }
      signals.register("__all__", onSignal);
      return () => {
        signals.unregister(onSignal);
      }
    }
  }, [ctx]);
  
  return isActive;
}

////////////////////////////////////////////////////////////////////////////////

export type HLProps = {
  /** Prop, który wyświetlamy i chcemy podświetlić, lub `undefined | "__all__"` jeśli chcemy podświetlić niezależnie od wybranego propsa */
  prop?: string
  
  /** Wartość podświetlana */
  value: string
  
  className?: string
}

/** Kliencki komponent podświetlający, podświetli swoją wartość jeśli otaczający SearchContext tego zażąda */
export const HL = React.memo(function HL(props: HLProps) {
  const highlight = useSearchHighlight(props.prop || "__all__");
  const result = highlight(props.value) as any;
  if (props.className) {
    if (typeof result === "object")
      return React.cloneElement(result, { className: props.className });
    else
      return <span className={props.className}>{result}</span>
  }
  return result;
});

/** Kliencki komponent podświetlający, podświetli swoją wartość jeśli otaczający SearchContext tego zażąda,
 *  nie wyświetla niepodświetlonej wartości (zwraca null) */
export const OnlyHL = React.memo(function OnlyHL(props: HLProps) {
  const highlight = useSearchHighlight(props.prop || "__all__");
  const result = highlight(props.value) as any;
  if (typeof result === "object") {
    if (props.className)
      return React.cloneElement(result, { className: props.className });
    return result;
  }
  return null;
});

////////////////////////////////////////////////////////////////////////////////

/** Dzieli frazę na słowa, szuka słów w dowolnym miejscu w tekście (równie wewnątrz słów) */
export function wordSubstringHighlighter(phrase: string, className: string): ReturnType<SearchHighlighter> {
  const words = phrase.split(/\s+/).filter(isTrue);
  if (!words.length)
    return identity;
  const regex = new RegExp(`(${words.map(escapeRegExp).join("|")})`, "iu");
  
  return function wordSubstringHighlighter(input: string, className?: string) {
    if (!input) return input;
    const parts = input.split(regex);
    return <span>{parts.map(highlightPart)}</span>;
  }
  
  function highlightPart(part: string, i: number) {
    // części parzyste to zawsze będzie tekst pomiędzy wzorcami
    if (i % 2 == 0)
      return part;
    // a nieparzyste to zawsze złapany wzorzec (gdy jest jedna para nawiasów w regexie)
    else
      return <mark key={i} className={className}>{part}</mark>;
  }
}

////////////////////////////////////////////////////////////////////////////////

export function prefixHighlighter(phrase: string, className: string): ReturnType<SearchHighlighter> {
  if (!phrase.length)
    return identity;

  const escapedPhrase = escapeRegExp(phrase);
  const regex = new RegExp(`^${escapedPhrase}`, "iu");

  function highlightPart(part: string, i: number) {
    if (i % 2 === 0) {
      return part;
    } else {
      return <mark key={i} className={className}>{part}</mark>;
    }
  }

  return function prefixHighlighter(input: string, className?: string) {
    if (!input) return input;

    const match = input.match(regex);
    if (!match) return input;

    const matchedPart = match[0];
    const restOfInput = input.slice(matchedPart.length);

    return (
      <span>
        {highlightPart(matchedPart, 1)}
        {restOfInput}
      </span>
    );
  }
}

const defaultClassName = "hl";
const defaultSearchHighlighter = wordSubstringHighlighter;
