import bootstrap from "bootstrap";

import { CapiCommand, capiDateToString, sessionAuth } from "capi/client";

const stringify = JSON.stringify;

function mapFrame(frame) {
  let file = frame.fileName;
  if (typeof file === "string") {
    const o = window.location.origin;
    if (o && file.startsWith(o))
      file = file.substring(o.length);
    
    const m = /^\/v\/(.*?)\/(.*)/.exec(file);
    if (m)
      file = file[2];
  }
  return {
    fn: frame.functionName,
    in: file,
    at: frame.lineNumber || undefined,
    co: frame.columnNumber || undefined
  };
}

function memoTraceback(error) {
  // asynchronicznie dekodujemy stos (bo może to wymagać dogrania sourcemap) i tłumaczymy na nasz format;
  // pamiętamy obietnicę w obiekcie wyjątku, żeby robić to tylko raz, a za kolejnym razem z wykonanej
  // obietnicy możemy przeczytać gotowy wynik
  if (typeof error._tracebackPromise === "undefined")
    error._tracebackPromise =
      import(/* webpackChunkName: "stjs" */ "stacktrace-js")
      .then(stacktraceJs => stacktraceJs.fromError(error))
      .then(frames => frames.map(mapFrame));

  return error._tracebackPromise;
}

export async function reportError(error, noConsole = false) {
  const timestamp = capiDateToString();

  noConsole || console._error(error);

  if (error.dontReport) return;
  
  try {
    error.dontReport = true;
  }
  catch (e) {}

  // importujemy store dynamicznie, żeby inicjowało się po tym module
  const module = await import("store");

  const store = module.default;
  const state = store.getState();
  const session = state.session;
  let auth;
  if (session) {
    auth = sessionAuth(session.session_id, session.session_key);
  }

  const client = bootstrap.folks.client.copyWith({ log: undefined });
  
  const traceback = await memoTraceback(error);

  const log = getLog();

  for (let i = 0, L = log.length; i < L; i++) {
    let message = log[i];
    let err = message.error;
    if (err === error) {
      // wywalamy z loga ten sam wyjątek, który zgłaszamy, żeby niepotrzebnie nie potwarzać 2x tej samej informacji
      log[i] = { ms: "<this error>", lv: message.lv, ts: message.ts };
    } else if (err) {
      // tworzymy wiadomość w formie do wysłania z JSONowym wyjątkiem zamiast natywnego
      let tb = await memoTraceback(err);
      log[i] = {
        ms: message.ms,
        lv: message.lv,
        ts: message.ts,
        ex: { cl: err.name, ms: err.message, tb }
      };
    }
  }

  const metadata = {
    navigator: {
      agent: window.navigator.userAgent.toString(),
      "cookies?": window.navigator.cookieEnabled,
      lang: window.navigator.language
    },
    cookies: document.cookie,
    url: window.location.href,
    store: state,
    // window.bootstrap a nie bootstrap, żeby logować konfigurację podaną,
    // bez elementów dodanych przez nas (które niekoniecznie są reprezentowalne JSONowo)
    bootstrap: window.bootstrap,
  };

  await client.execute(
    new ErrorReport(`${error.name}: ${error.message}`, timestamp, {
      app: "websowa",
      release: process.env.WS_VER,
      version: `${process.env.WS_VER}.${process.env.WS_BLD}`, 
      traceback,
      log,
      metadata,
      auth
    })
  );
}

CapiCommand.reportError = reportError;

window.onerror = function(msg, file, line, col, error) {
  error && reportError(error, true)
};

// używamy tego w index.html, bo nie umiałem w webpacku skonfigurować wykonania
// kodu przed włączeniem react-error-overlay
window.onWebsowaUnhandledRejection = function onWebsowaUnhandledRejection(event) {
  // zapobiegamy wyświetlaniu komunikatu błędu w trybie deweloperskim
  if (event.reason && (event.reason._softReported || event.reason.dontReport)) {
    event.stopImmediatePropagation();
  }
};

const RING_SIZE = 25;
const ring = [];
const MAX_STRING_LEN = 64;
const MAX_ARRAY_LEN = 512;
const MAX_OBJECT_SIZE = 1024;
let ringPtr = 0;

function logToRing(level, args) {
  const message = { ts: capiDateToString(), lv: level, ms: null };

  if (ring.length < RING_SIZE) {
    ring.push(message);
    ringPtr = ring.length;
  } else {
    ringPtr = ringPtr % ring.length;
    ring[ringPtr] = message;
    ringPtr += 1;
  }

  const parts = [];
  const L = args.length;

  for (let i = 0; i < L; i++) {
    const arg = args[i];
    switch (typeof arg) {
      case "undefined":
        parts.push("<undefined>");
        break;
      case "object":
        if (arg === null) {
          parts.push("<null>");
        }
        else if (arg instanceof Error) {
          message.error = arg; // formatujemy potem, przed wysłaniem
          parts.push("<error>");
        } else {
          let json;
          
          try {
            json = elementJson(arg);
            
            if (json.length > MAX_ARRAY_LEN && Array.isArray(arg)) {
              json = elementJson([arg[0], `<${arg.length - 1} more …>`]);
            }
            
            if (json.length > MAX_OBJECT_SIZE) {
              json = "<big object>";
            }
          }
          catch (err) {
            json = "<unrepresentable object>";
          }

          parts.push(json);
        }
        break;
      case "string":
        const max_len = i === 0 ? 4 * MAX_STRING_LEN : MAX_STRING_LEN;
        if (arg.length > max_len) parts.push(arg.slice(0, max_len - 1) + "…");
        else parts.push(arg);
        break;
      default:
        parts.push(arg);
    }
  }

  message.ms = parts.join(" ");
}

function getLog() {
  if (ring.length < RING_SIZE) return ring.slice();
  else {
    const result = ring.slice(ringPtr, RING_SIZE);
    result.push(...ring.slice(0, ringPtr));
    return result;
  }
}

/** Funkcja skopiowana z IPUBa, która dumpuje info o elemencie DOMowym. */
function elementJson(element)
{
  if (element === null)           return "<null>";
  else if (element === undefined) return "<undefined>";
  else if (! element.tagName)     return stringify(element, undefined, 2);

  let text = element.id ? ("#" + element.id) : "";
  if (! text)
  {
    let cls = element.className || "";
    if (typeof cls.baseVal !== "undefined") cls = cls.baseVal;
    cls = cls.toString ().replace (/\s+/g, ".").replace(/^\.+|\.+$/, "");
    text += "." + cls;
  }

  return element.tagName.toLowerCase() + text;
}

export class ErrorReport extends CapiCommand {
  constructor(message, timestamp, opts) {
    super(["errorReport", [message, timestamp], opts]);
  }
}

function interceptConsole(fn, level) {
  const console = window.console;
  const original = console[fn];
  const apply = Function.prototype.apply;

  console[fn] = function() {
    const args = Array.prototype.slice.apply(arguments);
    apply.apply(original, [console, args]);
    logToRing(level, args);
  };

  console[`_${fn}`] = original;
}

if (process.env.NODE_ENV !== "test") {
  interceptConsole("log", "D");
  interceptConsole("warn", "W");
  interceptConsole("error", "E");
  interceptConsole("exception", "E");
  interceptConsole("info", "I");
  interceptConsole("debug", "T");
}

export function makeRichError(props) {
  let msg = props.message;
  if (props.id)
    msg = window?.__ws_translate?.(props.id) || props.id;
  const e = new Error(msg);
  e.enduserMessage = msg;
  e.__ws_richError = props;
  return e;
}

export function throwRichError(props) {
  throw makeRichError(props);
}
