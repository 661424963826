import bootstrap from "../bootstrap";
import { doNothing, emptyArray, emptyObject } from "./constants";
import {
  finishImmediateBatchUpdates,
  scheduleImmediateReactUpdate,
  scheduleReactUpdate,
  startImmediateBatchUpdates
} from "./hooks/batch";
import { useSignal } from "./hooks/other";
import { useEffect } from "react";

// Tworzymy rejestr:    const sr = signalRegistry<string, void>();
// Rejestrujemy sygnał: const signal = () => console.log("foo!")
//                      sr.register("foo", signal);
// Emitujemy sygnał:    sr.signal(["foo"]); // signal() zostaje wywołany
// Sprzątamy:           sr.unregister(signal);

// Pamiętamy: tożsamość przekazywanej funkcji jest kluczowa, jak jej nie zachowamy to nie będzie jak posprzątać 

type Signal<K> = (keys: readonly K[], ...args: any[]) => void

export interface SignalRegistry<K = string> {
  signal(keys: Iterable<K>, ...args: any[]): void
  register(key: K, signal: Signal<K>): void
  unregister(signal: Signal<K>): void
  hasSignalsFor(key: K): boolean
  useWithKeys(...keys: K[]): void
}

type SignalRegistryOptions = {
  delay?: boolean
}

export function signalRegistry<K = string>(options: SignalRegistryOptions = emptyObject): SignalRegistry<K> {
  const storage = new Map<K, Set<Signal<K>>>();
  const delay = options.delay;
  const startUpdates = delay ? doNothing : startImmediateBatchUpdates;
  const finishUpdates = delay ? doNothing : finishImmediateBatchUpdates;
  // ło jezu ile to typowania by tu było
  const scheduleUpdate: (fn: any, arg: any) => void =
    delay ? scheduleReactUpdate : scheduleImmediateReactUpdate;
  
  return Object.freeze({
    signal(keys: Iterable<K>, ...args: any[]) {
      const notify = new Set<Signal<K>>();
      const dedup: K[] = [];
      
      for (const key of keys) {
        const signals = storage.get(key);
        if (signals) {
          dedup.push(key);
          for (const signal of signals)
            notify.add(signal);
        }
      }
      
      if (notify.size > 0) {
        devel && console.log("Signalling:", dedup);
        startUpdates();
        for (const signal of notify)
          scheduleUpdate(signalWrapper, [signal, dedup, args]);
        finishUpdates();
      }
    },
    register(key: K, signal: Signal<K>) {
      let set = storage.get(key);
      if (!set) {
        set = new Set();
        storage.set(key, set);
      }
      set.add(signal);
    },
    unregister(signal: Signal<K>) {
      // iterujemy całą mapę, można zoptymizować robiąc mapę odwrotną
      for (const [key, set] of storage.entries()) {
        set.delete(signal);
        if (set.size < 1)
          storage.delete(key);
      }
    },
    hasSignalsFor(key: K) {
      return storage.has(key);
    },
    useWithKeys(...keys: K[]) {
      const self = this;
      const signal = useSignal();
      useEffect(() => {
        for (const key of keys)
          self.register(key, signal);
        return () => self.unregister(signal);
      }, [self, keys.join("\0")]);
    }
  })
}

function signalWrapper<K>(args: readonly [Signal<K>, readonly K[], any[]]) {
  const [fn, keys, other] = args;
  return fn(keys, other);
}

const devel = bootstrap.devel;
