import React, {useContext, useRef} from "react";

import { reportError, WSError, type RichErrorProps } from "utils/errorReporting";
import {translate} from "utils/language";

import {Blankholder} from ".";

import {doNothing, emptyObject} from "utils/constants";
import { RichErrorDialog } from "./Dialog/comps/RichErrorDialog";
import useDialog, {IDialog} from "./Dialog/useDialog";

export type ErrorExtraInfo = {
  title?: string
} 

export type ErrorBoundaryApi = {
  installed: boolean
  reportError(error: any, extra?: ErrorExtraInfo): void
  softReportError(error: any, extra?: ErrorExtraInfo): void | never
  clearError(): void
}

const ErrorBoundaryContext = React.createContext({ installed: false, reportError: doNothing, softReportError: doNothing, clearError: doNothing } as ErrorBoundaryApi);

const DEFAULT_MESSAGE = translate("error.generic") as string;

type Props = {
  fallback?: React.ReactNode | ((message: string) => React.ReactNode)
  resetKey?: any
}

type State = {
  thrownError: string
}

export default class ErrorBoundary extends React.Component<Props, State> {
  state: State = {
    thrownError: "",
  };
  
  static getDerivedStateFromError(error: any) {
    // Twarde błędy, to takie które React złapie jako wyjątki podczas renderowania
    // obsługa ich polega na odmontowaniu całego poddrzewa komponentów i zastąpieniu go komunikatem
    //
    if (error.enduserMessage)
      return { thrownError: error.enduserMessage };
    else
      return { thrownError: DEFAULT_MESSAGE };
  }
  
  componentDidUpdate(prevProps: Props) {
    const props = this.props;
    if (prevProps.resetKey !== props.resetKey)
      this.setState({ thrownError: "" });
  }
  
  clearError = () => this.setState({ thrownError: "" });
  
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    // TODO: errorInfo.componentStack
    void reportError(error)
  }
  
  render() {
    let content;
    
    if (this.state.thrownError) {
      if (typeof this.props.fallback === "function")
        content = this.props.fallback(this.state.thrownError);
      else if (this.props.fallback)
        content = this.props.fallback;
      else
        content = <Blankholder icon="exclamation-circle" description={this.state.thrownError}/>
    }
    else
      content = this.props.children;

    return <ErrorBoundary.Inner clearError={this.clearError}>
        {content}
    </ErrorBoundary.Inner>
  }
  
  static useApi(): ErrorBoundaryApi {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useContext(ErrorBoundaryContext);
  }
  
  /** Komponent wewnętrznej granicy błędów, który udostępnia API do wyświetlania błędów przez okna dialogowe. */
  static Inner({ clearError, children }: { clearError: () => void, children: any }) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const errorDialog = useDialog(RichErrorDialog);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const api = useRef(null as any as ErrorBoundaryApi);
    if (api.current === null) {
      api.current = {
        installed: true,
        reportError(error: any, extra: ErrorExtraInfo = emptyObject) {
          if (error)
            errorDialog.openWith(getErrorProps(error));
          
          if (error instanceof Error) {
            void reportError(error);
          }
        },
        softReportError(error: any, extra: ErrorExtraInfo = emptyObject) {
          if (error && !error._softReported)
            errorDialog.openWith(getErrorProps(error));
          
          if (error instanceof Error) {
            // @ts-ignore
            error._softReported = true;
            throw error;
          }
        },
        clearError
      }
    }
    return <ErrorBoundaryContext.Provider value={api.current}>
      {children}
    </ErrorBoundaryContext.Provider>
  }
}

export function getErrorProps(error: any): Readonly<RichErrorProps> {
  if (!error)
    return defaultError;
  
  if (typeof error === "string")
    return {
      message: error,
      nature: "appError",
    }
  
  if (error instanceof Error) {
    const wsError = error as WSError;
    if (wsError.__ws_richError)
      return wsError.__ws_richError;
    else {
      const message = wsError.enduserMessage || wsError.message;
      return {
        message: message,
        nature: "appError",
      }
    }
  }
  
  return defaultError;
}

const defaultError = Object.freeze({
  id: "error.generic",
  nature: "appError",
});