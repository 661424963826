import {Dispatch, SetStateAction, useRef, useState} from "react";

import lodashIsEqual from "lodash/isEqual";

import {identity, increment} from "../constants"
import {areHookInputsEqual} from "./util";
import {UnboundedTuple} from "../types";

export type Fork<T> = Dispatch<SetStateAction<T>>

export type Unfork = () => void

export type ForkedState<T> = [T, Fork<T>, Unfork, unknown, unknown, unknown];

const slice = Array.prototype.slice;

/** Zmienna stanu na podstawie innej:
 *   - można ustawić wartość ręcznie, ale tymczasowo
 *   - zawsze jak depsy się zmienią to obliczamy na nowo ze źródła
 *  
 *  Wspiera po cichu trzecią sygnaturę gdzie `derivation` jest pominięte, ale nie z typescriptu,
 *  gdzie należy wywołać `useForkedState(identity, ...)` (jej obecność powodowała głupie komunikaty błędów).
 */
export function useForkedState<T extends any[], R>(derivation: (...args: T) => R, ...args: T): ForkedState<R>;
export function useForkedState<T extends any[], R>(derivation: (...args: T) => R, ...args: UnboundedTuple<T>): ForkedState<R>;
export function useForkedState<T extends any[], R>(derivation: (...args: T) => R /* + arguments (w ramach opt) */): ForkedState<R> {
  let argStart = 1;
  if (typeof derivation !== "function") {
    argStart = 0; // pierwszy argument nie jest funkcją, więc jest stanem do sforkowania
    derivation = identity as any;
  }
  
  type State = [
    R,                                // 0 aktualna wartość stanu
    Fork<R>,                          // 1 stały callback dla forkState
    Unfork,                           // 2 stały callback dla unforkState
    Dispatch<SetStateAction<number>>, // 3 signal
    (...args: T) => R,                // 4 derivation
    T                                 // 5 deps / args
  ];
  
  const ref = useRef(null as unknown as State); // typscript nie musi się przejmować, że dopuszczamy null w tym miejscu
  const [_, signal] = useState(0); //TODO: useSignal
  
  if (ref.current === null) {
    const forkState = function(action: SetStateAction<R>): void {
      const [value,,, signal] = ref.current;
      if (typeof action === "function")
        ref.current[0] = (action as (prevState: R) => R)(value);
      else
        ref.current[0] = action;
      signal(increment);
    };
    
    const unforkState = function() {
      const [,,,, derivation, args] = ref.current;
      ref.current[0] = derivation.apply(null, args);
      signal(increment);
    };
    
    const args = slice.call(arguments, argStart) as T;
    
    ref.current = [
      derivation.apply(null, args),
      forkState,
      unforkState,
      signal,
      derivation,
      args
    ];
  
    return ref.current;
  }

  ref.current[4] = derivation;
  
  let [value,,,,, oldArgs] = ref.current;
  
  let equal = arguments.length === (oldArgs.length + argStart);
  if (equal) {
    const is = Object.is;
    for (let i = argStart; i < arguments.length; i++) {
      if (!is(oldArgs[i - argStart], arguments[i])) {
        equal = false;
        break;
      }
    }
  }
  
  if (!equal) {
    for (let i = argStart; i < arguments.length; i++)
      oldArgs[i - argStart] = arguments[i];
    oldArgs.length = arguments.length - argStart;
    
    ref.current[0] = value = derivation.apply(null, oldArgs);
  }
  
  return ref.current;
}

/** Prostsza zmienna stanu na podstawie innej:
 *   - zawsze jak depsy się zmienią to obliczamy na nowo ze źródła
 */
export function useDerivedState<T extends any[], R>(derivation: (...args: T) => R, ...args: T): R;
export function useDerivedState<T extends any[], R>(derivation: (...args: T) => R, ...args: UnboundedTuple<T>): R;
export function useDerivedState<T extends any[], R>(derivation: (...args: T) => R /* + arguments (w ramach opt) */): R {
  if (typeof derivation !== "function") {
    throw new Error(`useDerivedState expects a function, not: ${typeof derivation}`)
  }
  
  type State = [
    (...args: T) => R,
    T,
    R
  ];
  
  const ref = useRef(null as any as State);
  
  if (ref.current === null) {
    const args = slice.call(arguments, 1) as T;
    
    ref.current = [
      derivation,
      args,
      derivation.apply(null, args)
    ];
    
    return ref.current[2];
  }
  
  let [_, oldDeps, value] = ref.current;
  
  ref.current[0] = derivation;
  
  let equal = arguments.length === (oldDeps.length + 1);
  if (equal) {
    const is = (derivation as any).comparison ?? Object.is;
    for (let i = 1; i < arguments.length; i++) {
      if (!is(oldDeps[i - 1], arguments[i])) {
        equal = false;
        break;
      }
    }
  }
  
  if (!equal) {
    for (let i = 1; i < arguments.length; i++)
      oldDeps[i - 1] = arguments[i];
    oldDeps.length = arguments.length - 1;
    
    ref.current[2] = value = derivation.apply(null, oldDeps);
  }
  
  return value;
}

/** Porównuje aktualnie podany obiekt z poprzednim i jeśli wszystkie pola mają te same wartości,
 *  to zwraca stary obiekt zamiast nowego.
 *  UWAGA: porównuje płytko. */
export function useDedup<T>(input: T): T
export function useDedup(input: any): any {
  const ref = useRef(undefined as any);
  const last = ref.current;
  
  if (Object.is(last, input))
    return last;
  
  loop: do { // pętla żeby break działał jak goto :P
    if (typeof last !== typeof input) break;
    
    if (typeof input !== "object") break;
    
    if (last === null || input === null) break;
    
    const is1 = Array.isArray(last),
          is2 = Array.isArray(input);
    
    if (is1 !== is2) break;
    
    if (is1) {
      if (areHookInputsEqual(last, input))
        return last;
    }
    else {
      for (let k in last) {
        if (last.hasOwnProperty(k)) {
          if (!input.hasOwnProperty(k)) break loop;
          if (!Object.is(last[k], input[k])) break loop;
        }
      }
      
      for (let k in input) {
        if (input.hasOwnProperty(k) && !last.hasOwnProperty(k)) break loop;
      }
      
      return last;
    }
  } while (false);
  
  ref.current = input;
  return input;
}

// TODO: opt i useEffect
export function useDeepDedup<T>(input: T): T {
  const ref = useRef<T>(input);
  if (ref.current === input || lodashIsEqual(ref.current, input))
    return ref.current as T;
  
  ref.current = input;
  return input;
}

/** Jak useDerivedState, ale dostaje jako argument również poprzedni wynik.
 */
export function useFeedbackLoop<T extends any[], R>(initial: R, derivation: (feedback: R, ...args: T) => R, ...args: T): R;
export function useFeedbackLoop<T extends any[], R>(initial: R, derivation: (feedback: R, ...args: T) => R, ...args: UnboundedTuple<T>): R;
export function useFeedbackLoop<T extends any[], R>(initial: R, derivation: (feedback: R, ...args: T) => R /* + arguments (w ramach opt) */): R {
  if (typeof derivation !== "function") {
    throw new Error(`useFeedbackLoop expects a function, not: ${typeof derivation}`)
  }
  
  type State = [
    (feedback: R, ...args: T) => R,
    T,
    R
  ];
  
  const ref = useRef(null as any as State);
  
  if (ref.current === null) {
    const args = slice.call(arguments, 2) as T;
    
    ref.current = [
      derivation,
      args,
      derivation(initial, ...args)
    ];
    
    return ref.current[2];
  }
  
  let [_, oldDeps, value] = ref.current;
  
  ref.current[0] = derivation;
  
  let equal = arguments.length === (oldDeps.length + 2);
  if (equal) {
    const is = (derivation as any).comparison ?? Object.is;
    for (let i = 2; i < arguments.length; i++) {
      if (!is(oldDeps[i - 2], arguments[i])) {
        equal = false;
        break;
      }
    }
  }
  
  if (!equal) {
    for (let i = 2; i < arguments.length; i++)
      oldDeps[i - 2] = arguments[i];
    oldDeps.length = arguments.length - 2;
  
    ref.current[2] = value = derivation(value, ...oldDeps);
  }
  
  return value;
}
