import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Divider, Loader, Message, ModuleTile, Toast } from "components/shared";
import { Link } from "react-router-dom";
import { APPS, pages } from "utils/pages";

import teka_screen from "images/teka_splash_image.jpg";
import sowa_screen from "images/sowa_splash_image.jpg";
import workstation_screen from "images/workstation.jpg";
import journal_screen from "images/journal_splash_image.jpg";
import cataloguing_screen from "images/cataloguing_splash_image.jpg";
import lending_screen from "images/lending_splash_image.jpg";
import locker_screen from "images/locker_splash_image.jpg";
import weeding_screen from "images/weeding_splash_image.jpg";

import { isStaff } from "utils/roles";
import { LoginDialog } from "components/special-pages/Login/LoginDialog";
import { useDialog } from "components/shared/Dialog";

import { LANGUAGES_BY_ID, Tran, tran } from "utils/language";

import bootstrap from "bootstrap";

import { ensure, isLoaded, useTeka } from "capi/hooks";
import { useBind, useDerivedState, useDeviceState } from "utils/hooks";
import { emptyArray, emptyObject } from "utils/constants";

import { isURLValid } from "utils/validate";
import { WithBreadcrumb } from "../../utils/hooks/breadcrumbs";
import { ColumnLayout } from "../shared/ColumnLayout";
import { LogoutDialog } from "../layout/TopBar/LogoutDialog";
import { useFolksParams, useSowaParams, useTekaParams } from "../../utils/params/hooks";
import { MiscInfo } from "../../capi/teka";
import { lazyComponent } from "../../utils/pageLoading";
import { useShellContext } from "../shell/shellContext";
import { useCurrentUser } from "../../utils/hooks/redux";

const ExpoLoader = lazyComponent(() => import(/* webpackChunkName: "expo" */ "components/pages/Teka/Expo/Expo"), "Expo");

const SOWA_CAT_ID = bootstrap.sowa.firstCatalogue?.cat_id;

const THUMBNAILS = {
  [pages.teka.id]: teka_screen,
  [pages.admin.id]: sowa_screen,
  [pages.cataloguing.id]: cataloguing_screen,
  [pages.wyp.id]: lending_screen,
  [pages.locker.id]: locker_screen,
  [pages.stan.id]: workstation_screen,
  [pages.czyt.id]: journal_screen,
  [pages.ubyt.id]: weeding_screen
}

export const Front = () => {
  const current_user = useCurrentUser();
  const isLogged = Boolean(current_user.folks.user_id);

  // Requests
  const folksParamsRq = useFolksParams("core.signup.default.enabled");
  const tekaParamsRq  = useTekaParams("branding.tile.default.url", "branding.exposition.contents");
  const sowaParamsRq  = useSowaParams(SOWA_CAT_ID, "opac.registration.default.regulations");
  const tekaStatsRq   = ensure(useTeka(MiscInfo, "stats"), 200);

  // Response data
  const folksParams = folksParamsRq.data;
  const tekaParams  = tekaParamsRq.data;
  const sowaParams  = sowaParamsRq.data;
  
  const { requirementsMet } = useShellContext();

  // Variables depending on parameters
  const isSignupAvailable   = folksParams && folksParams["core.signup.default.enabled"];
  const brandingUrl         = tekaParams && tekaParams["branding.tile.default.url"];
  const hasRegulations      = isURLValid(sowaParams && sowaParams["opac.registration.default.regulations"]);

  const showSignup          = bootstrap.WZUW || (isSignupAvailable && hasRegulations);

  // Dialog
  const loginDialog = useDialog(LoginDialog);
  const logoutDialog = useDialog(LogoutDialog);

  if (brandingUrl && isURLValid(brandingUrl)) {
    THUMBNAILS[pages.teka.id] = brandingUrl;
  }
  
  const expo    = tekaParams && tekaParams["branding.exposition.contents"];
  const hasTeka = bootstrap.teka && bootstrap.teka.url;
  const hasExpo = hasTeka && bootstrap.tekaExpo && expo && expo.length;

  const modules = APPS.filter(app => app.checkRequirements(requirementsMet)).map(app => ({
    ...app,
    thumbnail: THUMBNAILS[app.id]
  }));
  
  let content;

  if (current_user.loading && isLogged)
    content = <Loader />; // zapobiegamy wyświetleniu dwóch kafelków a potem szybko trzech po załadowaniu info usera z sowy
  else {
    let main = null;
    
    if (hasExpo) {
      main = <>
        <ExpoLoader />

        <ColumnLayout cols={"400px"}>
          <Button to={"/teka/index"} content={
            isLoaded(tekaStatsRq)
              ? <span>
                  {tekaStatsRq.data.published_documents}
                  {" "}
                  <Tran id="front.moreDocs" count={tekaStatsRq.data.published_documents} />
                </span>
              : tran("front.moreDocs.L")
          } />
        </ColumnLayout>
        
        {modules.length > 0 && <Divider />}

        {modules.length > 0 && <ColumnLayout cols="repeat(auto-fit, 300px)" margin={0} spacing="2rem" width="100%" hPosition="center">
          {modules.map(e => (<Button key={e.fullPath} variant="contained" to={e.fullPath} content={e.displayName} icon={e.icon} />))}
        </ColumnLayout>}
      </>
    }
    else {
      main = modules.length > 0
          ? <ColumnLayout cols="repeat(auto-fit, 400px)" margin={0} spacing="2rem" width="100%" hPosition="center">
              {modules.map(e => (<ModuleTile key={e.fullPath} url={e.fullPath} title={e.displayName} description={e.description} thumbnail={e.thumbnail} />))}
            </ColumnLayout>
          : <span style={{ fontSize: "1.5em", color: "var(--primary)" }}>{tran("front.welcomeAnon")}</span>;
    }
    
    content = <>
      {main}

      <Divider />

      <ColumnLayout cols="auto auto" hPosition="end" vAlign="stretch" margin={0} width="100%" className="front__lowerMenu" unresponsive>
        {isLogged
          ? <>
            <Button
              to="/myProfile"
              variant="outlined"
              color="primary"
              align="right"
            >
              {tran("pages.list.myProfile.title")}
            </Button>
            <Button
              variant="outlined"
              onClick={logoutDialog.open}
            >
              {tran("unique.logout")}
            </Button>
          </>
          : <Button
            variant="outlined"
            color="primary"
            onClick={loginDialog.open}
          >
            {showSignup ? tran("front.signinOrSignup") : tran("front.signin")}
          </Button>
        }
      </ColumnLayout>
    </>
  }
  
  if (modules.length > 0 && !bootstrap.WZUW)
    return <WithBreadcrumb label={tran("front.welcomeAnon")}>{content}</WithBreadcrumb>;

  return content;
};
