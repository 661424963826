import React from "react";
import { Icon } from "components/shared";
import { connect } from "react-redux";

const UserCard = ({ user, current, folks_role }) => {
  const { label, kind, role } = user;

  const style = {
    maxWidth: "90vw",
    display: "flex",
    padding: "0 1rem",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "left"
  };

  return (
    <div style={style}>
      <div style={{ padding: "0 1.5rem 0 0" }}>
        <Icon {...getKindIcon(kind, role)} style={{ fontSize: "32px" }} />
      </div>
      <div>
        <h3 style={{ marginBottom: 0 }}>{label}</h3>
        <p>{findRoleDescription(folks_role, role)}</p>
      </div>
    </div>
  );
};

const findRoleDescription = (folks_role = [], role) => {
  let r = "";

  const findR = folks_role.find(e => e.key === role);

  if (findR && findR.desc) r = findR.desc;

  return r;
};

const getRoleIcon = role => {
  switch (role) {
    case "spectator":
      return "user-injured";

    case "aspirant":
      return "user-clock";

    case "patron":
      return "user";

    case "staff":
      return "user-tie";

    case "gdpr":
      return "user-shield";

    case "admin":
      return "user-astronaut";

    default:
      return "user";
  }
};

const getKindIcon = (kind, role) => {
  let icon = { title: kind === "person" ? role : kind };

  switch (kind) {
    case "person":
      icon.name = getRoleIcon(role);
      break;

    case "manual":
      icon.name = "users";
      break;

    case "by_email":
      icon.name = "user-tag";
      break;

    default:
      icon.name = "question";
      break;
  }

  return icon;
};

const READ = state => ({
  current_user: state.currentUser.folks,
  folks_role: state.utils.enumDictionaries.data.folks_role
});

export default connect(READ)(UserCard);
