import { call } from "redux-saga/effects";
import cloneDeep from "lodash.clonedeep";

import * as SowaApi from "api/sowa";
import { sagaCapi } from "../capi/appclient";
import * as Sowa from "../capi/sowa";
import { CapiCommand } from "../capi/client";
import { emptyArray } from "../utils/constants";

/**
 * Pobieranie danych użytkownika z Sowy z jednoczesnym pobraniem wszystkch możliwych uprawnień
 * @param {String} login Login użytkownika
 * @param {Object} catalogue obiekt katalogu z pliku bootstrap
 */
export function* fetchSowaUser(login, catalogue, withConfigInfo) {
  let userData = {};
  try {
    const userGet = new Sowa.UserGet(login);
    const commands = [userGet];
    let configInfo;
    if (withConfigInfo) {
      configInfo = new ConfigInfo();
      commands.push(configInfo);
    }

    yield sagaCapi("sowa", "cataloguesById", catalogue.cat_id, ...commands);
    const result = userGet.result;
    
    if (configInfo && configInfo.status !== 200) {
      userData.error = configInfo.status;
    }
    else if (result.status === 200) {
      userData = { ...result.data };
      if (configInfo) {
        userData.configInfo = configInfo.result.data;
      }
    } else {
      userData.error = result.status;
    }
  } catch (error) {
    userData.error = 500;
  }

  try {
    const parsed = yield call(getAttributes, userData, catalogue.cat_id);
    return parsed;
  } catch (error) {
    throw error;
  }
}

export class ConfigInfo extends CapiCommand {
  constructor() {
    super(["sowaConfigInfo", ConfigInfo.kwargs]);
  }

  static kwargs = { act_protocol: "act1.0", no_forms: true };
}

/**
 * Pobranie wszystkich dostępnych w danym katalogu uprawnień i utworzenie wygodnego obiektu zawierającego uprawnienia użytkownika
 * @param {Object} userData wynik zapytania pobierającego uprawnienia użytkownika
 * @param {Number} cat_id id katalogu
 */
function* getAttributes(userData, cat_id) {
  try {
    const result = yield call(SowaApi.userDef, cat_id);

    if (result.status === 200) {
      return parseSowaAttributes(userData, result.data);
    } else {
      throw result;
    }
  } catch (error) {
    // eslint-disable-next-line
    throw { status: 500 };
  }
}

// FIXME: nie powinniśmy tworzyć tego "wygodnego" obiektu dla każdego użytkownika,
//        bo za każdym razem to pobiera definicje uprawnień, i tworzy niepotrzebnie dużo obiektów

/**
 * 
 * @param userData
 * @param userDefData
 * @returns {import('utils/hooks/redux').CurrentSowaUser}
 */
export function parseSowaAttributes(userData, userDefData) {
  userDefData = cloneDeep(userDefData);
  
  const parsedObj = {
    ...userData,
    profiles: [...userDefData.profiles],
    rights: [],
    rightsMap: {},
  };
  
  const rightsMap = parsedObj.rightsMap;
  for (const right of userData.rights || emptyArray) {
    const def = userDefData.rights.find(def => def.right === right.right);
    let value = right.attrib;
    if (def) {
      if (!def.with_attrib)
        value = true;
      else if (def.multichoice) {
        value = value.split(",");
        if (value.length < 1)
          value = undefined;
      }
    }
    rightsMap[right.right] = value;
  }

  userDefData.rights.forEach(right => {
    // mapowanie po wszystkich możliwych uprawnieniach

    if (right.choices) {
      // pod right.choices zapisane są wszystkie możliwe opcje wartości danego uprawnienia
      // Zapisanie dostępnych opcji jako tablica obiektów
      // np [ { value: "00", name: "Czytelnia" } ]
      right.choices = right.choices.map(choice => {
        return {
          value: choice[0],
          name: choice[1]
        };
      });
    }

    // Znalezienie wartości użytkownika dla danego uprawnienia
    const userValue = userData.rights
      ? userData.rights.find(item => item.right === right.right)
      : undefined;
    if (userValue) {
      


      if (right.choices) {
        // Wartościami są agendy - te same, które są zapisane pod kluczem "choices".


        const findItemInChoices = value => {
          // Wyszukanie elementu w choices na podstawie wartości
          return right.choices.find(item => item.value === value);
        }
        
        if (right.multichoice) {
          // wartościami może być wiele agend - są zapisane jako jeden string, agendy oddzielone przecinkami
          const values = userValue.attrib.split(",");
          const parsedValues = [];
          values.forEach(value => {
            // Kopiowanie z right.choices tych obiektów, do których użytkownik ma uprawnienie
            const choice = findItemInChoices(value)
            if (choice) {
              parsedValues.push(choice);
            } else {
              // Jakimś cudem użytkownik ma zapisaną wartość, która nie jest dostępna w right.choices - nie powinno się to zdarzyć
              parsedValues.push({ value, name: "" });
            }
          });
          right.value = parsedValues;
        }
        else {
          const choice = findItemInChoices(userValue.attrib);
          if (choice) {
            right.value = choice;
          } else {
            // Jakimś cudem użytkownik ma zapisaną wartość, która nie jest dostępna w right.choices - nie powinno się to zdarzyć
            right.value ={ value: userValue.attrib, name: "" };
          }
        }


      } 
      else if (right.right === "administrowanie") {
        // Jeżeli uprawnienie "administrowanie" zostało podane, to znaczy że użytkownik ma to uprawnienie (w tym uprawenieniu nie ma podanej wartości)
        right.value = "";
      }
      else {
        right.value = userValue.attrib;
      }
    } 
    else {
      // Uzytkownik nie ma przypisanego tego uprawnienia
      right.value = null;
    }

    parsedObj.rights.push(right);
  });

  return parsedObj;
}
