import React, { useContext, useEffect, useState } from "react";
import { doNothing, emptyArray, emptySet, negate } from "utils/constants";
import { BetterContextProvider } from "utils/context";
import {
  useBind,
  useDedup,
  useDerivedState,
  useDeviceState,
  useMediaQuery,
  useSmallOrMediumScreen,
  useTouchDevice
} from "utils/hooks";
import { type CurrentSowaUser, useCurrentUser } from "utils/hooks/redux";
import { Dictionary } from "utils/types";
import bootstrap from "bootstrap";
import { useSowaParams } from "utils/params/hooks";
import { useTekaEdition } from "utils/tekaUtils";
import roles, { isRoleHigherOrEqual } from "utils/roles";

export interface ShellContext {
  shellInitialized: boolean
  
  requirementsMet: Set<string>
  
  sidebarMode: "fixed" | "floating"
  isSidebarOpen: boolean
  
  setSidebarOpen(open: boolean): void
  toggleSidebarOpen: (event?: any) => void
  openSidebar: (event?: any) => void
  closeSidebar: (event?: any) => void
}

const shellContext = React.createContext<Readonly<ShellContext>>(Object.freeze({
  shellInitialized: false,
  requirementsMet: emptySet,
  sidebarMode: "floating",
  isSidebarOpen: false,
  setSidebarOpen: doNothing,
  toggleSidebarOpen: doNothing,
  openSidebar: doNothing,
  closeSidebar: doNothing,
}));

export function useShellContext() {
  return useContext(shellContext);
}

export type ShellContextProviderProps = {
  children?: React.ReactNode
}

export function ShellContextProvider(props: ShellContextProviderProps) {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  
  const isWideWindow = useMediaQuery('only screen and (min-width: 1400px)');
  const isMediumScreen = useSmallOrMediumScreen();
  
  const sidebarMode = isMediumScreen || !isWideWindow ? "floating" : "fixed";
  
  const [fixedSidebarOpen, setFixedSidebarOpen] = useDeviceState("sb", false);
  
  useEffect(() => {
    if (sidebarMode === "fixed") setSidebarOpen(fixedSidebarOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarMode]);
  
  useEffect(() => {
    if (sidebarMode === "fixed") setFixedSidebarOpen(isSidebarOpen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarMode, isSidebarOpen]);
  
  const toggleSidebarOpen = useBind(setSidebarOpen, negate).preventDefault();
  const openSidebar = useBind(setSidebarOpen, true).preventDefault();
  const closeSidebar = useBind(setSidebarOpen, false).preventDefault();
  
  const requirementsMet = useRequirements();
  
  // TODO: opt z __proto__
  const value = useDedup({
    sidebarMode,
    isSidebarOpen,
    setSidebarOpen,
    toggleSidebarOpen,
    openSidebar,
    closeSidebar,
    requirementsMet,
  })
  return <BetterContextProvider context={shellContext} value={value as any} children={props.children} />
};

function useRequirements() {
  const currentUser = useCurrentUser();
  
  // FIXME: chcemy nie pobierać tych informacji, jeśli user nie jest zalogowany
  let sowaModules: Dictionary<readonly string[]> = {};
  for (const catalogue of bootstrap.sowa.catalogues) {
    // lista katalogów jest stała
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const sowaParamsRq = useSowaParams(catalogue.cat_id, "system.licence.default.modules");
    sowaModules[catalogue.cat_id] = useDedup(sowaParamsRq.data?.["system.licence.default.modules"] || emptyArray);
  }
  sowaModules = useDedup(sowaModules);
  
  const { hasEditors, hasSerials } = useTekaEdition();
  
  return useDerivedState(gatherRequirements, !!currentUser.folks.user_id, currentUser.folks.role, !!hasEditors, !!hasSerials, currentUser.sowa, sowaModules);
}

function gatherRequirements(loggedIn: boolean, role: string, hasEditors: boolean, hasSerials: boolean, catalogueRights: Dictionary<CurrentSowaUser>, sowaModules: Dictionary<readonly string[]>): Set<string> {
  const result = new Set<string>();
  
  if (bootstrap.devel)
    result.add("devel");
  
  if (bootstrap.beta)
    result.add("beta");
  
  if (loggedIn) {
    result.add("login");
    
    for (const catId in catalogueRights) {
      const user = catalogueRights[catId];
      const rightsMap = user.rightsMap;
      const modules = sowaModules[catId];
      
      if (modules.includes("CZT")) {
        result.add("czytelnia");
        if (rightsMap.czytelnia || rightsMap.czytelnie) {
          result.add("czytelniaRights");
          result.add(`czytelniaRights:${catId}`);
        }
      }
      
      if (modules.includes("STN")) {
        result.add("stanowiska");
        if (rightsMap.stanowiska || rightsMap.pracownie) {
          result.add("stanowiskaRights");
          result.add(`stanowiskaRights${catId}`);
        }
      }
      
      if (modules.includes("KSI")) {
        result.add("książkomaty")
        if (rightsMap.książkomat) {
          result.add("książkomatyRights");
          result.add(`książkomatyRights:${catId}`);
        }
      }
      
      if (modules.includes("INW")) {
        result.add("ubytkowanie")
        if (rightsMap.ubytkowanie) {
          result.add("ubytkowanieRights");
          result.add(`ubytkowanieRights:${catId}`);
        }
      }

      if (modules.includes("WYP")) {
        result.add("wypożyczanie")
        if (rightsMap.wypożyczanie || rightsMap.inne)  {
          result.add("wypożyczalniaRights");
          result.add(`wypożyczalniaRights:${catId}`);
        }
      }
    }
  }
  
  if (bootstrap.teka.client) {
    result.add("teka");
    hasEditors && result.add("tekaEditors");
    hasSerials && result.add("tekaSerials");
  }
  
  if (bootstrap.kasa.client)
    result.add("kasa");
  
  for (const r of Object.keys(roles)) {
    if (isRoleHigherOrEqual(role, r))
      result.add(r);
  }
  
  return result;
}