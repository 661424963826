import React from "react";
import PropTypes from "prop-types";
import { Icon } from "components/shared";
import { getPage, PageId } from "../../../utils/pages";

export type PageHeaderProps = {
  pageId?: PageId
  
  icon?: string
  title?: React.ReactNode
  description?: React.ReactNode
  rightColumnContent?: React.ReactNode
  children?: React.ReactNode
  
  // @deprecated
  subheader?: React.ReactNode
}

function PageHeader(props: PageHeaderProps) {
  let { pageId, icon, title, description } = props;
  
  title ||= props.children;
  let subheader = description || props.subheader;
  
  if (pageId) {
    const page = getPage(pageId);
    icon ||= page.icon;
    title ||= page.displayName;
    subheader ||= page.description;
  }
  
  return (
    <header className="page-header__wrapper">
      <div className="page-header__container">
        <div className="page-header__headers">
          {title && <h1 className="page-header__title">{title}</h1>}
          {subheader && <div className="page-header__subtitle">{subheader}</div>}
        </div>
        {props.rightColumnContent && <div className="page-header__right-column-content">
          {props.rightColumnContent}
        </div>}
        {icon && <Icon name={icon} className="page-header__icon" />}
      </div>
    </header>
  );
}

PageHeader.propTypes = {
  icon: PropTypes.string,
  // nazwa ikony
  title: PropTypes.any,
  description: PropTypes.node,
  rightColumnContent: PropTypes.node,

  // ! Deprecated
  subheader: PropTypes.node
};

export default PageHeader;
