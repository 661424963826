import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { Button, Icon } from 'components/shared';
import { doNothing, identity, negate } from "../../../../utils/constants";
import { copyTextToTitle } from "../../../../utils/dom";
import { useBind, useForkedState } from "../../../../utils/hooks";
import { useSearchHighlight } from "../../../../utils/hooks/search";
import { SidebarEntry, SubMenuPages } from '../types';
import { SubNavigationList } from './SubNavigationList';

type SidebarItemProps = {
  item: SidebarEntry;
  depth?: number;
};


export const SidebarListItem = React.memo(({ item, depth = 0 }: SidebarItemProps) => {
  const { fullPath, icon, displayName, children } = item;
  
  if (!fullPath)
    console.warn("item has no path", item);
  
  const className = depth ? "main-sidebar__entry main-sidebar__entry--normal" : "main-sidebar__entry main-sidebar__entry--category";
  const style = depth > 1 ? { paddingLeft: `${16 + (depth - 1) * 8}px` } : undefined;
  const routeMatch = useRouteMatch(fullPath!); // ech, to niestety budzi się na każdej zmianie lokacji
  const isActive = !!routeMatch;
  const [manuallyExpanded, setManuallyExpanded] = useState(undefined as undefined | boolean);
  const isExpanded = manuallyExpanded ?? isActive;
  const isLeaf = !children;
  const isExact = routeMatch?.isExact;
  const ref = useRef<HTMLDivElement | null>(null);
  const toggleExpand = useBind(setManuallyExpanded, !isExpanded).preventDefault();
  
  useEffect(() => {
    // scrollujemy do aktywnego elementu, ale tylko gdy sidebar nie jest aktywnie używany
    if (isActive && (isExact || isLeaf) && ref.current) {
      const $ref = ref.current.closest(".main-sidebar__entry")! as HTMLElement;
      const $scrollContainer = $ref.closest(".main-sidebar__list--main")!;
      // TODO: osobne wykrywanie aktywności w trybie mobilnym, może sekunda poza dotknięciu ekranu
      if (!$scrollContainer.matches(":hover")) {
        const timer = setTimeout(() => {
          if ($scrollContainer.contains($ref)) {
            const offset = $ref.offsetTop;
            const height = $ref.clientHeight;
            const viewport = $scrollContainer.clientHeight;
            const target = isLeaf
                ? Math.round(offset - (viewport - height) * .5) // centrujemy na liściach
                : offset - height; // kategorie wyrównujemy prawie do góry
            const scroll = $scrollContainer.scrollTop
            
            if (scroll >= offset || Math.abs(target - scroll) >= viewport * .333)
              $scrollContainer.scrollTo({ behavior: "smooth", left: 0, top: target });
          }
        }, 100);
        return () => clearTimeout(timer);
      }
    }
  }, [isActive, isExact]);
  
  return <>
    <Link className={`rst ${className} ${isActive ? "main-sidebar__entry--active" : ""}`} to={item.fullPath!} style={style} aria-expanded={isExpanded} title={item.tooltip}>
      <Icon className="main-sidebar__entry-icon" name={item.icon} type="primary" />
      <div ref={ref} className="main-sidebar__entry-name one-liner" onMouseEnter={copyTextToTitle}>{item.displayName}</div>
      {children &&
        <Button className="main-sidebar__entry-expand" variant="icon" icon={isExpanded ? "minus" : "plus"} onClick={toggleExpand} />}
    </Link>
    {children && isExpanded && children.map((subItem) => (
      <SidebarListItem
        key={subItem.fullPath}
        item={subItem}
        depth={depth + 1}
      />
    ))}
  </>;
});