import React, { useState } from "react";
import type { RichErrorProps } from "utils/errorReporting";
import { negate } from "utils/constants";
import { useCurrentUserFolks } from "utils/hooks/redux";
import { tran, Tran, type TranProps } from "utils/language";
import { isStaff } from "utils/roles";
import Icon from "components/shared/Icon";
import SimpleAccordion from "components/shared/SimpleAccordion";
import type { IDialog } from "../useDialog";
import { MessageDialog, type MessageDialogProps } from "./MessageDialog";

export function RichErrorDialog(props: RichErrorProps & { dialog: IDialog }) {
  const [detailsOpen, setDetailsOpen] = useState(false);
  
  const user = useCurrentUserFolks();
  
  let variant: MessageDialogProps["variant"];
  let icon;
  switch (props.nature) {
    case "userError":
      variant = "error";
      icon = "message-exclamation outline";
      break;
    
    case "appError":
      variant = "fatal";
      icon = "square-exclamation outline";
      break;
      
    case "networkError":
      variant = "fatal";
      icon = "cloud-exclamation outline"
      break;
      
    default:
      variant = "normal";
  }
  
  
  let message: React.ReactNode;
  if (props.message) {
    message = <b>{props.message}</b>;
  }
  else if (props.id) {
    message = <b><Tran
      id={props.id}
      search={props.search}
      replace={props.replace}
      case={props.case}
      form={props.form}
      count={props.count}
      fallback={props.fallback}
      className={props.className}
    /></b>
  }
  
  let content = <>
    <Icon name={icon} size="x2" />
    {message}
    {props.richMessage || null}
  </>;
  
  if (props.details && isStaff(user.role)) {
    content = <>
      {content}
      <SimpleAccordion.Controlled
        className="rich-error-dialog__details"
        open={detailsOpen}
        onToggle={() => setDetailsOpen(negate)}
        header={tran("unique.details")}
      >
        {props.details}
      </SimpleAccordion.Controlled>
    </>
  }
  else {
  }
  
  return <MessageDialog
    variant={variant}
    size={detailsOpen ? "medium" : "small"}
    children={content}
    unwrapped={true}
    dialog={props.dialog}
  />;
}
