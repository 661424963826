import React, { useRef, useState, useEffect } from "react";
import { emptyObject } from "utils/constants";
import Button from "components/shared/Button";
import Input from "components/shared/Input";
import { __barcodeState } from "components/shell/appbar/barcodeState";

type BarcodeInputProps = {
  className?: string;
  icon?: string;
  placeholder?: string;
};

export const AppBarBarcodeInput = React.memo(
  ({ className, icon, placeholder }: BarcodeInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const handleKeyboardScanning = (ev: KeyboardEvent) => {
        if (!ev.key || !ev.key.match(/^[0-9a-zA-Z]$/)) {
          return;
        }
        if (ev.ctrlKey || ev.altKey || ev.shiftKey || ev.metaKey) {
          return;
        }
        if (isInputFocused()) return;
        inputRef.current?.focus();
      };

      document.addEventListener("keydown", handleKeyboardScanning);
      return () => {
        document.removeEventListener("keydown", handleKeyboardScanning);
      };
    }, []);
    
    return (
      <form onSubmit={onSubmit}>
        <Input
          inputRef={inputRef}
          icon={icon}
          className={className}
          placeholder={placeholder || "Kod kreskowy"}
          borderless
          data-type="scanning-exception"
          tabIndex={-1}
          onFocus={() => toggleVisibility(true)}
          onBlur={() => {
            !inputRef.current?.value && toggleVisibility(false);
          }}
          onKeyDown={(event) => {
            if (!inputRef.current?.value && event.key === "Escape") {
              event.preventDefault();
              event.currentTarget.blur();
            }
          }}
          clearButton={isVisible}
          onClear={() => toggleVisibility(false)}
          style={{ fontFamily: "monospace", ...(isVisible ? emptyObject : hiddenStyle) }}
        />
        {!isVisible && (
          <Button
            title="Wprowadź kod kreskowy"
            variant="icon"
            icon="barcode outline"
            onClick={() => inputRef?.current?.focus()}
          />
        )}
      </form>
    );

    function toggleVisibility(visible: boolean) {
      setIsVisible(visible);
      if (!visible) {
        inputRef.current?.blur();
      }
    }

    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
      e.preventDefault();
      const barcode = (inputRef.current?.value || "").trim(); 
      if (!barcode) return;
      
      const { consumers } = __barcodeState;
      if (consumers.length <= 0) return;
      
      const consumer = consumers[consumers.length - 1];
      consumer(barcode)
        .then(() => {
          // FIXME: @marcin useOperation nie daje na zewnątrz żadnej informacji o powodzeniu,
          //        więc input się zawsze skasuje
          inputRef.current!.value = "";
          toggleVisibility(false);
        });
    }
  }
);

function isInputFocused() {
  const activeElement = document.activeElement;
  return (
    activeElement &&
    (forbiddenTagNames.has(activeElement.tagName.toLowerCase()) ||
      activeElement.getAttribute("data-type") === "scanning-exception" ||
      activeElement.getAttribute("role") === "textbox")
  );
}

const hiddenStyle = { width: 0, border: 0, padding: 0 };
const forbiddenTagNames = new Set(["input", "textarea", "select", "button"]);
