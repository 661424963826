import React from "react";
import classnames from 'classnames';
import { Icon } from "components/shared";

type BlankholderProps = {
  icon?: string
  
  title?: React.ReactNode
  description?: React.ReactNode
  button?: React.ReactNode
  animate?: boolean
  outline?: boolean
}

function Blankholder({ outline, animate = true, ...props }: BlankholderProps) {
  return (
    <div className="blankholder-wrapper">
      <div
        className={classnames("blankholder", {
          animate,
          outline,
        })}>
        {props.icon ? (
          <div className="blankholder__icon-wrapper">
            <Icon name={props.icon} />
          </div>
        ) : null}
        <div className="blankholder__content">
          <div className="blankholder__text">
            {props.title && <h3>{props.title}</h3>}
            {props.description && <p className="blankholder__description">{props.description}</p>}
          </div>
          {props.button && <div style={{ marginTop: 20 }}>{props.button}</div>}
        </div>
      </div>
    </div>
  );
};

export default Blankholder;
