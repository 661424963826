import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { emptyObject } from "../../../utils/constants";

import * as DialogPresets from './comps';
import useDialog, { IDialog } from './useDialog';
import { useDedup } from 'utils/hooks';

export { default as useDialog } from './useDialog';
export { default as Dialog, type DialogProps } from "./Dialog";
export { DialogPresets };

export type OpenDialogProps<P extends { dialog: IDialog }> = {
  component: React.ComponentType<P>
  
  doClose?: (...args: any[]) => void
  onClose?: (...args: any[]) => void
  
  /** Wartość tych propsów jest brana pod uwagę tylko podczas pierwszego otwarcia */
  initialProps?: { [K in keyof P]?: P[K] }
  
  /** Wartość tych prosów powoduje aktualizacje w dialogu */
  liveProps?: { [K in keyof P]?: P[K] }
}

/** Klasa do otwierania dialogów ze starych komponentów klasowych */
export function OpenDialog<P extends { dialog: IDialog }>(props: OpenDialogProps<P>) {
  const dialog = useDialog(props.component, props.initialProps).doClose(props.doClose).onClose(props.onClose);
  const liveProps = useDedup(props.liveProps);
  
  React.useEffect(() => {
    dialog.openWith(liveProps || emptyObject);
  }, [props.component, liveProps]);
  
  return null;
}

export type DialogRouteProps<P extends { dialog: IDialog }> = {
  component: React.ComponentType<P>
  
  path?: string | string[]
  exact?: boolean
  sensitive?: boolean
  strict?: boolean
  
  /** Wartość tych propsów jest brana pod uwagę tylko podczas pierwszego otwarcia */
  initialProps?: { [K in keyof P]?: P[K] }
  
  /** Wartość tych prosów powoduje aktualizacje w dialogu */
  liveProps?: { [K in keyof P]?: P[K] }
  
  /** UWAGA: Nie wywoływane przy odmontowaniu, w przeciwieństwie do onClose w innych miejscach. */
  onClose?: (...args: any[]) => void
}

/** Eksperymentalna klasa do otwierania dialogów pod URLami, działa jak zwykły Route */
export function DialogRoute<P extends { dialog: IDialog }>(props: DialogRouteProps<P>) {
  const match = useRouteMatch(props);
  
  // TODO: czy są sytuacje w których zamiast history.goBack wolimy zrobić replaceState?
  //       może gdy user wszedł na dialog spoza naszej aplikacji
  //       ale czy to się da wykryć... 
  
  if (match)
    return <OpenDialog<P>
      component={props.component}
      initialProps={props.initialProps}
      // @ts-ignore
      liveProps={{ ...props.liveProps, ...match.params }}
      doClose={doClose}
    />;
  else 
    return null;
  
  function doClose(/*arguments*/) {
    // tylko jeśli dialog został otwarty przez linka w nowej karcie, to długość historii będzie 1
    // a przycisk wstecz będzie nieaktywny,
    // możemy wtedy przy zamykaniu dialogu zamknąć całą kartę 
    if (typeof window.history.length === "number" && window.history.length <= 1)
      window.close();
    else
      window.history.back();
    
    // @ts-ignore
    props.onClose && props.onClose.apply(this, arguments);
  }
}
