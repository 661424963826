import React from "react";
import { Link } from "react-router-dom";
import bootstrap from "bootstrap";
import { Logo } from "components/shared/Logo";

const hasLogo = bootstrap.logo.img;
const logo = {
  src: bootstrap.logo.img,
  url: bootstrap.logo.url,
};

const target = bootstrap.topLinkTarget || "_self";
const invert = !hasLogo || !!bootstrap.logo.inv;

export const TOP_LOGO = logo.url ? (
  <a href={logo.url} className="top-bar__logo hide-on-small-only" target={target}>
    {hasLogo ? <img src={logo.src} alt="logo" className={invert ? "filter-invert" : ""} /> : <Logo />}
  </a>
) : (
  <Link to="/" className="top-bar__logo hide-on-small-only">
    {hasLogo ? <img src={logo.src} alt="logo" className={invert ? "filter-invert" : ""} /> : <Logo />}
  </Link>
);

// TODO: przetestować wariant WZUWu

const secondLogoEl = 
    bootstrap.WZUW && bootstrap.secondLogo && bootstrap.secondLogo.enabled && bootstrap.secondLogo.url && 
      <a href={bootstrap.secondLogo.url} className="top-bar__logo-second hide-on-small-only" target={target}><img src={bootstrap.secondLogo.img} alt="second logo" /></a>;

export const TOP_TITLE = <>{secondLogoEl}{
  (bootstrap.titleUrl
    ? <a href={bootstrap.titleUrl} className="top-bar__info" target={target}>
        <b>{bootstrap.title}</b>
      </a>
    : <b className="top-bar__info">{bootstrap.title}</b>)
}</>;

const result = <div className="top-bar__logo-box">
  {TOP_LOGO}
  {secondLogoEl}
  {TOP_TITLE}
</div>;
