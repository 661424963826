import { Dropdown, Icon } from 'components/shared'
import React from 'react'
import { LANGUAGE_LIST, setLanguage, useLanguage } from 'utils/language';

const langOptions = LANGUAGE_LIST.map(lang => ({
  text: lang.name,
  image: lang.flagUrl,
  value: lang.id
}));

export const LanguageMenu = React.memo((props: {}) => {
  const { id, translate } = useLanguage();
  
  const trigger = <button
    className="main-sidebar__headerIcon"
    title={translate("pages.settings.content.lang.title")}
  >
    <Icon name="globe-europe" />
  </button>;
  
  return <Dropdown options={langOptions} trigger={trigger} value={id} onChange={setLanguage} fluid />;
});
