import React, { createContext, useContext, useEffect, useRef, type ReactNode } from "react";
import { useHistory, useLocation } from "react-router";
import { doNothing, emptyArray, emptyObject, isTrue } from "utils/constants";
import { BetterContextProvider } from "utils/context";
import { getSearchParams, objectFilter, objectMap, setSearchParams } from "utils/globalFunctions";
import { type SignalRegistry, signalRegistry } from "utils/signalRegistry";
import type { Dictionary } from "utils/types";

interface ParamsContextValue {
  // TODO: dodać obsługę number na wszelki wypadek, i zapewnić, że 0 nie jest traktowane jak ""?
  paramsContextPush: (params?: Dictionary<string | undefined | null>) => void;
  paramsContextReplace: (params?: Dictionary<string | undefined | null>) => void;
}

interface ParamsContextPriv extends ParamsContextValue {
  params: Dictionary;
  signals: SignalRegistry;
  last?: string
}

const ParamsContext = createContext(emptyObject);

export function StateParamsContextProvider({
  children,
  initParams = emptyObject,
}: {
  children: ReactNode;
  initParams?: Dictionary;
}) {
  const stateRef = useRef<ParamsContextPriv>(null as any);

  if (stateRef.current === null) {
    const signals = signalRegistry({ delay: true });

    stateRef.current = {
      signals,
      params: Object.freeze({ __proto__: emptyStringForAllProps, ...objectFilter(initParams, isTrue) }),
      paramsContextPush: updateParams,
      paramsContextReplace: updateParams,
    };
    
    function updateParams(v?: Dictionary) {
      if (!v) return;
      const emit = [] as string[];
      
      const oldParams = stateRef.current?.params;
      const newParams = { ...oldParams, ...v };
      
      Object.keys(newParams).forEach((key) => {
        if ((newParams[key] || "") !== oldParams[key]) {
          emit.push(key);
        }
      });
      
      if (emit.length > 0) {
        stateRef.current.params = Object.freeze({
          __proto__: emptyStringForAllProps,
          ...objectFilter(newParams, isTrue),
        });
        emit.push("__all__");
        signals.signal(emit);
      }
    }
  }

  return (
    <BetterContextProvider context={ParamsContext} value={stateRef.current}>
      {children}
    </BetterContextProvider>
  );
}

export function UrlParamsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const location = useLocation();
  const history = useHistory();

  const stateRef = useRef<ParamsContextPriv>(null as any);

  if (stateRef.current === null) {
    const signals = signalRegistry({ delay: false });

    stateRef.current = {
      signals,
      params: Object.freeze({ __proto__: emptyStringForAllProps }),
      last: "",
      paramsContextPush: (v?: Dictionary) => {
        if (!v) return;
        history.push({
          search: setSearchParams(history.location.search, objectMap(v, x => x || undefined)),
        });
      },
      paramsContextReplace: (v?: Dictionary) => {
        if (!v) return;
        history.replace({
          search: setSearchParams(history.location.search, objectMap(v, x => x || undefined)),
        });
      },
    };
  }
  
  const state = stateRef.current;
  let emit = emptyArray as readonly string[];
  
  if (location.search !== state.last) {
    const { params } = state;
    const newParams = objectFilter(getSearchParams(location.search), isTrue);
    const keySet = new Set([...Object.keys(params), ...Object.keys(newParams)]);
    
    for (const key of keySet) {
      // TODO: nie chcemy pewnie rozróżniać "" i undefined
      if (newParams[key] !== params[key])
        emit = [...emit, key];
    }
    
    state.last = location.search;
    
    if (emit.length > 0) {
      state.params = Object.freeze({
        __proto__: emptyStringForAllProps,
        ...newParams
      });
    }
  }
  
  useEffect(emit.length === 0 ? doNothing : () => {
    state.signals.signal(emit);
  });
  
  return (
    <BetterContextProvider context={ParamsContext} value={state}>
      {children}
    </BetterContextProvider>
  );
}

// ten obiekt będzie naszym prototypem, który będzie powodował,
// że nieodnalezione propsy zwracają "" zamiast undefined
const emptyStringForAllProps = new Proxy(Object.freeze({ __proto__: null }), {
  get(target: Readonly<{}>, p: string | symbol, receiver: any): any {
    return "";
  }
});

/**
 * 
 * @param props Lista parametrów, w których zmiana ma być sygnalizowana
 */
export function useParamsContext(): Readonly<ParamsContextValue>;
export function useParamsContext<T extends string[]>(...props: T): Readonly<ParamsContextValue> & {
  readonly params: {
    readonly [K in T[number]]: string
  }
};
export function useParamsContext<T extends string[]>(...props: T): any {
  const ctx = useContext(ParamsContext) as ParamsContextPriv;
  ctx.signals.useWithKeys(...props);
  return ctx;
}
