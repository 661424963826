import React from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Icon, Popup } from "components/shared";

import UserCard from "./UserCard";
import { USER_INFO } from "../../capi/folksCache";
import { isLoaded, notYet } from "../../capi/hooks";
import { isStaff as roleIsStaff } from "../../utils/roles";
import { useSearchHighlight } from "../../utils/hooks/search";

// TODO: otwieranie profilu w popupie? wymagałoby sporego refaktoringu profilu

const UserLabel = React.memo(({
  user,
  link: showLink,
  icon: showIcon,
  preview: showPreview,
  kind: showKind = true,
  inline
}) => {
  if (typeof user === "string")
    user = { user_id: user };
  
  let highlight = useSearchHighlight("label");
  const loginHighlight = useSearchHighlight("login");

  let download = !user.kind;
  const userInfo = USER_INFO.use(notYet(download) || user.user_id);
  
  if (isLoaded(userInfo))
    user = userInfo;
  else if (userInfo && userInfo._state === "not_found") {
    // TODO: not_found nie jest obsługiwane przez isLoaded, ani nic podobnego... może się pozbyć tego stanu...
    user = userInfo;
    highlight = italicize;
  }
  
  let { user_id, label, kind, role, ref_key, ref_val, is_editor, login } = user;
  
  const currentUser = useSelector(currentUserFolksSelector);
  const isCurrentUser = currentUser.user_id === user_id;
  const isStaff = roleIsStaff(currentUser.role);

  if (!user.user_id)
    return <span className="userLabel"/>;
  
  // TODO: tłumaczenie
  // TODO: cache enumów folks żeby tutaj użyć
  
  let title = label;
  let isGroup = false;
  let isRef = false;
  
  label = label && label.trim();
  if (!label) {
    if (role === "aspirant") {
      label = "<Niepotwierdzony czytelnik>";
      highlight = italicize;
    }
    else if (login) {
      label = login;
      highlight = s => (<code title={`Użytkownik bez etykiety, login: ${login}`}>[{loginHighlight(s)}]</code>);
    }
    else {
      label = user_id.replace(/-/g, "");
      const L = label.length;
      label = `[…${label.substring(L - 7, L)}]`;
      highlight = s => (<code title={`Użytkownik bez etykiety, id: ${user_id}`}>{label}</code>);
    }
  }
  
  switch (kind) {
    case "manual":
      title = `Grupa: ${label}`;
      if (showKind) label = (<span className={`group ${user.kind}`}>Grupa <q>{highlight(label)}</q></span>);
      else label = highlight(label);
      isGroup = true;
      break;
  
    case "auto":
      switch (ref_key) {
        case "U;usos_fac":
          title = `Jednostka organizacyjna: ${label}`;
          if (showKind) label = (<span className={`group ${user.kind}`}>Jednostka <q>{highlight(label)}</q></span>);
          else label = highlight(label);
          break;
        case "U;usos_cg":
          if (ref_val && label.startsWith(ref_val))
            label = label.substring(ref_val.length + 2);
          title = `Przedmiot: ${label}`;
          if (showKind) label = (<span className={`group ${user.kind}`}>Przedmiot <code>{highlight(ref_val || "")}:</code> <q>{highlight(label)}</q></span>);
          else label = (<span><code>{highlight(ref_val || "")}:</code> {highlight(label)}</span>)
          break;
        default:
          title = `Grupa automatyczna: ${label}`;
          if (showKind) label = (<span className={`group ${user.kind}`}>Grupa <q>{highlight(label)}</q></span>);
          else label = highlight(label);
      }
      isGroup = true;
      break;
      
    case "by_email":
      if (ref_val) {
        label = <span className={`ref ${user.kind}`}>E-mail: {highlight(ref_val)}</span>;
        title = `Odnośnik po adresie e-mail: ${ref_val}`;
      }
      else {
        label = <span className={`ref ${user.kind}`}>Odnośnik po adresie e-mail</span>;
        title = `Odnośnik po adresie e-mail: (wartość chroniona)`;
      }
      isRef = true;
      break;
      
    case "by_property":
      if (ref_key === "gradebook_id") {// TODO: inne
        if (ref_val) {
          label = <span className={`ref ${user.kind}`}>Indeks: {highlight(ref_val)}</span>;
          title = `Odnośnik po nr indeksu: ${ref_val}`;
        }
        else {
          label = <span className={`ref ${user.kind}`}>Odnośnik po nr indeksu</span>;
          title = `Odnośnik po nr indeksu: (wartość chroniona)`;
        }
      }
      isRef = true;
      break;
      
    default:
      label = highlight(label);
  }

  const icon = showIcon
    ? isCurrentUser
      ? <Icon fixed name="thumbs-up" />
      : <Icon fixed {...getKindIcon(kind, role, is_editor)} />
    : null;
  
  let content = <>{icon} <span title={title}>{label}</span></>;

  if (showLink && !isRef) {
    let url;
    if (isStaff)
      url = `/admin/${isGroup ? "groups" : "users"}/v/${user_id}`;
    else if (isCurrentUser)
      url = "/myProfile";
    else {
      const isGroupMember = (isGroup && currentUser.member_of) && currentUser.member_of.find(g => g.user_id === user_id);
      if (isGroupMember)
        url = `/myProfile/groups/v/${user_id}`;
    }
    
    if (url)
      content = <Link to={url} target={showLink === "newTab" ? "_blank" : undefined} onClick={stopPropagation}>{content}</Link>;
  }

  content = inline
    ? <span className="userLabel">{content}</span>
    : <div className="userLabel one-liner">
        {content}
      </div>;
  
  if (showPreview)
    content = <Popup
      position="top center"
      on="hover"
      pointing={false}
      trigger={content}
      content={<UserCard user={user} current={isCurrentUser} />}
    />;

  return content;
});

const italicize = s => (<i>{s}</i>);

const stopPropagation = (event) => event.stopPropagation();

const getRoleIcon = (role, is_editor) => {
  switch (role) {
    case "spectator":
      return "user-injured";

    case "aspirant":
      return "user-clock";

    case "patron":
      if (is_editor)
        return "user-graduate";
      return "user";

    case "staff":
      return "user-tie";

    case "senior":
      return "user-secret";
      
    case "gdpr":
      return "user-shield";

    case "admin":
      return "user-astronaut";

    default:
      return "user";
  }
};

const getKindIcon = (kind, role, is_editor) => {
  let icon = { title: kind === "person" ? role : kind };

  switch (kind) {
    case "person":
      icon.name = getRoleIcon(role, is_editor);
      break;

    case "manual":
      icon.name = "users";
      break;
  
    case "auto":
      icon.name = "users-cog";
      break;
  
    case "app":
      icon.name = "user-cog";
      break;
  
    case "by_email":
      icon.name = "envelope";
      break;
  
    case "by_property":
      icon.name = "user-tag";
      break;
  
    default:
      icon.name = "question";
      break;
  }

  return icon;
};

const currentUserFolksSelector = state => state.currentUser.folks;

UserLabel.propTypes = {
  user: PropTypes.shape({
    user_id: PropTypes.string
  }).isRequired,

  link: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  icon: PropTypes.bool,
  preview: PropTypes.bool,
  
  inline: PropTypes.bool,
};

export default UserLabel;
