import React, { useEffect, useState } from "react";

import { useHistory, useLocation, useRouteMatch } from "react-router";

import { StrixModule, useStrixModules } from "capi/useStrixModules";
import { useDerivedState } from "../../utils/hooks";
import { emptyArray } from "../../utils/constants";
import Loader from "./Loader";
import { LinkTabs } from "./Tabs/LinkTabs";
import { tran } from "utils/language";
import Blankholder from "./Blankholder";

interface CatalogueSwitchProps {
  catTypes?: readonly string[];
  children: (catalogue: StrixModule) => JSX.Element;
}

export function CatalogueSwitch({
  catTypes,
  children,
}: CatalogueSwitchProps) {
  const route = useRouteMatch();
  const allModules = useStrixModules();
  const catalogues = useDerivedState(filterModules, allModules, catTypes || emptyArray);
  
  const history = useHistory();
  
  const [catalogue, replace] = useDerivedState(catalogueFromLocation, history.location.pathname, route.url, catalogues);
  
  useEffect(() => {
    if (catalogue && replace)
      history.replace(`${route.url}/${catalogue.modId}`);
  }, [catalogue, replace]);
  
  if (!catalogue)
    return <Blankholder description={tran("unique.noAvailableCatalogues")} icon="exclamation triangle" />;
  
  return <>
    <LinkTabs
      tabs={catalogues.map(catalogue => ({
        link: `${route.url}/${catalogue.modId}`,
        text: catalogue.text,
      }))}
    />
    {children(catalogue)}
  </>;
}

export function filterModules(modules: ReturnType<typeof useStrixModules>, types: readonly string[]) {
  if (types.length === 0) return modules;
  return modules.filter((module) => types.includes(module.type));
}

export function catalogueFromLocation(pathname: string, prefix: string, catalogues: readonly StrixModule[]): [StrixModule | undefined, boolean] {
  if (!catalogues.length)
    return [undefined, false];
  
  pathname = pathname.substring((prefix || "/").length);
  const m = /[\w-]+/.exec(pathname);
  
  if (m) {
    const id = m[0];
    let n = parseInt(id);
    const nan = isNaN(n);
    if (!nan) {
      if (n > 0 && n < catalogues.length)
        return [catalogues[n], true];
    }
    else {
      const cat = catalogues.find(cat => cat.modId === id);
      if (cat)
        return [cat, false];
    }
  }
  return [catalogues[0], true];
}
