import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ShortcutManager } from "react-shortcuts";

import { Loader } from "components/shared";
import { ToastContainer } from "components/shared/Toast/ToastContainer";

import ErrorPage from "components/special-pages/ErrorPage";
import { TermsCheck } from 'components/special-pages/Terms/TermsCheck'

import { AppContainer } from "components/AppContainer";
import { TopBar } from "components/layout/TopBar";

import * as themes from "utils/themes";
import { translate, getLanguage } from "utils/language";
import * as moment from "moment";
import { keymap } from "utils/keymap";

import {
  FETCH_DICTIONARIES
} from "sagas/types";

/** Import moment.js locale */
import "moment/locale/pl";
import "moment/locale/de";
import CookiesDeclaration from "./common/CookiesDeclaration";
import { OverlayService } from "./layout/overlays";
import { setFont } from "../utils/themes";
import { BreadcrumbsService } from "utils/hooks/breadcrumbs";
import { KeyDownListenerService } from "utils/hooks/keyDownListener";
import { ShellContextProvider } from "./shell/shellContext";
import { UrlParamsContextProvider } from "utils/hooks/paramsContext";

const shortcutManager = new ShortcutManager(keymap);

/**
 * @todo Write end2end tests
 */
class App extends PureComponent {
  state = {
    enableAnimations: true
  };

  // Hmm, daty chyba będziemy musieli obsłużyć w przyszłości komponentami jak tłumaczenia
  // zamiast renderować stringi
  loadLocale = () => moment.locale(getLanguage().split("_")[0] || "global");

  getChildContext() {
    return {
      shortcuts: shortcutManager
    };
  }
  
  componentDidMount() {
    /** Ustawiamy domyślne preferencje */
    
    this.props.fetchDictionaries();
    this.loadLocale();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.appSettings &&
      this.props.appSettings !== prevProps.appSettings
      ) {
      this.applySettings(this.props.appSettings);
      this.loadLocale();
    }
  }

  /**
   * Zastosowanie ustawień użytkownika
   * @param {Object} settings
   */
  applySettings(settings) {
    if (settings.font) {
      setFont(settings.font);
    }
    if (settings.contrast) {
      themes.setTheme("contrast");
    } else {
      themes.setTheme(settings.theme);
    }
    this.handleAnimations(settings.enableAnimations);
  }

  handleAnimations(enabled = true) {
    if (enabled) {
      document.body.classList.add("animate");
    } else {
      document.body.classList.remove("animate");
    }
    this.setState({ enableAnimations: enabled });
  }
  
  render() {
    const {
      userInfo,
      isUserLoggedIn,
      anonymousSession,
      userError,
      sessionError
    } = this.props;
    
    let content;
    
    if (userError || sessionError) {
      content = CONTENT_ERROR;
    }
    else if (!anonymousSession && !isUserLoggedIn) {
      content = LOADING_SESSION;
    }
    else if (isUserLoggedIn && Object.keys(userInfo).length === 0) {
      content = LOADING_USER;
    }
    else {
      content = isUserLoggedIn ? CONTENT : CONTENT_ANONYMOUS;
    }
    
    return <KeyDownListenerService>
      <UrlParamsContextProvider>
        <OverlayService>
          <BreadcrumbsService>
            {content}
          </BreadcrumbsService>
        </OverlayService>
        {TOAST_CONTAINER}
      </UrlParamsContextProvider>
    </KeyDownListenerService>
  }
}

// Tutaj jako stałe w ramach optymizacji tego głównego komponentu aplikacji
const ERROR_PAGE = <ErrorPage />;
const LOADING_SESSION = <Loader text={translate("loading.sessionInitialization") + "..."} />;
const LOADING_USER = <Loader text={translate("loading.user") + "..."} />;
const CONTENT_ANONYMOUS = <>
  <div className="App" id="app">
    <ShellContextProvider>
      <AppContainer />
    </ShellContextProvider>
  </div>
  <CookiesDeclaration />
</>;
const CONTENT = <>
  {CONTENT_ANONYMOUS}
  <TermsCheck /> {/* TODO: znaleźć lepsze miejsce dla regulaminu */}
</>;
const CONTENT_ERROR = <>
  <div className="App" id="app">
    <TopBar />
    <ErrorPage />
  </div>
</>;
const TOAST_CONTAINER = <ToastContainer />;

App.childContextTypes = {
  shortcuts: PropTypes.object.isRequired
};

App.propTypes = {
  isUserLoggedIn: PropTypes.bool.isRequired, // czy zalogowany jest użyktownik
  anonymousSession: PropTypes.bool.isRequired, // czy jest aktywna sesja anonimowa
  userInfo: PropTypes.object.isRequired,
  appSettings: PropTypes.object.isRequired,
  userError: PropTypes.bool,
  sessionError: PropTypes.string,
  fetchDictionaries: PropTypes.func.isRequired // pobranie słowników używanych do zapytań API
};

const READ = state => ({
  isUserLoggedIn: state.session.isUserLoggedIn,
  anonymousSession: state.session.anonymousSession,
  userInfo: state.currentUser.folks,
  appSettings: state.utils.appSettings,
  userError: state.currentUser.error,
  sessionError: state.session.sessionError
});

const EMIT = dispatch => ({
  fetchDictionaries: () => dispatch({ type: FETCH_DICTIONARIES })
});

const mergeProps = (read, emit, own) => ({
  ...read,
  ...emit,
  ...own
});

export default connect(
  READ,
  EMIT,
  mergeProps
)(App);
